import { fetchAPI, FetchOptions } from "."
import { PointFreezeWindow, PointsFreezeMap } from "../types"

// marketing.GET("/pointFreezeWindows/:uid", s.getPointFreezeWindowsForUser)
// marketing.GET("/pointFreezeWindows/:uid/:cntID", s.getPointFreezeWindowsForContact)
// marketing.GET("/pointFreezeWindows/byPii/:uid/:pii", s.getPointFreezeWindowsForPii)
// marketing.POST("/pointFreezeWindow/:uid/:piiType", s.createPointFreezeWindow)
// marketing.PUT("/pointFreezeWindow/:uid", s.updatePointFreezeWindow)
// marketing.DELETE("/pointFreezeWindow/:uid", s.deletePointFreezeWindow)

export function getPointFreezeWindowsForUser(options?: FetchOptions): Promise<PointsFreezeMap> {
    return fetchAPI('/pointFreezeWindows/:uid', { method: 'GET', ...options })
}

export function getPointFreezeWindowsForContact(cntID: string, options?: FetchOptions): Promise<PointFreezeWindow[]> {
    return fetchAPI(`/pointFreezeWindows/:uid/${cntID}`, { method: 'GET', ...options })
}

export function getPointFreezeWindowsForPii(pii: string, options?: FetchOptions): Promise<PointFreezeWindow[]> {
    return fetchAPI(`/pointFreezeWindows/byPii/:uid/${pii}`, { method: 'GET', ...options })
}

export function createPointFreezeWindow(piiType: string, payload: Partial<PointFreezeWindow>, options?: FetchOptions): Promise<PointFreezeWindow> {
    return fetchAPI(`/pointFreezeWindow/:uid/${piiType}`, { method: 'POST', payload, ...options })
}

export function updatePointFreezeWindow(payload: Partial<PointFreezeWindow>, options?: FetchOptions): Promise<PointFreezeWindow> {
    return fetchAPI('/pointFreezeWindow/:uid', { method: 'PUT', payload, ...options })
}

export function deletePointFreezeWindow(payload: Partial<PointFreezeWindow>, options?: FetchOptions): Promise<void> {
    return fetchAPI('/pointFreezeWindow/:uid', { method: 'DELETE', payload, ...options })
}

