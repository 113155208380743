import { Image as Img } from "antd";
import React, { useEffect, useRef } from "react";
import utils from "../../helpers/utils";
import allStyles from "../../helpers/allStyles";

const checkTransparencyAndBrightness = async (url: string) => {
    return new Promise<ImageDetails>((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous"; // handle CORS
        img.src = url;
        img.onload = function () {
            const canvas = document.createElement("canvas");
            const context = canvas.getContext("2d")!;
            canvas.width = img.width;
            canvas.height = img.height;
            context.drawImage(img, 0, 0, img.width, img.height);
            const imgData = context.getImageData(0, 0, img.width, img.height).data;
            let hasTransparency = false;
            let totalLuminance = 0;
            let colorCount: any = {};
            let totalRed = 0, totalGreen = 0, totalBlue = 0;
            let darkestLuminance = 255;
            let brightestLuminance = 0;
            let darkestColor = '';
            let brightestColor = '';
            let transparentPixelsCount = 0;


            for (let i = 0; i < imgData.length; i += 4) {
                const r = imgData[i];
                const g = imgData[i + 1];
                const b = imgData[i + 2];
                const a = imgData[i + 3];
                if (a !== 255) {
                    hasTransparency = true;
                    transparentPixelsCount++;
                    continue;
                }
                const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
                totalLuminance += luminance;
                totalRed += r;
                totalGreen += g;
                totalBlue += b;

                const color = `${r},${g},${b}`;
                if (!colorCount[color]) {
                    colorCount[color] = 0;
                }
                colorCount[color]++;

                if (luminance < darkestLuminance) {
                    darkestLuminance = luminance;
                    darkestColor = `rgb(${color})`;
                }

                if (luminance > brightestLuminance) {
                    brightestLuminance = luminance;
                    brightestColor = `rgb(${color})`;
                }


            }

            const avgLuminance = totalLuminance / (imgData.length / 4);
            const avgRed = Math.round(totalRed / (imgData.length / 4));
            const avgGreen = Math.round(totalGreen / (imgData.length / 4));
            const avgBlue = Math.round(totalBlue / (imgData.length / 4));

            const transparencyPercent = (transparentPixelsCount / (imgData.length / 4)) * 100;
            const mostCommonColor = `rgb(${Object.keys(colorCount).reduce((a, b) => colorCount[a] > colorCount[b] ? a : b)})`;
            const averageColor = `rgb(${avgRed},${avgGreen},${avgBlue})`;

            // Calculate brightness of the most common color
            const [mostCommonR, mostCommonG, mostCommonB] = mostCommonColor.substring(4, mostCommonColor.length - 1).split(",").map(Number);;
            const mostCommonBrightness = 0.2126 * mostCommonR + 0.7152 * mostCommonG + 0.0722 * mostCommonB;
            const brightnessOfMostCommonColor = (mostCommonBrightness / 255) * 10;

            resolve({
                hasTransparency,
                isBright: avgLuminance > 128,
                mostCommonColor,
                averageColor,
                darkestColor,
                brightestColor,
                recommendedBackgroundColor: mostCommonColor,
                transparencyPercent,
                brightnessOfMostCommonColor,
            });
        };
        img.onerror = () => {
            reject(`Error loading image: ${url}`);
        };
    });
};

interface ImageWithBackgroundProps {
    src: string;
    style?: React.CSSProperties;
    wrapperStyle?: React.CSSProperties;
}

interface ImageDetails {
    hasTransparency: boolean,
    isBright: boolean,
    mostCommonColor: string,
    averageColor: string,
    brightestColor: string,
    darkestColor: string,
    recommendedBackgroundColor: string,
    transparencyPercent: number,
    brightnessOfMostCommonColor: number,
}

const ImageWithBackground: React.FC<ImageWithBackgroundProps> = React.memo(({ src, ...props }) => {
    const imageRef = useRef<HTMLImageElement | null>(null);
    const [imageDetails, setImageDetails] = React.useState<ImageDetails | null>(null);

    useEffect(() => {
        (async () => {
            if (imageRef?.current) {
                utils.asyncFetch(async () => {
                    const { hasTransparency, isBright, ...details } = await checkTransparencyAndBrightness(src);
                    setImageDetails({ hasTransparency, isBright, ...details });
                }, (err) => console.debug(err), true)
            }
        })();
    }, [src, imageRef.current]);


    // Show a border if the image is transparent || too light to tell where it ends
    const showBorder = (imageDetails?.hasTransparency && !imageDetails?.isBright) || (((imageDetails?.transparencyPercent || 0) < 15) && (imageDetails?.brightnessOfMostCommonColor || 0) > 9);
    const addLightBackground = (imageDetails?.hasTransparency && imageDetails?.transparencyPercent > 50 && !imageDetails?.isBright) && (imageDetails.brightnessOfMostCommonColor < 5 || imageDetails?.brightnessOfMostCommonColor > 9);
    const fillDarkBackground = ((imageDetails?.transparencyPercent || 0) < 50 && !imageDetails?.isBright && (imageDetails?.brightnessOfMostCommonColor || 0) < 3)

    let background = addLightBackground ? `#4A4A4D2${Math.floor(imageDetails.brightnessOfMostCommonColor || 0)}` : "";
    if (fillDarkBackground) background = imageDetails?.mostCommonColor || "";

    const wrapperStyle: React.CSSProperties = {
        background,
        padding: addLightBackground ? 2 : 0,
        border: showBorder ? `1px solid #4A4A4D3${Math.floor(imageDetails?.brightnessOfMostCommonColor || 0)}` : "",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        textAlign: 'center',
        borderRadius: props.style?.borderRadius || 0,
        ...props.wrapperStyle,
        ...allStyles.shadows.lightShadow()
    }

    const style: React.CSSProperties = {
        ...props.style
    }

    // if we add 3 pixels of padding for bg we subtract 3 pixels from the width and height
    if (addLightBackground) {
        style.width = style.width && typeof style.width === 'number' ? style.width - 3 : 'auto';
        style.height = style.height && typeof style.height === 'number' ? style.height - 3 : 'auto';
    }

    // if we added border we subtract 2 pixels from the width and height
    if (showBorder) {
        style.width = style.width && typeof style.width === 'number' ? style.width - 2 : 'auto';
        style.height = style.height && typeof style.height === 'number' ? style.height - 2 : 'auto';
    }

    return <div style={{ ...wrapperStyle }}>
        <img ref={imageRef} src={src} {...props} style={{ display: 'none' }} />
        <Img src={src} {...props} style={{ ...props.style, ...style, }} />
    </div>;
})

export default ImageWithBackground;