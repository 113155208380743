import { userUtils, utils } from '../../../helpers';
import { Audience, CouponCodeBatch, Discount, EntryMap, ID, Store } from '../../../types';
import { RecipeStatus } from '../../recipes/recipeUtils';
import { getDefaultDiscount, getDiscountBodyImage, getDiscountCoverImage, getDiscountLogoImage } from './disc.builder.helpers';

export type DiscountBuilderStagesProps = {

    discountID: string | undefined;
    storeID: string | undefined;

    uid: string;
    loaded: boolean;
    isCreating: boolean;
    isCopying: boolean;
    isPreview: boolean
    isDisabled: boolean;
    disabledReasons: string[];

    selectedStage: DiscountBuilderStage | undefined
    setSelectedStage: React.Dispatch<React.SetStateAction<DiscountBuilderStage | undefined>>

    discount: Discount;
    setDiscount: React.Dispatch<React.SetStateAction<Discount>>;

    ogDiscount: Discount;
    setOgDiscount: React.Dispatch<React.SetStateAction<Discount>>;

    codeBatches: CouponCodeBatch[]
    setCodeBatches: React.Dispatch<React.SetStateAction<CouponCodeBatch[]>>

    audiences: EntryMap<Audience>;
    setAudiences: React.Dispatch<React.SetStateAction<EntryMap<Audience>>>;

    stores: EntryMap<Store>;
    setStores: React.Dispatch<React.SetStateAction<EntryMap<Store>>>;

    canProceedStages: (stage: DiscountBuilderStage) => string[]
    changeStage: (stage: DiscountBuilderStage) => void;
    saveDiscount: () => Promise<void>;

    recipeStatus: RecipeStatus

    showCancel: boolean;
    cancelProcess: () => void;

}



type Errors = string[];

export type PartialStageProps = Partial<DiscountBuilderStagesProps>;

export const DiscountBuilderStages = {
    information: {
        index: 0,
        name: "Basic Info",
        // This is the first stage so it has always started
        hasStarted: (args: PartialStageProps): boolean => {
            return true
        },
        // Stage is considered completed after channels have been selected
        // and the user has moved on to the next stage
        hasCompleted: (args: PartialStageProps): boolean => {
            const discount = args.discount
            const hasName = !!discount?.internalName
            return hasName
        },
        checkCompleted: (args: PartialStageProps): Errors => {
            const errors: Errors = []
            if (!!args.discount?.addToPrizeWheel && !args.discount?.prizeTitle) {
                return ["Please enter a Prize Title for this discount"]
            }
            return errors
        }
    },
    design: {
        index: 1,
        name: "Design",
        // This stage has started if the user has selected this stage
        // OR if the user has already completed the stage
        hasStarted: (args: PartialStageProps): boolean => {
            const isOnDesignStage = args.selectedStage === 'design'
            const hasCompletedDesignStage = DiscountBuilderStages.design.hasCompleted(args)
            return isOnDesignStage || hasCompletedDesignStage
        },
        // Stage is considered completed after recipients have been selected (for each discount)
        hasCompleted: (args: PartialStageProps): boolean => {
            const discount = args.discount
            const hasTitle = !!discount?.pageTitle;
            return hasTitle
        },
        checkCompleted: (args: PartialStageProps): Errors => {
            if (!args.discount?.pageTitle) {
                return ["Please enter a Short Description for this discount"]
            }
            return []
        }
    },
    redemption: {
        index: 2,
        name: "Redemption",
        // This stage has started if the user has selected this stage
        // OR if the user has already completed the stage
        hasStarted: (args: PartialStageProps): boolean => {
            const isOnRedemptionStage = args.selectedStage === 'redemption'
            const hasCompletedRedemptionStage = DiscountBuilderStages.redemption.hasCompleted(args)
            return isOnRedemptionStage || hasCompletedRedemptionStage
        },
        // Stage is considered completed after recipients have been selected (for each discount)
        hasCompleted: (args: PartialStageProps): boolean => {
            const discount = args.discount || {}
            const hasCompletedDesign = DiscountBuilderStages.design.hasCompleted(args)
            return !!discount.redemptionType && hasCompletedDesign
        },
        checkCompleted: (args: PartialStageProps): Errors => {
            const discount = args.discount || {}
            return []
        }
    },
    review: {
        index: 3,
        name: "Review",
        // This stage has started if the user has selected this stage
        // OR if the user has already completed the stage
        hasStarted: (args: PartialStageProps): boolean => {
            const isOnReviewStage = args.selectedStage === 'review'
            const hasCompletedReviewStage = DiscountBuilderStages.review.hasCompleted(args)
            return isOnReviewStage || hasCompletedReviewStage
        },
        // Stage is considered completed after recipients have been selected (for each discount)
        hasCompleted: (args: PartialStageProps): boolean => {
            const discount = args.discount
            // Check hsa completed for all stages
            const hasCompletedAllStages = Object.keys(DiscountBuilderStages)
                .every((key) => key === 'review' || DiscountBuilderStages[key as DiscountBuilderStage].hasCompleted(args))
            return hasCompletedAllStages && !!discount?.hasCompletedRedemptionPage
        },
        checkCompleted: (args: PartialStageProps): Errors => {
            return []
        }
    },
} as const;

export type DiscountBuilderStage = keyof typeof DiscountBuilderStages;

export const DiscountTypesMapping = {
    normal: {
        title: 'Normal',
        description: '',
        visisble() {
            return true
        },
        check: (discount: Discount): boolean => {
            return !discount.tierDiscount
        },
        enable: (discount: Discount): Discount => {
            discount.tierDiscount = false
            return discount
        },
    },
    tierReward: {
        title: 'Tier reward',
        description: '',
        visisble() {
            return true
        },
        check: (discount: Discount): boolean => {
            return !!discount.tierDiscount
        },
        enable: (discount: Discount): Discount => {
            discount.tierDiscount = true
            return discount
        },
    },
} as const

export type DiscountType = keyof typeof DiscountTypesMapping

export const DiscountImageTypesMapping = {
    none: {
        title: 'None',
        description: '',
        visisble() {
            return true
        },
        check: (discount: Discount, storeID: ID | undefined): boolean => {
            const image = getDiscountBodyImage(discount, storeID)
            return !image
        },
        enable: (discount: Discount): Discount => {
            discount.body = undefined
            return discount
        },
    },
    upload: {
        title: 'Custom image',
        description: '',
        visisble() {
            return true
        },
        check: (discount: Discount, storeID: ID | undefined): boolean => {
            const image = getDiscountBodyImage(discount, storeID)
            return !!image
        },
        enable: (discount: Discount): Discount => {
            discount.body = discount.body || ''
            return discount
        },
    },
} as const

export type DiscountImageType = keyof typeof DiscountImageTypesMapping

const isDefaultCover = (avatar?: string, uid?: string): boolean => {
    if (!avatar) return false
    const url = new URL(avatar.startsWith('http') ? avatar : `${location.origin}${avatar}`)
    const defaultUrl = new URL(location.origin + (getDefaultDiscount(uid || utils.uid).avatar || ''))
    return (
        url.pathname === defaultUrl.pathname && url.hostname === defaultUrl.hostname
    )
}

export const DiscountCoverTypesMapping = {
    default: {
        title: 'Default',
        description: '',
        visisble() {
            return true
        },
        check: (discount: Discount, storeID: ID | undefined): boolean => {
            const cover = getDiscountCoverImage(discount, storeID)
            return cover === undefined || isDefaultCover(cover, discount.userID)
        },
        enable: (discount: Discount): Discount => {
            discount.avatar = undefined
            return discount
        },
    },
    custom: {
        title: 'Custom',
        description: '',
        visisble() {
            return true
        },
        check: (discount: Discount, storeID: ID | undefined): boolean => {
            const cover = getDiscountCoverImage(discount, storeID)
            return cover !== undefined && !isDefaultCover(cover, discount.userID)
        },
        enable: (discount: Discount): Discount => {
            discount.avatar = ''
            return discount
        },
    },
} as const

export type DiscountCoverType = keyof typeof DiscountCoverTypesMapping

const isDefaultLogo = (avatar?: string, uid?: string): boolean => {
    if (!avatar) return false
    const url = new URL(avatar.startsWith('http') ? avatar : `${location.origin}${avatar || ''}`)
    const defaultUrl = new URL(location.origin + (getDefaultDiscount(uid || utils.uid)?.logo || ''))
    return (
        url.pathname === defaultUrl.pathname && url.hostname === defaultUrl.hostname
    )
}

export const DiscountLogoTypesMapping = {
    default: {
        title: 'Default',
        description: '',
        visisble() {
            return true
        },
        check: (discount: Discount, storeID: ID | undefined): boolean => {
            const logo = getDiscountLogoImage(discount, storeID)
            return logo === undefined || isDefaultLogo(logo, discount.userID)
        },
        enable: (discount: Discount): Discount => {
            discount.logo = undefined
            return discount
        },
    },
    custom: {
        title: 'Custom',
        description: '',
        visisble() {
            return true
        },
        check: (discount: Discount, storeID: ID | undefined): boolean => {
            const logo = getDiscountLogoImage(discount, storeID)
            return logo !== undefined && !isDefaultLogo(logo, discount.userID)
        },
        enable: (discount: Discount): Discount => {
            discount.logo = ''
            return discount
        },
    },
} as const

export type DiscountLogoType = keyof typeof DiscountLogoTypesMapping

export const DiscountRedemptionTypesMapping = {
    default: {
        title: 'N/A',
        reviewTitle: (discount: Discount) => "No redemption method selected",
        description: '',
        visisble() {
            return true
        },
        check: (discount: Discount): boolean => {
            return discount.redemptionType === undefined || discount.redemptionType === 'default'
        },
        applyInitial: (discount: Discount): Discount => {
            return discount
        },
        enable: (discount: Discount, keepKeys?: string[]): Discount => {
            discount = clearRedemptionTypes(discount, keepKeys as DiscountRedemptionType[])
            discount = clearRedemptionFields(discount, keepKeys as DiscountRedemptionType[])
            discount.redemptionType = 'default'
            return discount
        },
    },
    staticDollarAmount: {
        title: 'Static $ off',
        reviewTitle: (discount: Discount) => `Static $${discount.dollarValue} off`,
        description: '',
        visisble() {
            return true
        },
        check: (discount: Discount): boolean => {
            return discount.redemptionType === 'staticDollarAmount'
        },
        applyInitial: (discount: Discount): Discount => {
            if (!!discount.dollarValue && !discount.redemptionType) {
                discount.redemptionType = 'staticDollarAmount'
            }
            return discount
        },
        enable: (discount: Discount, keepKeys?: string[]): Discount => {
            discount = clearRedemptionTypes(discount, keepKeys as DiscountRedemptionType[])
            discount = clearRedemptionFields(discount, keepKeys as DiscountRedemptionType[])
            discount.redemptionType = 'staticDollarAmount'
            return discount
        },
    },
    staticPercentage: {
        title: 'Static % off',
        reviewTitle: (discount: Discount) => `Static ${discount.percentageValue}% off`,
        description: '',
        visisble() {
            return true
        },
        check: (discount: Discount): boolean => {
            return discount.redemptionType === 'staticPercentage'
        },
        applyInitial: (discount: Discount): Discount => {
            if (!!discount.percentageValue && !discount.redemptionType) {
                discount.redemptionType = 'staticPercentage'
            }
            return discount
        },
        enable: (discount: Discount, keepKeys?: string[]): Discount => {
            discount = clearRedemptionTypes(discount, keepKeys as DiscountRedemptionType[])
            discount = clearRedemptionFields(discount, keepKeys as DiscountRedemptionType[])
            discount.redemptionType = 'staticPercentage'
            return discount
        },
    },
    triggerIntegration: {
        title: 'Trigger a partner platform reward',
        reviewTitle: (discount: Discount) => 'Partner platform reward',
        description: '',
        visisble() {
            return true
        },
        check: (discount: Discount): boolean => {
            return discount.redemptionType === 'triggerIntegration'
        },
        applyInitial: (discount: Discount): Discount => {
            if (!discount.redemptionType) {
                const hasIntegrationRedemptionMethod = !!Object.keys(IntegrationRedemptionMethodsMapping)
                    .find((key) => IntegrationRedemptionMethodsMapping[key as IntegrationRedemptionMethod].check(discount))
                if (hasIntegrationRedemptionMethod) {
                    discount.redemptionType = 'triggerIntegration'
                }
            }
            return discount
        },
        enable: (discount: Discount, keepKeys?: string[]): Discount => {
            discount = clearRedemptionTypes(discount, keepKeys as DiscountRedemptionType[])
            discount = clearRedemptionFields(discount, keepKeys as DiscountRedemptionType[])
            discount.redemptionType = 'triggerIntegration'
            discount.redemptionMethod = discount.redemptionMethod || 'couponCode'
            discount.couponType = discount.couponType || 'sameCode'
            return discount
        },
    }
} as const
export type DiscountRedemptionType = keyof typeof DiscountRedemptionTypesMapping


const clearRedemptionTypes = (discount: Discount, keepKeys?: DiscountRedemptionType[]): Discount => {
    if (!keepKeys?.includes('triggerIntegration')) {
        discount.redemptionType = undefined
        discount.redemptionMethod = undefined
        discount.couponType = undefined
        discount.uniqueCodeGenerationType = undefined
    }
    return discount
}

const clearRedemptionFields = (discount: Discount, keepKeys?: DiscountRedemptionType[]): Discount => {
    console.log('keepKeys', keepKeys)
    if (!keepKeys?.includes('staticDollarAmount')) {
        discount.dollarValue = undefined
    }
    if (!keepKeys?.includes('staticPercentage')) {
        discount.percentageValue = undefined
    }
    if (!keepKeys?.includes('triggerIntegration')) {
        discount.ecomCoupon = undefined
        discount.posDiscountID = undefined
        discount.barcodeType = undefined
        discount.barcodeValue = undefined
        discount.uniqueCodeGenerationType = undefined
    }
    if (!discount.uniqueCodeGenerationType) {
        discount.hasCouponCodes = false
    }
    return discount
}


export function hasStaticDollarOff(discount: Discount) {
    return discount.dollarValue && discount.dollarValue > 0
}

export function hasStaticPercentageOff(discount: Discount) {
    return discount.percentageValue && discount.percentageValue > 0
}

export function hasPriceCap(discount: Discount) {
    return !!discount.priceCap
}

export function hasIntegrationTriggerSettings(discount: Discount) {
    return (
        !!discount.ecomCoupon ||
        !!discount.posDiscountID ||
        (!!discount.barcodeType && !!discount.barcodeValue) ||
        (discount.hasCouponCodes)
    )
}

export const IntegrationRedemptionMethodsMapping = {
    couponCode: {
        title: 'Coupon Code',
        description: '',
        visisble() {
            return true
        },
        check: (discount: Discount): boolean => {
            return discount.redemptionMethod === 'couponCode'
        },
        applyInitial: (discount: Discount): Discount => {
            if (!discount.redemptionMethod && !!discount.ecomCoupon) {
                discount.redemptionType = 'triggerIntegration'
                discount.redemptionMethod = 'couponCode'
            }
            return discount
        },
        enable: (discount: Discount, keepKeys?: string[]): Discount => {
            discount = clearRedemptionFields(discount, keepKeys as DiscountRedemptionType[])
            discount.ecomCoupon = ''
            discount.redemptionMethod = 'couponCode'
            return discount
        },
    },
    posDiscountID: {
        title: 'POS Discount ID',
        description: '',
        visisble() {
            return true
        },
        check: (discount: Discount): boolean => {
            return discount.redemptionMethod === 'posDiscountID'
        },
        applyInitial: (discount: Discount): Discount => {
            if (!discount.redemptionMethod && !!discount.posDiscountID) {
                discount.redemptionType = 'triggerIntegration'
                discount.redemptionMethod = 'posDiscountID'
            }
            return discount
        },
        enable: (discount: Discount, keepKeys?: string[]): Discount => {
            discount = clearRedemptionFields(discount, keepKeys as DiscountRedemptionType[])
            discount.posDiscountID = ''
            discount.redemptionMethod = 'posDiscountID'
            return discount
        },
    },
    barcode: {
        title: 'Barcode',
        description: '',
        visisble() {
            return true
        },
        check: (discount: Discount): boolean => {
            return discount.redemptionMethod === 'barcode'
        },
        applyInitial: (discount: Discount): Discount => {
            const hasBarcode = discount.barcodeType !== undefined && discount.barcodeValue !== undefined
            if (!discount.redemptionMethod && hasBarcode) {
                discount.redemptionType = 'triggerIntegration'
                discount.redemptionMethod = 'barcode'
            }
            return discount
        },
        enable: (discount: Discount, keepKeys?: string[]): Discount => {
            discount = clearRedemptionFields(discount, keepKeys as DiscountRedemptionType[])
            discount.barcodeType = ''
            discount.barcodeValue = ''
            discount.redemptionMethod = 'barcode'
            return discount
        },
    },
} as const

export type IntegrationRedemptionMethod = keyof typeof IntegrationRedemptionMethodsMapping

export const DiscountCouponTypesMapping = {
    sameCode: {
        title: 'Same coupon code for everyone',
        description: 'All recipients will receive the same coupon code',
        visisble() {
            return true
        },
        check: (discount: Discount): boolean => {
            return discount.couponType === 'sameCode'
        },
        applyInitial: (discount: Discount): Discount => {
            if (!!discount.ecomCoupon && !discount.couponType) {
                discount.redemptionType = 'triggerIntegration'
                discount.redemptionMethod = 'couponCode'
                discount.couponType = 'sameCode'
            }
            return discount
        },
        enable: (discount: Discount): Discount => {
            discount = clearRedemptionFields(discount)
            discount.ecomCoupon = ''
            discount.couponType = 'sameCode'
            return discount
        },
    },
    uniqueCodes: {
        title: 'Unique coupon codes',
        description: 'Each recipient will receive a unique coupon code',
        visisble() {
            return true
        },
        check: (discount: Discount): boolean => {
            return discount.couponType === 'uniqueCodes' || !!discount.hasCouponCodes
        },
        applyInitial: (discount: Discount): Discount => {
            if (!!discount.uniqueCodeGenerationType && !discount.couponType) {
                discount.redemptionType = 'triggerIntegration'
                discount.redemptionMethod = 'couponCode'
                discount.couponType = 'uniqueCodes'
            }
            return discount
        },
        enable: (discount: Discount): Discount => {

            if (!userUtils.hasPackageTier('marketing', 'pro')) {
                utils.showErr('At least "Marketing Pro" is required to use unique coupon codes')
                return discount
            }

            discount = clearRedemptionFields(discount)
            discount.hasCouponCodes = true
            discount.hideRedeemInStore = true;
            discount.uniqueCodeGenerationType = discount.uniqueCodeGenerationType || 'randomAIQ'
            discount.couponType = 'uniqueCodes'
            return discount
        },
    },
} as const

export type DiscountCouponType = keyof typeof DiscountCouponTypesMapping

export const DiscountCouponUniqueCodeTypesMapping = {
    randomAIQ: {
        title: 'Generate unique codes',
        reviewTitle: (discount: Discount) => "Uniquely generated codes",
        description: '',
        visisble() {
            return true
        },
        check: (discount: Discount): boolean => {
            return discount.uniqueCodeGenerationType === 'randomAIQ'
        },
        applyInitial: (discount: Discount): Discount => {
            return discount
        },
        enable: (discount: Discount): Discount => {
            discount = clearRedemptionFields(discount)
            discount.hasCouponCodes = true
            discount.uniqueCodeGenerationType = 'randomAIQ'
            return discount
        },
    },
    thirdParty: {
        title: 'Paste codes into AIQ from a 3rd party',
        reviewTitle: (discount: Discount) => "Codes pasted into AIQ from a 3rd party",
        description: '',
        visisble() {
            return true
        },
        check: (discount: Discount): boolean => {
            return discount.uniqueCodeGenerationType === 'thirdParty'
        },
        applyInitial: (discount: Discount): Discount => {
            return discount
        },
        enable: (discount: Discount): Discount => {
            discount = clearRedemptionFields(discount)
            discount.hasCouponCodes = true
            discount.uniqueCodeGenerationType = 'thirdParty'
            return discount
        },
    },
} as const

export type DiscountCouponUniqueCodeType = keyof typeof DiscountCouponUniqueCodeTypesMapping