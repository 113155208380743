import { fetchAPI } from '.';
import { timeUtils, utils } from '../helpers';
import { MacroSurvey, Survey, SurveyAnswerWithPII, SurveySubmission, SurveyWithInsights, SurveysContext } from '../types';

type UpsertResponse = { survey: Survey, op: 'create' | 'update' }

function getSurveys(options?: { uid?: string }): Promise<SurveyWithInsights[]> {
    return fetchAPI('/survey/list/:uid', { ...options, method: 'GET', forceToken: utils.TOKEN })
}

function getSurvey(id: string, options?: { uid?: string, impression?: boolean }): Promise<Survey> {
    return fetchAPI(`${location.origin}/survey/${id}/:uid?impression=${options?.impression || false}`, { ...options, method: 'GET', forceToken: utils.TOKEN })
}

function createSurvey(survey: Survey): Promise<UpsertResponse> {
    return fetchAPI(`/survey/upsert/:uid`, { method: 'POST', payload: survey })
}

function updateSurvey(survey: Survey): Promise<UpsertResponse> {
    return fetchAPI(`/survey/upsert/upsert/:uid/${survey.id}`, { method: 'POST', payload: survey })
}

function archiveSurvey(id: string, status?: boolean): Promise<Survey> {
    return fetchAPI(`/survey/status/:uid/${id}/${status || false}`, { method: 'POST' })
}

function submitSurvey(id: string, payload?: SurveySubmission, options?: { uid?: string }): Promise<{ id: string }> {
    return fetchAPI(`${location.origin}/survey/submit/${id}/:uid`, { ...options, method: 'POST', payload })
}

export type GetSurveySumissionForContactResponse = {
    submissions: SurveySubmission[]
    surveysSubmitted: Survey[]
}

function getSurveySubmissionsForContact(contactID: string): Promise<GetSurveySumissionForContactResponse> {
    return fetchAPI(`/survey/submissions/${contactID}/:uid`, { method: 'GET', forceToken: utils.TOKEN })
}

function getSurveyQuestionAnswers(questionID: string): Promise<SurveyAnswerWithPII[]> {
    return fetchAPI(`/survey/${questionID}/:uid`, { method: 'GET', forceToken: utils.TOKEN })
}

// Returns a list of surveys for macros, only returns surveys that are active, not archived, and are between the start and end date (if they have one)
function getMacroSurveys(): Promise<MacroSurvey[]> {
    return fetchAPI(`/surveys/:uid?validOnly=true`, { method: 'GET', forceToken: utils.TOKEN })
}

async function getMacroSurveysMemoized(...[surveys, setSurveys]: SurveysContext): Promise<MacroSurvey[]> {
    const isSet = surveys && Object.keys(surveys.surveys || {}).length > 0;
    const needsUpdate = (new Date().getTime() - (surveys?.updated || 0)) > (timeUtils.units.MINUTE_MS * 10) // Templates are updated every 10 minutes or per refresh
    // Check that number of entities matches the number of templates
    // We have already fetched the data, and it's not too old
    if (isSet && !needsUpdate) return Object.values(surveys.surveys || {});
    // We haven't fetched the data yet, or it's too old
    const surveysList = await getMacroSurveys() || [];
    const surveysMap = surveysList.reduce((acc, survey) => ({ ...acc, [survey.id]: survey }), {});
    setSurveys({ surveys: surveysMap, updated: new Date().getTime() });
    return surveysList;
}

export const SurveysAPI = {
    getMacroSurveys,
    getSurveys,
    getSurvey,
    createSurvey,
    updateSurvey,
    archiveSurvey,
    submitSurvey,
    getSurveySubmissionsForContact,
    getSurveyQuestionAnswers,
    getMacroSurveysMemoized
}
