import React from 'react'

export default function useMountedEffect(callback: (b: React.MutableRefObject<boolean>) => void | (() => void), dependencies: React.DependencyList) {
    const mountedRef = React.useRef(false);

    React.useEffect(() => {
        mountedRef.current = true;
        const cb = callback(mountedRef);
        return () => {
            mountedRef.current = false;
            if (cb) cb?.();
        };
    }, dependencies);

    return mountedRef
}
