import '../../../styles/Navbar.css';
import '../../../styles/simplebar.css';
import 'simplebar/dist/simplebar.min.css';

import { Badge } from 'antd';
import React from 'react';

import { getUnAcknowledgedAlerts } from '../../../helpers';
import { colors } from '../../../helpers/allStyles';
import utils from '../../../helpers/utils';
import { AlertsContextProvider } from '../../../hooks/useContext';
import { RenderAlertMessages } from '../../../pages/alerts/components/NotificationsDrawer';
import { AlertsContext } from '../../../types';
import GPop from '../../global/GPop';

export default function MenuSwitch({ text, ...props }: {
    text: string,
    isSettings?: boolean
    showSettings: boolean
    setShowSettings: React.Dispatch<React.SetStateAction<boolean>>
}) {
    const isSettings = !!props.isSettings
    const isSettingsClass = isSettings ? 'is-settings' : '';

    const [alertData] = React.useContext(AlertsContextProvider) as AlertsContext;

    const unSelected = isSettings ? !props.showSettings : props.showSettings

    const alertsList = Object.values(alertData.alerts || [])
    const unAcknowledgedAlerts = getUnAcknowledgedAlerts(alertsList, utils.uid, utils.auth.getEmail());
    const errorAlerts = unAcknowledgedAlerts.filter((ua) => ua.type === 'error')
    const showNotify = isSettings && errorAlerts.length && (utils.uid !== '1' && utils.user.type !== 'agency');

    return <>
        {showNotify ? <GPop {...{
            placement: 'right',
            content: <RenderAlertMessages activeAlerts={errorAlerts} isExternal />,
            overlayInnerStyle: { maxWidth: 500, maxHeight: 250, overflowX: 'hidden', overflowY: 'auto', scrollbarWidth: 'thin' },
        }}>
            <span
                className={`menu-switch ${isSettingsClass} ${unSelected ? 'unselected' : 'selected'}`}
                style={{
                    position: 'relative',
                    borderRadius: isSettings ? '0px 30px 30px 0px' : '30px 0px 0px 30px',
                }}
                onClick={(e) => {
                    e.preventDefault()
                    props.setShowSettings(isSettings)
                    utils.local.setLocal('sidebar:showSettings', isSettings)
                }}
            >
                <div style={{ position: 'absolute', top: -8, right: 2 }}>
                    <Badge count={errorAlerts.length} style={{ backgroundColor: colors.primary.error500 }} size='small' offset={[15, 0]} />
                </div>
                <span
                    style={{
                        color: 'inherit',
                        transition: 'color 0.3s ease, background 0.3s ease',
                    }}>
                    {text}
                </span>
            </span>
        </GPop> : <>
            <span
                className={`menu-switch ${isSettingsClass} ${unSelected ? 'unselected' : 'selected'}`}
                style={{
                    borderRadius: isSettings ? '0px 30px 30px 0px' : '30px 0px 0px 30px',
                }}
                onClick={(e) => {
                    e.preventDefault()
                    props.setShowSettings(isSettings)
                    utils.local.setLocal('sidebar:showSettings', isSettings)
                }}
            >
                {text}
            </span>
        </>}
    </>
}
