import React, { useEffect, useMemo, useState } from "react";
// @ts-ignore
import debounce from 'lodash/debounce';


// export function useSearchDebounce(delay = 300) {
//     const [searchTerm, setSearchTerm] = useState<any>(undefined);

//     const handleChange = (str: any) => setSearchTerm(str);

//     const debouncedResults = useMemo(() => debounce(handleChange, delay), []);

//     useEffect(() => {
//         return () => debouncedResults.cancel();
//     });

//     return [searchTerm, debouncedResults]
// }


// export function useSearchDebounce(delay = 200) {

//     const [search, setSearch] = useState<string>('');
//     const [inputValue, setInputValue] = useState<string>('');
//     const setServicesValueDebounced: any = React.useRef(debounce((v: any) => setSearch(v), delay));

//     const updateValue = (v: string) => {
//         setInputValue(v);
//         setServicesValueDebounced.current(v);
//     };

//     return [inputValue, search, updateValue] as [string, string, (x: string) => void]
// }


export type SearchDataType = [string, string, (x: string, cb?: (v: string) => void) => void]

export function useSearchDebounce(input?: string, delay = 300, searchCallback?: (v: string) => void) {
    const [tid, setTID] = useState<any>(undefined)
    const [search, _setSearch] = useState<string>(input || '');
    const [inputValue, setInputValue] = useState<string>(input || '');
    // const setServicesValueDebounced: any = React.useRef(debounce((v: any) => setSearch(v), delay));

    const setSearch = (v: string, cb?: (v: string) => void) => {
        _setSearch(v)
        cb && cb(v)
        searchCallback && searchCallback(v)
    }

    const updateData = (v: string, cb?: (v: string) => void) => {
        setInputValue(v);
        if (tid) clearTimeout(tid)
        setTID(setTimeout(() => {
            setSearch(v, cb)
        }, delay))
        // setServicesValueDebounced.current(v);
    };

    useEffect(() => {
        return () => tid ? clearTimeout(tid) : undefined
    }, [search]);

    return [inputValue, search, updateData] as [string, string, (x: string) => void] as SearchDataType
}


// export function useSearchDebounce(delay = 300) {
//     const [inputValue, setInputValue] = useState<string>('');
//     const [searchValue, setSearchValue] = useState<string>('');

//     useEffect(() => {
//         const timer = setTimeout(() => {
//             setSearchValue(inputValue);
//         }, delay);

//         return () => {
//             clearTimeout(timer);
//         };
//     }, [inputValue, delay]);

//     const updateValue = (newValue: string): void => {
//         setInputValue(newValue);
//     };

//     return [inputValue, searchValue, updateValue] as [string, string, (x: string) => void]
// };
