import { fetchAPI } from ".";
import { utils } from "../helpers";
import { TransactionalStats, User } from "../types";


function getUser(uid?: string): Promise<User> {
    return fetchAPI(`/users/${uid || utils.uid}`, { method: 'GET' })
}

function setInvoiceFreeze(status: boolean): Promise<void> {
    return fetchAPI(`/users/invoiceFreeze/:uid/${status}`, { method: 'PUT' })
}

type TransactionQuery = {
    uid?: string
    to?: string // phone number
    start?: number // timestamp
    end?: number // timestamp
}

function getTransactionalStats(query: TransactionQuery): Promise<TransactionalStats> {
    const config = Object.entries(query).filter(([_, value]) => value && _ !== 'uid').map(([key, value]) => `${key}=${value}`)
    return fetchAPI(`/transactional/stats/:uid?${config.join('&')}`, { uid: query.uid, method: 'GET', forceToken: utils.TOKEN })
}

export const UsersAPI = {
    getUser,
    setInvoiceFreeze,
    getTransactionalStats
}