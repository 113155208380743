import moment from 'moment';

import utils from './utils';
import { parseNumber } from './math';

interface Config {
	dataFormat: boolean; // * YYYY-MM-DD format used in charts and what not
	hideTime: boolean; // * hide hour, minute, second, am/pm
	friendly: boolean; // * allow for "last Wednesday at 12 pm" and "This Monday at 4am" formats
	day: boolean | string;
	month: boolean | string;
	second: boolean | string;
	minute: boolean | string;
	hour: boolean | string;
	year: boolean | string;
	hour12: boolean;
	hour12Lower: boolean;
	calendar: boolean;
	standard: Intl.DateTimeFormatOptions;
	timezone: string;
	showNameDay: boolean; // Show the day of the week name, IE "Monday", "Tuesday", etc
	file?: boolean
	format?: string; // MM/DD/YYYY, YYYY-MM-DD, etc
}

const defaultConfig = {
	day: true,
	year: true,
	hour: true,
	month: true,
	minute: true,
	second: false,
	hour12: true,
	calendar: false,
	friendly: true,
	standard: {},
} as Partial<Config>

const formatTimestamp = (date: any, config?: Partial<Config>) => {
	if (!!config?.file) config = { ...config, friendly: false, calendar: true, hideTime: true }
	config = { ...defaultConfig, ...config }

	let { realDate, valid }: { realDate: Date, valid: boolean } = parseDate(date);
	if (!valid) return 'N/A';

	const getFormat = (v: string | boolean | undefined, def: string | undefined) => typeof v === 'string' ? v || def : v ? def : undefined;

	if (config.dataFormat) return formatDateShort(realDate)

	let format = utils.trimObject(config?.format ? parseCustomFormat(config.format) : {
		calendar: config.hideTime ? false : config.calendar,
		day: getFormat(config?.day, config.calendar ? '2-digit' : 'numeric'),
		month: getFormat(config?.month, config.calendar ? '2-digit' : 'short'),
		year: getFormat(config.year, 'numeric'),
		hour12: config.hideTime ? undefined : config.hour12,
		hour: getFormat(config.hour, config.hideTime ? undefined : 'numeric'),
		minute: getFormat(config.minute, config.hideTime ? undefined : '2-digit'),
		second: getFormat(config.second, config.hideTime ? undefined : '2-digit'),
		timeZone: config.timezone,
		...config?.standard,
	} as Intl.DateTimeFormatOptions), formatDate = `${realDate.toLocaleString('default', format)}`;

	if (config.showNameDay) {
		const day = realDate.toLocaleString('default', { weekday: 'long', ...config.standard, });
		formatDate = `${day}, ${formatDate}`;
	}

	if (config.format) return formatDate

	const lastWeek = isLastWeek(realDate), thisWeek = isThisWeek(realDate)

	if (config.friendly && (lastWeek || thisWeek)) {
		let weekDay = realDate.toLocaleString('default', { weekday: 'long', ...config.standard, }),
			timeRange = realDate.toLocaleString('default', { hour: 'numeric', minute: '2-digit', ...config.standard, })
		formatDate = `${lastWeek ? 'Last ' : thisWeek ? '' : ''}${isToday(realDate) ? 'Today' : isYesterday(realDate) ? 'Yesterday' : weekDay} ${config.hideTime ? '' : `at ${timeRange}`}`
	}

	const formatted = config.hour12Lower ? formatDate.replace('AM', 'am').replace('PM', 'pm') : formatDate

	// If file, we replace spaces with _'s and : with _'s
	if (!!config.file) return formatted.replaceAll(/ /g, '_').replaceAll(/:/g, '_')
	return formatted
}

// returns the timestamp formatted as an excel-compatible string (YYYY-MM-DD hh:mm:ss)
const formatTimestampCSV = (date: any): string => {
	const { realDate, valid }: { realDate: Date, valid: boolean } = parseDate(date);
	if (!valid) return 'N/A';
	const formatted = new Intl.DateTimeFormat('default', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		hour12: false
	}).format(realDate);
	const [monthDayYear, time] = formatted.split(', ');
	const [month, day, year] = monthDayYear.split('/');
	return `${year}-${month}-${day} ${time}`;
}

// Helper function to parse custom format string
const parseCustomFormat = (format: string): Intl.DateTimeFormatOptions => {
	const options: Intl.DateTimeFormatOptions = {};

	if (format.includes('YYYY')) {
		options.year = 'numeric';
	} else if (format.includes('YY')) {
		options.year = '2-digit';
	}

	if (format.includes('MM')) {
		options.month = '2-digit';
	} else if (format.includes('M')) {
		options.month = 'numeric';
	}

	if (format.includes('DD')) {
		options.day = '2-digit';
	} else if (format.includes('D')) {
		options.day = 'numeric';
	}

	if (format.includes('h')) {
		options.hour = 'numeric';
	}

	if (format.includes('mm')) {
		options.minute = '2-digit';
	} else if (format.includes('m')) {
		options.minute = 'numeric';
	}

	if (format.includes('ss')) {
		options.second = '2-digit';
	} else if (format.includes('s')) {
		options.second = 'numeric';
	}

	if (format.includes('a')) {
		options.hour12 = true;
	}

	return options;
};

const defaultTimes = ['0001-01-01T00:00:00Z']
// * This function should convert any invalid dates a valid one or return 'N/A'
const parseDate = (date: any): { valid: boolean, realDate: Date } => {
	let realDate: Date = new Date(), valid = false
	if (date === 0) return { valid: false, realDate }
	try {
		let fmtDate: any = date instanceof Date ? date : JSON.parse(JSON.stringify(date || 0))
		if (moment.isMoment(fmtDate)) fmtDate = fmtDate.toDate();
		if (typeof date === 'number') {
			const isMiliseconds = date > 1000000000000
			fmtDate = new Date(isMiliseconds ? date : date * 1000);
		}
		if (typeof date === 'string' && !defaultTimes?.includes(date)) fmtDate = new Date(date);
		if (typeof date === 'object' && !moment.isMoment(fmtDate)) fmtDate = new Date(fmtDate);
		if (fmtDate instanceof Date) {
			realDate = fmtDate
			valid = true
		}
	} catch (error) {
		valid = false
		if (!realDate) realDate = new Date()
		console.warn('Invalid date', date, error)
	}
	return { valid, realDate }
}

// * Function used with date picker to get a safe unix date
const safeDate = (value: any, startOf?: boolean) => {
	if (!value) return 0
	const m = value.unix ? value : moment(value)
	return startOf ? m.startOf('day').unix() : m.unix() as number
}

const isThisWeek = (date: Date) => {
	const todayObj = new Date(), todayDate = todayObj.getDate(), todayDay = todayObj.getDay();
	const fd = new Date(todayObj.setDate(todayDate - todayDay)), ld = new Date(fd);
	ld.setDate(ld.getDate() + 6);
	return date >= fd && date <= ld;
}

const isLastWeek = (date: Date) => {
	const todayObj = new Date(), todayDate = todayObj.getDate(), todayDay = todayObj.getDay();
	const fd = new Date(todayObj.setDate((todayDate - todayDay) - 7)), ld = new Date(fd);
	ld.setDate(ld.getDate() + 6);
	return date >= fd && date <= ld;
}

const isToday = (date: Date, today = new Date()) => {
	return date.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0)
}

const isYesterday = (date: Date, yesterday = new Date()) => {
	yesterday.setDate(yesterday.getDate() - 1)
	return yesterday.getDate() === date.getDate()
}

const isSameDate = (date: Date, compare = new Date()) => {
	return date.getDate() == compare.getDate() && date.getMonth() == compare.getMonth() && date.getFullYear() == compare.getFullYear()
}


const formatDateShort = (date: Date) => {
	var d = new Date(date), month = '' + (d.getMonth() + 1), day = '' + d.getDate(), year = d.getFullYear();
	return [year, `${month.length < 2 ? '0' : ''}${month}`, `${day.length < 2 ? '0' : ''}${day}`].join('-');
}

const getDaysSince = (x: Date, date: string) => (Math.abs(x.getTime() - new Date(date).getTime()) / (24 * 60 * 60 * 1000))

const getCountDownTime = (endDate: Date) => {
	const now = new Date().getTime(), distance = endDate.getTime() - now,
		days = Math.floor(distance / (1000 * 60 * 60 * 24)),
		hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
		minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
		seconds = Math.floor((distance % (1000 * 60)) / 1000);
	if (distance < 0) return null
	let string = ''
	if (days > 0) string += `${days}d `
	if (hours > 0) string += `${hours}h `
	if (minutes > 0) string += `${minutes}m `
	if (seconds > 0) string += `${seconds}s `
	string = string.trim()
	return { days, hours, minutes, seconds, string }
}

function getDatesBetween(startDate: Date, endDate: Date) {
	const dates = []
	let currentDate = startDate
	const addDays = function (days: any) { // @ts-ignore
		const date = new Date(this.valueOf())
		date.setDate(date.getDate() + days)
		return date
	}
	while (currentDate <= endDate) {
		dates.push(currentDate)
		currentDate = addDays.call(currentDate, 1)
	}
	return dates
}

type Func<T extends any[], R> = (...args: T) => R;
type AsyncFunc<T extends any[], R> = (...args: T) => R | Promise<R>;


const getTimeSinceMilliseconds = (start: number, end: number = Date.now(), showMS = false, short = false): string => {
	const diff = end - start; // difference in milliseconds

	if (!start) return 'N/A'

	// if diff is less than 1 second return formatted milliseconds
	if (diff < 1000) {
		return showMS ? `${diff} milliseconds ago` : 'Just now';
	}

	// convert milliseconds to seconds
	const totalSeconds = Math.floor(diff / 1000);

	// convert seconds to a friendly format
	let friendly;
	if (totalSeconds < 60) {
		friendly = `${totalSeconds} seconds ago`;
	} else if (totalSeconds < 3600) {
		const minutes = Math.floor(totalSeconds / 60);
		const remainingSeconds = totalSeconds % 60;
		friendly = `${minutes} minute${minutes > 1 ? 's' : ''}`;
		if (remainingSeconds > 0 && !short) {
			friendly += `, ${remainingSeconds} seconds ago`;
		} else {
			friendly += ` ago`;
		}
	} else if (totalSeconds < 86400) {
		const hours = Math.floor(totalSeconds / 3600);
		const remainingMinutes = Math.floor((totalSeconds % 3600) / 60);
		friendly = `${hours} hour${hours > 1 ? 's' : ''}`;
		if (remainingMinutes > 0 && !short) {
			friendly += `, ${remainingMinutes} minutes ago`;
		} else {
			friendly += ` ago`;
		}
	} else {
		const days = Math.floor(totalSeconds / 86400);
		const remainingHours = Math.floor((totalSeconds % 86400) / 3600);
		friendly = `${days} day${days > 1 ? 's' : ''}`;
		if (remainingHours > 0 && !short) {
			friendly += `, ${remainingHours} hours ago`;
		} else {
			friendly += ` ago`;
		}
	}

	return friendly;
};

/**
 * Calculate the number of days between two dates.
 * @param startDate - The start date.
 * @param endDate - The end date.
 * @returns The number of days between the two dates.
 */
function getDaysBetween(startDate: Date, endDate: Date): number {
	// Strip time information for comparison
	const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
	const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

	// Calculate the difference in full days
	const differenceMs = end.getTime() - start.getTime();
	const differenceDays = differenceMs / (1000 * 60 * 60 * 24);

	// If startDate and endDate are on different days, but less than 24 hours apart, include both days
	if (differenceDays > 0 && differenceDays < 1) {
		return 1;
	}

	return Math.ceil(differenceDays);
}

const formatTimeMinSec = (time: number, mili?: boolean) => {
	if (mili) time = time / 1000;
	const minutes = Math.floor(time / 60);
	const seconds = Math.floor(time % 60);
	return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

export function formatHours(hrs?: number, alwaysDaysIfApplys?: boolean): [string, string] {
	// This function simplifies the original by reducing conditionals and using early returns.
	if (hrs === undefined || hrs === 0) {
		return ["0", ""];
	}

	const hoursIn100Years = 24 * 365 * 100;
	if (hrs > hoursIn100Years) {
		const foreverWords = ["forever", "infinite",];
		const foreverWord = foreverWords[Math.floor(Math.random() * foreverWords.length)];
		return [foreverWord, ""];
	}

	if (hrs === 1) {
		return ["1 hour", "hour"];
	}

	if (hrs % 24 === 0) {
		const numDays = hrs / 24;
		const specialCases: { [key: number]: [string, string] } = {
			1: ["1 day", "day"],
			7: (Math.random() < 0.5 || alwaysDaysIfApplys) ? ["7 days", "days"] : ["1 week", "week"],
			30: (Math.random() < 0.5 || alwaysDaysIfApplys) ? ["30 days", "days"] : ["1 month", "month"],
			365: (Math.random() < 0.5 || alwaysDaysIfApplys) ? ["365 days", "days"] : ["1 year", "year"],
			730: ["2 years", "years"],
			1095: ["3 years", "years"]
		};

		if (specialCases[numDays]) {
			return specialCases[numDays];
		} else if (numDays % 365 === 0) {
			const years = numDays / 365;
			return [`${years} ${years > 1 ? "years" : "year"}`, years > 1 ? "years" : "year"];
		} else if (numDays > 30) {
			const months = Math.floor(numDays / 30);
			const isNotEvenMonth = numDays % 30 !== 0;
			// 50/50 of returning "x months" or "x days"
			if (Math.random() < 0.50 || isNotEvenMonth || alwaysDaysIfApplys) {
				return [`${numDays} days`, "days"];
			}
			return [`${months} ${months > 1 ? "months" : "month"}`, months > 1 ? "months" : "month"];
		} else {
			return [`${numDays} ${numDays > 1 ? "days" : "day"}`, numDays > 1 ? "days" : "day"];
		}
	}

	return [`${hrs} hours`, "hours"];
}

// Returns the start and end of the day TS in unix seconds
const getTodayUnix = (date: Date = new Date()) => {
	const start = new Date(date), end = new Date(date);
	start.setHours(0, 0, 0, 0);
	end.setHours(23, 59, 59, 999);
	return {
		start: Math.floor(start.getTime() / 1000),
		now: Math.floor(date.getTime() / 1000),
		end: Math.floor(end.getTime() / 1000)
	}
}

export const timeUtils = {
	formatHours,
	formatTimeMinSec,
	formatTimestamp,
	formatTimestampCSV,
	parseDate,
	getTodayUnix,
	getDateMinusDays(days: number): string {
		const date = new Date();
		date.setDate(date.getDate() - days);

		const month = (date.getMonth() + 1).toString().padStart(2, '0');
		const day = date.getDate().toString().padStart(2, '0');
		const year = date.getFullYear().toString();

		return `${month}-${day}-${year}`;
	},

	fmtWord: (v: string) => {
		switch (v) {
			case 'day':
				return 'Daily';
			case 'week':
				return 'Weekly';
			case 'month':
				return 'Monthly';
			case 'quarter':
				return 'Quarterly';
			case 'year':
				return 'Yearly';
			case 'yesterdays':
				return 'Yesterday';
			case 'lastWeeks':
				return 'Last Week';
			case 'lastMonths':
				return 'Last Month';
			case 'lastQuarters':
				return 'Last Quarter';
			case 'lastYears':
				return 'Last Year';
			case 'nothing':
				return 'No Other Time';
			case 'oneDay':
				return '1 Day Before';
			case 'oneWeek':
				return '1 Week Before';
			case 'oneMonth':
				return '1 Month Before';
			case 'oneQuarter':
				return '1 Quarter Before';
			case 'oneYear':
				return '1 Year Before';
			default:
				return v;
		}
	},

	units: {
		SECOND_MS: 1000,
		MINUTE_MS: 1000 * 60,
		HOUR_MS: 1000 * 60 * 60,
		DAY_MS: 1000 * 60 * 60 * 24,
		WEEK_MS: 1000 * 60 * 60 * 24 * 7,
		MONTH_MS: 1000 * 60 * 60 * 24 * 30,
		YEAR_MS: 1000 * 60 * 60 * 24 * 365,

		SECOND_S: 1,
		MINUTE_S: 60,
		HOUR_S: 60 * 60,
		DAY_S: 60 * 60 * 24,
		WEEK_S: 60 * 60 * 24 * 7,
		MONTH_S: 60 * 60 * 24 * 30,
		YEAR_S: 60 * 60 * 24 * 365,
	},

	validateDate: parseDate,
	safeDate,
	isThisWeek,
	isLastWeek,
	isToday,
	isYesterday,
	isSameDate,
	formatDateShort,
	getDaysSince,
	getCountDownTime,
	getDatesBetween,
	getDaysBetween,
	getTimeSinceMilliseconds,


	areDatesInSameMonth: (date1: Date, date2: Date) => date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear(),
	getOrdinalSuffix(day: number) {
		if (day >= 11 && day <= 13) return 'th'
		switch (day % 10) {
			case 1:
				return 'st';
			case 2:
				return 'nd';
			case 3:
				return 'rd';
			default:
				return 'th';
		}
	},
	calendarRange: (date1: Date, date2: Date) => {
		const options: any = { month: 'short', day: 'numeric' };
		const formatter = new Intl.DateTimeFormat('en-US', options);

		const month1 = date1.getMonth();
		const month2 = date2.getMonth();

		const day1 = date1.getDate();
		const day2 = date2.getDate();

		if (month1 === month2) {
			if (day1 === day2) {
				return `${formatter.format(date1)}${timeUtils.getOrdinalSuffix(day1)}`;
			} else {
				return `${formatter.format(date1)}${timeUtils.getOrdinalSuffix(day1)} - ${day2}${timeUtils.getOrdinalSuffix(day2)}`;
			}
		} else {
			return `${formatter.format(date1)}${timeUtils.getOrdinalSuffix(day1)} - ${formatter.format(date2)} ${timeUtils.getOrdinalSuffix(day2)}`;
		}
	},

	// * This function will time any async function and log the time it took to execute
	timeAsync<T extends any[], R>(fn: AsyncFunc<T, R>, name?: string): AsyncFunc<T, R> {
		return async function (...args: T): Promise<R> {
			let startTime = performance.now();
			let result = await fn(...args);
			console.info(`Function "${name || fn.name}" took ${timeUtils.formatTimedResult(startTime).trim()} to execute.`)
			return result;
		};
	},
	// * This function will time any function and log the time it took to execute
	timeFunction<T extends any[], R>(fn: Func<T, R>, name?: string, opts?: { ignore?: boolean }): Func<T, R> {
		return function (...args: T) {
			let startTime = performance.now();
			let result = fn(...args);
			console.info(`Function "${fn.name || name}" took ${timeUtils.formatTimedResult(startTime).trim()} to execute.`)
			return result;
		};
	},
	formatTimedResult: (startTime: number, endTime = performance.now(), options?: { showFull?: boolean, hideMilliseconds?: boolean, hideSeconds?: boolean, hideMinutes?: boolean }) => {
		let executionTime = endTime - startTime;
		return timeUtils.formatMilliseconds(executionTime, { hideMilliseconds: false, ...options })
	},

	// for cooldowns
	formatMilliseconds: (milliseconds: number, options: {
		hideMilliseconds?: boolean,
		showFull?: boolean,
		hideSeconds?: boolean,
		hideMinutes?: boolean,
	} = { hideMilliseconds: true }) => {
		if (milliseconds < timeUtils.units.SECOND_MS) return `${milliseconds}ms`;
		// round for performance.now
		milliseconds = Math.round(milliseconds);
		const days = Math.floor(milliseconds / timeUtils.units.DAY_MS);
		milliseconds %= timeUtils.units.DAY_MS;
		const hours = Math.floor(milliseconds / timeUtils.units.HOUR_MS);
		milliseconds %= timeUtils.units.HOUR_MS;
		const minutes = Math.floor(milliseconds / timeUtils.units.MINUTE_MS);
		milliseconds %= timeUtils.units.MINUTE_MS;
		const seconds = Math.floor(milliseconds / timeUtils.units.SECOND_MS);
		milliseconds %= timeUtils.units.SECOND_MS;
		let timeString = '';
		if (days > 0) timeString += `${days}${options?.showFull ? ` day${days > 1 ? 's' : ''}` : 'd'} `;
		if (hours > 0) timeString += `${hours}${options?.showFull ? ` hour${hours > 1 ? 's' : ''}` : 'h'} `;
		// if (minutes > 0) timeString += `${minutes}${options?.showFull ? ` min${minutes > 1 ? 's' : ''}` : 'm'} `;
		if (!options.hideMinutes && minutes > 0) timeString += `${minutes}${options?.showFull ? ` min${minutes > 1 ? 's' : ''}` : 'm'} `;
		// if (seconds > 0) timeString += `${seconds}${options?.showFull ? ` sec${seconds > 1 ? 's' : ''}` : 's'} `;
		if (!options.hideSeconds && seconds > 0) timeString += `${seconds}${options?.showFull ? ` sec${seconds > 1 ? 's' : ''}` : 's'} `;
		if (!options.hideMilliseconds && milliseconds > 0) timeString += `${milliseconds}ms `;
		return timeString.trim();
	},

	areDatesMoreThanAYearApart: (date1: Date, date2: Date) => {
		const oneYearInMilliseconds: number = 365 * 24 * 60 * 60 * 1000; // Assuming a year has 365 days

		const differenceInMilliseconds: number = Math.abs(date1.getTime() - date2.getTime());
		const differenceInYears: number = differenceInMilliseconds / oneYearInMilliseconds;

		return differenceInYears >= 1;
	},
	formatHourNumber: (hour?: number | string): string => {
		if (hour === undefined) return 'N/A'
		hour = parseNumber(hour)
		if (hour === 0) return '12 AM'
		if (hour === 12) return '12 PM'
		if (hour < 12) return `${hour} AM`
		return `${hour - 12} PM`
	},
	getTimeFor
}

type DateFinderOpts = {
	startOf?: boolean // * If true, will return the start of the time frame, IE if today, will return 12:00 AM
	endOf?: boolean // * If true, will return the end of the time frame, IE if today, will return 11:59 PM
}

// Returns Milliseconds timestamp for the start of the given date
const DateFinders = {
	today: (options?: DateFinderOpts) => {
		const today = new Date();
		if (options?.startOf) today.setHours(0, 0, 0, 0)
		if (options?.endOf) today.setHours(23, 59, 59, 999)
		return today.getTime()
	},
	yesterday: (options?: DateFinderOpts) => {
		const yesterday = new Date();
		yesterday.setDate(yesterday.getDate() - 1);
		if (options?.startOf) yesterday.setHours(0, 0, 0, 0)
		if (options?.endOf) yesterday.setHours(23, 59, 59, 999)
		return yesterday.getTime()
	},
	tomorrow: (options?: DateFinderOpts) => {
		const tomorrow = new Date();
		tomorrow.setDate(tomorrow.getDate() + 1);
		if (options?.startOf) tomorrow.setHours(0, 0, 0, 0)
		if (options?.endOf) tomorrow.setHours(23, 59, 59, 999)
		return tomorrow.getTime()
	},
	last7Days: (options?: DateFinderOpts) => {
		const today = new Date();
		const last7Days = new Date(today.setDate(today.getDate() - 7));
		if (options?.startOf) last7Days.setHours(0, 0, 0, 0)
		if (options?.endOf) last7Days.setHours(23, 59, 59, 999)
		return last7Days.getTime()
	},
	last30Days: (options?: DateFinderOpts) => {
		const today = new Date();
		const last30Days = new Date(today.setDate(today.getDate() - 30));
		if (options?.startOf) last30Days.setHours(0, 0, 0, 0)
		if (options?.endOf) last30Days.setHours(23, 59, 59, 999)
		return last30Days.getTime()
	},
	last90Days: (options?: DateFinderOpts) => {
		const today = new Date();
		const last90Days = new Date(today.setDate(today.getDate() - 90));
		if (options?.startOf) last90Days.setHours(0, 0, 0, 0)
		if (options?.endOf) last90Days.setHours(23, 59, 59, 999)
		return last90Days.getTime()
	},
	last12months: (options?: DateFinderOpts) => {
		const today = new Date();
		const last12months = new Date(today.setMonth(today.getMonth() - 12));
		if (options?.startOf) last12months.setHours(0, 0, 0, 0)
		if (options?.endOf) last12months.setHours(23, 59, 59, 999)
		return last12months.getTime()
	}
} as const

export type DateFinder = keyof typeof DateFinders


function getTimeFor(finder: DateFinder, options?: DateFinderOpts & {
	unix?: boolean
}) {
	const value = DateFinders[finder](options)
	if (options?.unix) return Math.floor(value / 1000)
	return value
}

(window as any)['timeUtils'] = timeUtils
export default timeUtils
