import { mergeThese } from '../../wallet/utils';
import { allStyles, colors, colorUtils, utils } from '../../../helpers';
import { Store } from '../../../types';
import { Survey, SurveyConfig } from '../types';
import { defaultDividerColor } from '../../wallet/assets/walletStyles';

export const getSurveyLinks = (survey: Partial<Survey>, uid: string, options: { storeID?: number; stores?: Store[] }) => {
    if (!survey.id) return { csvData: [], surveyURL: '', surveyMacro: '' }
    const host = `${document.location.origin}`;
    let surveyURL = `${host}/survey/c/${uid}/${survey.id}`;
    const surveyMacro = `{{survey-${survey.id}}}`


    let csvData: {
        storename: string | undefined,
        link: string,
        embed: string,
    }[] = [{
        storename: "Default (No store)",
        link: `${surveyURL}`,
        embed: `<iframe width="500" height="800" src="${surveyURL}" frameborder="0" allow="accelerometer; encrypted-media; gyroscope;"></iframe>`
    }]

    if (options.stores?.length && utils.isRetail()) {
        csvData = (options.stores || [])
            .filter((store: Store) => !store.archived)
            .map((store: Store) => ({
                storename: store.name,
                link: `${surveyURL}?storeID=${store.id}`,
                embed: `<iframe width="500" height="800" src="${surveyURL}?storeID=${store.id}" frameborder="0" allow="accelerometer; encrypted-media; gyroscope;"></iframe>`
            }));
    }

    surveyURL += `${options.storeID && options.storeID != -1 ? `?storeID=${options.storeID}` : ''}`

    return { csvData, surveyURL, surveyMacro }
}

export const defaultText = {
    title: {
        color: '#191919',
        fontWeight: 600,
        fontSize: 16
    },
    description: {
        color: '#191919',
        fontWeight: 400,
        fontSize: 14
    },
    small: {
        color: '#191919',
        fontWeight: 400,
        fontSize: 12
    }
}

export function createSurveyConfig(uid: string, survey: Survey, storeID: string | undefined): SurveyConfig {
    const OG_CONFIG = survey?.config || {}

    const storeConfig = OG_CONFIG?.storeLocations?.[storeID || ''] || {}
    const clean = (x: any) => JSON.parse(JSON.stringify(x || {}))
    const config = mergeThese(clean(OG_CONFIG), clean(storeConfig))
    const toggles = config?.toggles || {}
    let transparentBar = ((toggles?.hideBar ? 'transparent' : 'rgba(0, 0, 0, 0.7)'))

    const formStyle = {
        maxWidth: '100%',
        padding: '0px 10px 0px 10px',
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        columnGap: 32,
        rowGap: 16,
        customCSS: config?.formStyle?.customCSS || {},
        demoCSS: config?.formStyle?.demoCSS || {},
    }

    const avatar = `https://lab.alpineiq.com/avatar/${uid}`
    const coverImage = config?.headerStyle?.imageSource || `${(uid === '1034' || !!utils.TOKEN) ? 'https://lab.alpineiq.com' : location.origin}/cover/${uid}`

    let background: string = ((toggles.hideCover || !coverImage) ? 'transparent' : `url(${coverImage})`)

    let userName = config?.userName || ""
    if (!userName && utils.user.profile?.name) {
        userName = utils.user.profile.name
    }

    return ({

        storeLocations: config?.storeLocations || {},

        userName,
        storeName: config?.storeName || "",

        toggles,
        iconName: config?.iconName === undefined ? 'badge-check' : config?.iconName,
        iconType: config?.iconType || 'duotone',

        logo: {
            width: toggles.hideName ? 100 : 64,
            height: toggles.hideName ? 100 : 64,
            borderRadius: toggles.squareLogo ? 6 : 33.33,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            objectFit: 'cover',
            imageSource: config?.logo?.imageSource === undefined ? avatar : config?.logo?.imageSource,
            customCSS: config?.logo?.customCSS || {},
            demoCSS: config?.logo?.demoCSS || {},
        },

        pageStyle: {
            display: 'flex',
            justifyContent: 'center',
            minWidth: 350,
            padding: 16,
            customCSS: config?.pageStyle?.customCSS || {},
            demoCSS: config?.pageStyle?.demoCSS || {},
        },

        wrapperStyle: {
            maxWidth: config?.wrapperStyle?.maxWidth || 700,
            minWidth: config?.wrapperStyle?.minWidth || 350,
            border: `1px solid ${defaultDividerColor}`,
            boxShadow: config?.wrapperStyle?.boxShadow !== undefined ? config?.wrapperStyle?.boxShadow : '0 0 10px 0 rgba(183,192,206,.1)',
            borderRadius: 6,
            paddingBottom: 16,
            customCSS: config?.wrapperStyle?.customCSS || {},
            demoCSS: config?.wrapperStyle?.demoCSS || {},
        },

        thankYouPopup: {
            title: config?.thankYouPopup?.title || 'Thank you!',
            text: config?.thankYouPopup?.text || 'Your feedback has been submitted.',
            disabled: config?.thankYouPopup?.disabled || false,
            duration: (config?.thankYouPopup?.duration || 0) >= 1 ? config?.thankYouPopup?.duration : 8,
            notification: config?.thankYouPopup?.notification || false,
        },

        collectInfoPopup: {
            title: config?.collectInfoPopup?.title || 'Enter Email or Phone number',
            text: config?.collectInfoPopup?.text || 'This information is collected in order to link this survey response to your account. Without this information you will not receive credit for your completed survey.',
            email: config?.collectInfoPopup?.email !== undefined ? config.collectInfoPopup.email : true,
            phone: config?.collectInfoPopup?.phone !== undefined ? config.collectInfoPopup.phone : true,
        },

        headerStyle: {
            textAlign: 'center',
            background,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            padding: '22px 0px 22px 0px',
            borderTopLeftRadius: 6,
            borderTopRightRadius: 6,
            width: '100%',
            minWidth: '100%',
            imageSource: config?.headerStyle?.imageSource === undefined ? coverImage : config?.headerStyle?.imageSource,
            customCSS: config?.headerStyle?.customCSS || {},
            demoCSS: config?.headerStyle?.demoCSS || {},
        },

        headerInnerStyle: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            alignContent: 'center',
            flexDirection: 'column',
            padding: '10px 0px',
            background: transparentBar,
            customCSS: config?.headerInnerStyle?.customCSS || {},
            demoCSS: config?.headerInnerStyle?.demoCSS || {},
        },

        headerName: {
            paddingTop: 8,
            ...defaultText.title,
            color: '#ffffff',
            customCSS: config?.headerName?.customCSS || {},
            demoCSS: config?.headerName?.demoCSS || {},
        },

        bodyStyle: {
            padding: '24px 16px',
            textAlign: 'center',
            customCSS: config?.bodyStyle?.customCSS || {},
            demoCSS: config?.bodyStyle?.demoCSS || {},
        },

        buttonWrapper: {
            paddingTop: 16,
            textAlign: 'center',
            customCSS: config?.buttonWrapper?.customCSS || {},
            demoCSS: config?.buttonWrapper?.demoCSS || {},
        },

        storeNameStyle: {
            ...defaultText.small,
            textAlign: 'center',
            padding: '0px 10% 12px 10%',
            customCSS: config?.storeNameStyle?.customCSS || {},
            demoCSS: config?.storeNameStyle?.demoCSS || {},
        },

        titleStyle: {
            ...defaultText.title,
            displayText: config?.titleStyle?.displayText !== undefined ? config?.titleStyle?.displayText : 'Customer Satisfaction Survey',
            textAlign: 'center',
            padding: '0px 10% 8px 10%',
            customCSS: config?.titleStyle?.customCSS || {},
            demoCSS: config?.titleStyle?.demoCSS || {},
        },

        descriptionStyle: {
            ...defaultText.description,
            displayText: config?.descriptionStyle?.displayText !== undefined ? config?.descriptionStyle?.displayText : 'Your voice matters to us! We are continuously striving to improve our services and your feedback plays a vital role in this process.',
            textAlign: 'center',
            padding: '0px 10% 16px 10%',
            customCSS: config?.descriptionStyle?.customCSS || {},
            demoCSS: config?.descriptionStyle?.demoCSS || {},
        },

        descriptionSubStyle: {
            ...defaultText.small,
            displayText: config?.descriptionSubStyle?.displayText !== undefined ? config?.descriptionSubStyle?.displayText : 'Kindly take a few moments to share your thoughts about your recent interaction with us.',
            textAlign: 'center',
            padding: '0px 10% 16px 10%',
            customCSS: config?.descriptionSubStyle?.customCSS || {},
            demoCSS: config?.descriptionSubStyle?.demoCSS || {},
        },

        formStyle,

        formInputsStyle: {
            ...formStyle,
            customCSS: config?.formInputsStyle?.customCSS || {},
            demoCSS: config?.formInputsStyle?.demoCSS || {},
        },

        formWrapper: {
            customCSS: config?.formWrapper?.customCSS || {},
            demoCSS: config?.formWrapper?.demoCSS || {},
        },

        formHeaderStyle: {
            displayText: config?.formHeaderStyle?.displayText || '',
            customCSS: config?.formHeaderStyle?.customCSS || {},
            demoCSS: config?.formHeaderStyle?.demoCSS || {},
        },

        formBodyStyle: {
            displayText: config?.formBodyStyle?.displayText || '',
            customCSS: config?.formBodyStyle?.customCSS || {},
            demoCSS: config?.formBodyStyle?.demoCSS || {},
        },

        formFooterStyle: {
            displayText: config?.formFooterStyle?.displayText || '',
            customCSS: config?.formFooterStyle?.customCSS || {},
            demoCSS: config?.formFooterStyle?.demoCSS || {},
        },

        buttonSubText: {
            fontSize: 12,
            displayText: config?.buttonSubText?.displayText || '',
            customCSS: config?.buttonSubText?.customCSS || {},
            demoCSS: config?.buttonSubText?.demoCSS || {},
        },

        footerStyle: {
            fontSize: 10,
            textAlign: 'center',
            padding: '0px 10% 0px 10%',
            displayText: config?.footerStyle?.displayText !== undefined ? config?.footerStyle?.displayText : 'Privacy Policy: Your responses will be strictly confidential and used solely for the purpose of enhancing our customer service. No personal information will be shared with third parties.',
            customCSS: config?.footerStyle?.customCSS || {},
            demoCSS: config?.footerStyle?.demoCSS || {},
        },

        termsStyle: {
            textAlign: 'center',
            padding: '0px 10% 32px 10%',
            customCSS: config?.termsStyle?.customCSS || {},
            demoCSS: config?.termsStyle?.demoCSS || {},
        },

        formItemStyle: {
            textAlign: 'left',
            gridColumn: 'span 1',
            customCSS: config?.formItemStyle?.customCSS || {},
            demoCSS: config?.formItemStyle?.demoCSS || {},
        },

        alpineStyle: {
            paddingTop: config?.alpineStyle?.paddingTop || 16,
            ...config.alpineStyle,
            customCSS: config?.alpineStyle?.customCSS || {},
            demoCSS: config?.alpineStyle?.demoCSS || {},
        },

        button: mergeThese(config.button, {
            minWidth: config?.button?.minWidth || 100,
            height: config?.button?.height || 34,
            borderRadius: config?.button?.borderRadius || 6,
            borderWidth: config?.button?.borderWidth || 1,
            borderColor: config?.button?.borderColor || colors.primary.primary,
            backgroundColor: config?.button?.backgroundColor || colors.primary.primary,
            color: config?.button?.color || '#ffffff',
            fontSize: config?.button?.fontSize || 14,
            fontWeight: config?.button?.fontWeight || 400,
            displayText: config?.button?.displayText !== undefined ? config?.button?.displayText : 'Submit',
            customCSS: config?.button?.customCSS || {},
            demoCSS: config?.button?.demoCSS || {},
        }),

        input: mergeThese(config.input, {
            ...getShadow(config?.input),
            label: {
                color: config?.input?.color || defaultText.title.color,
                customCSS: config?.input?.label?.customCSS || {},
                demoCSS: config?.input?.label?.demoCSS || {},
            },
            color: config?.input?.color || defaultText.title.color,
            borderRadius: config?.input?.borderRadius || 6,
            borderWidth: config?.input?.borderWidth || 1,
            borderColor: config?.input?.borderColor || defaultDividerColor,
            backgroundColor: config?.input?.backgroundColor || 'transparent',
            fontWeight: config?.input?.fontWeight || 400,
            fontSize: config?.input?.fontSize || 14,
            customCSS: config?.input?.customCSS || {},
            demoCSS: config?.input?.demoCSS || {},
        }),

    })
}

const getShadow = (input?: any) => {
    const shadow = {
        color: input?.shadow?.color || '#212121',
        alpha: input?.shadow?.alpha || 0.05,
        config: input?.shadow?.config || '0px 4px 13px 0px',
        inset: input?.shadow?.inset || false,
        enabled: input?.shadow?.enabled === undefined ? true : input?.shadow?.enabled,
        raw: input?.shadow?.raw || ''
    }
    if (shadow.enabled && shadow.raw) return {
        shadow,
        boxShadow: shadow.raw,
        MozBoxShadow: shadow.raw,
        WebkitBoxShadow: shadow.raw,
    }
    return (shadow.enabled ? {
        shadow,
        ...allStyles.shadows.lightShadow(colorUtils.setHexAlpha(shadow.color, shadow.alpha), shadow.inset, shadow.config)
    } : { shadow })
}

