import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Image, Modal, Tooltip, Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload';
import React, { CSSProperties, useEffect, useState } from 'react';

import allStyles, { colors } from '../../helpers/allStyles';
import userUtils from '../../helpers/userUtils';
import utils from '../../helpers/utils';
import FA from '../global/FA';
import { handleMediaUpload, MediaFileMetadata, MediaUploaderOptions, validateImages } from './media.helpers';

const MediaLibrary = React.lazy(() => import('./MediaLibrary'));
const { Dragger } = Upload;

export type MediaSelectorProps = {

    open?: boolean
    onClose?: () => void

    config: MediaUploaderOptions;

    onDeleteCB?: () => void
    value?: any
    onSelect?: (image: Partial<MediaFileMetadata> | Partial<MediaFileMetadata>[] | any) => void

    uploadText?: React.ReactNode

    useLibrary?: boolean

    dragAndDrop?: boolean

    isLink?: boolean
    isButton?: boolean
    hideIcon?: boolean

    allowDelete?: boolean
    dontConfirmDelete?: boolean

    bordered?: boolean
    disabled?: boolean

    tooltip?: string | React.ReactNode

    style?: Partial<{
        [key: string]: any
        height: number | string
        width: number | string



        buttonProps?: ButtonProps
        buttonStyle?: CSSProperties
        iconStyle?: CSSProperties
    }>
}

interface ImageType {
    url?: string
    width?: number
    height?: number
}

export default function MediaSelector({ ...props }: MediaSelectorProps) {

    const { value } = props
    const uploadConfig = props.config || {}

    const getDefault = (value: any) => {
        let v = value ? typeof value === 'string' ? { url: value.replace(/[% ]+/g, '_') } : value : []
        if (Array.isArray(value)) v = value.map((x) => x ? typeof x === 'string' ? { url: x.replace(/[% ]+/g, '_') } : x : [])
        if (uploadConfig.multiple && v && !Array.isArray(v)) return [v]
        return v
    }

    const [uploading, setUploading] = useState(false);
    const [state, updateState] = useState<{
        loading: boolean,
        mediaSelector: boolean
        image: ImageType[]
    }>({
        mediaSelector: props.open || false,
        loading: false,
        image: getDefault(value),
    });
    const setState = (x: any) => updateState({ ...state, ...x });

    const imageAsArray = !state.image ? [] : Array.isArray(state.image) ? state.image : [state.image]

    React.useEffect(() => {
        if (props.open !== undefined && props.open) setState({ mediaSelector: props.open })
        console.log('%c--------------------%cMediaSelector%c--------------------', 'color: green', 'color: aqua', 'color: green');
        console.log('props:', props);
        console.log('props.open:', props.open);
        console.log('%c--------------------%cMediaSelector%c--------------------', 'color: green', 'color: aqua', 'color: green');
    }, [props.open])

    React.useEffect(() => {
        if (JSON.stringify(getDefault(value) || {}) !== JSON.stringify(imageAsArray)) setState({ image: getDefault(value) })
    }, [value]);

    const handleSelect = (image: any) => utils.asyncFetch(async () => {
        if (!image) {
            if (props.onSelect && props.value && props.allowDelete) props.onSelect(undefined);
            return setState({ mediaSelector: false, image: [] })
        }
        const { isValid, extra } = await validateImages(!Array.isArray(image) ? [image] : image, uploadConfig as any)
        if (!isValid) {
            setState({ mediaSelector: false, image: [] })
            return
        }

        if (uploadConfig.multiple && image && !Array.isArray(image)) image = [image]
        if (uploadConfig.multiple) {
            if (value && (image || []).find(({ url }: any) => value.includes(url))) return utils.showErr('You have already selected that media')
            image = [...image, ...(imageAsArray || [])]
        }

        if (props.onClose) props.onClose()
        setState({ mediaSelector: false, image })
        if (props.onSelect) props.onSelect(image);
    });

    const handleUpload = (file: any) => {
        if (uploadConfig.multiple && uploadConfig.maxQuantity && (uploadConfig.maxQuantity || 0) <= (imageAsArray || []).length) return utils.showErr(`You can only upload a max of ${uploadConfig.maxQuantity || 0} files at once`)
        utils.asyncFetch(async () => {
            setUploading(true)
            const { response, valid } = await handleMediaUpload(file, {}, uploadConfig)
            if (valid) {
                const newImages = !uploadConfig.multiple ? response[0] : [...imageAsArray || [], ...response]
                setState({ image: newImages })
                if (props.onSelect) props.onSelect(newImages);
            }
            setUploading(false)
        })
        return false
    }

    const linkOrButton = props.isLink || props.isButton
    const height = props.style?.height || (linkOrButton ? 'auto' : 104),
        width = props.style?.width || (linkOrButton ? 'auto' : 104)

    const borders: CSSProperties = props?.bordered ? { borderRadius: 6 } : {}
    const linkStyle: CSSProperties = props.isLink ? { ...utils.noPadding, ...utils.noMargin, } : {}
    const config: UploadProps = {
        name: 'file',
        openFileDialogOnClick: !props.useLibrary,
        multiple: uploadConfig.multiple,
        disabled: props.disabled,
        showUploadList: false,
        style: { width, height, minHeight: height, minWidth: width, maxWidth: width, maxHeight: height, ...borders, ...linkStyle },
        beforeUpload(file, FileList) {
            return handleUpload(file);
        },
        onDrop(e) {
            const files = e.dataTransfer.files || []
            return handleUpload(files)
        },
        onChange(info) {
        },

    };


    const defaultUploadText = uploadConfig.multiple ? 'Upload Files' : 'Upload'
    const iconStyle = { fontSize: '.875rem', color: props.isLink || props.isButton ? '' : 'inherit', ...props.style?.iconStyle }

    const Icon = !props.hideIcon && <>
        {uploading ? <>
            <LoadingOutlined style={{ ...iconStyle }} />
        </> : <>
            <UploadOutlined style={{ ...iconStyle }} />
        </>}
    </>

    const safeURL = imageAsArray[0]?.url

    const hwStyle = () => {
        let w = typeof width === 'string' ? width : width - 8, h = typeof height === 'string' ? height : height - 8
        return { maxWidth: w, maxHeight: h }
    }
    const GetImage = () => <div>
        {!uploadConfig.multiple && (safeURL) ? <>
            <Image src={safeURL} alt="Loading..." style={{ ...hwStyle(), ...borders }} />
        </> : <>
            <div style={{ fontSize: '.875rem' }}>
                {Icon}
                <div style={{ marginTop: 8 }}>{props.uploadText || defaultUploadText}</div>
            </div>
        </>}
    </div>

    const DeleteWrapper = ({ children }: any) => <>
        {props.allowDelete && (imageAsArray && imageAsArray.length > 0) && !uploadConfig.multiple && <div style={{ position: 'absolute', zIndex: 999, width }}>
            <div style={{ background: 'rgba(0,0,0,0.2)', padding: '2px 6px', float: 'right', borderRadius: props.bordered ? '0px 6px 0px 0px' : '', }}>
                <i
                    className="fa-regular fa-trash-can delete-color"
                    style={{ fontSize: '.825rem' }}
                    onClick={() => {
                        handleSelect(undefined)
                        if (props.onDeleteCB) props.onDeleteCB()
                    }}
                />
            </div>
        </div>}
        {children}
    </>

    let Uploader = (
        <DeleteWrapper>
            <div className={`newDefault-${utils.darkMode ? 'dark' : 'light'}`}>
                <Upload {...config}  >
                    <GetImage />
                </Upload>
            </div>
        </DeleteWrapper >
    )

    if (props.isButton || props.isLink) {
        Uploader = <>
            <Upload {...config} >
                {props.isLink ? <>
                    <a style={{ fontSize: '0.75rem', color: props.disabled ? colors.text.lighterer() : '', cursor: props.disabled ? 'no-drop' : '', ...props.style?.buttonStyle }}>
                        {Icon} {props.uploadText || defaultUploadText}
                    </a>
                </> : <>
                    <Button icon={Icon} disabled={props.disabled} style={{ borderRadius: 6, ...props.style?.buttonStyle }} {...props.style?.buttonProps}  >
                        {props.uploadText || defaultUploadText}
                    </Button>
                </>}
            </Upload>
        </>
    }

    if (props?.dragAndDrop) {
        Uploader = <>
            <DeleteWrapper>
                <Dragger {...config}>
                    <GetImage />
                </Dragger>
            </DeleteWrapper>
        </>
    }

    React.useMemo(() => {
        if (userUtils.debugMode() && userUtils.isDebugging('MediaSelector')) {
            console.log('%c--------------------%cMediaSelector%c--------------------', 'color: gray', 'color: aqua', 'color: gray');
            console.log('state:', state);
            console.log('%c--------------------%cMediaSelector%c--------------------', 'color: gray', 'color: aqua', 'color: gray');
        }
    }, [state])

    return (<>
        <Modal
            width="90%"
            centered
            style={{ ...(utils.noPadding), }}
            bodyStyle={{ padding: 8 }}
            open={state.mediaSelector}
            onCancel={() => {
                setState({ mediaSelector: false })
                props.onClose && props.onClose()
            }}
        >
            <MediaLibrary
                type='image'
                {...props}
                onSelectFile={handleSelect}
            />
        </Modal>
        <span
            className={`global uploadMedia${props.bordered ? ' bordered' : ''}`}
            onClick={() => {
                if (props.disabled) return
                let image = imageAsArray && Array.isArray(imageAsArray) ? imageAsArray : imageAsArray ? [imageAsArray] : []
                if (uploadConfig.multiple && uploadConfig.maxQuantity && (uploadConfig.maxQuantity || 0) <= image.length) return utils.showErr(`You can only upload a max of ${uploadConfig.maxQuantity || 0} files at once`)
                if (props.useLibrary && ((!imageAsArray || !image.length) || uploadConfig.multiple)) {
                    setState({ mediaSelector: true })
                }
            }}
            style={{ width, height, overflow: 'hidden', display: 'inline-block', cursor: 'pointer', position: 'relative' }}
        >
            <Tooltip title={props.tooltip || ''}>
                {Uploader}
            </Tooltip>
        </span>
    </>)
}

export interface PictureCardOptions {
    imageURL?: string
    bordered?: boolean
    copyable?: boolean
    height?: number
    width?: number
    disabled?: boolean
    onDelete?: (e: any) => void
    changePriority?: (up: boolean) => void
}

export const PictureCard = (props: PictureCardOptions) => {
    const borders = props?.bordered ? { borderRadius: 6 } : {}
    const height = props?.height || (104), width = props?.width || (104)
    const hwStyle = () => {
        let w = width - 8, h = height - 8
        return { maxWidth: w, maxHeight: h }
    }

    const light_black_alpha = utils.darkMode ? 'rgba(0,0,0,0.1)' : 'rgba(0,0,0,0.15)'

    const onDelete = () => {
        if (props.disabled) return
        if (props.onDelete) props.onDelete(undefined)
    }

    const onPriorityChange = (up: boolean) => {
        if (props.disabled) return
        if (props.changePriority) props.changePriority(up)
    }

    return <>
        <div style={{ position: 'relative', }}>
            {(!!props.onDelete || props.copyable) && <>
                <div style={{ position: 'absolute', width, zIndex: 999 }}>
                    {props.copyable && <>
                        <div style={{
                            background: light_black_alpha,
                            padding: '2px 6px',
                            float: 'left',
                            borderRadius: props.bordered ? '6px 0px 0px 0px' : '0px 0px 6px 0px'
                        }}>
                            <span onClick={() => {
                                utils.notify('Success', 'Image link copied to clipboard!')
                                navigator.clipboard.writeText(props.imageURL || '')
                            }}>

                                <i className="fa-regular fa-copy link-color-icon" style={{ fontSize: '.825rem' }} />
                            </span>
                        </div>
                    </>}
                    {(!!props.onDelete) && <>
                        <div style={{
                            background: light_black_alpha,
                            padding: '2px 6px',
                            float: 'right',
                            borderRadius: props.bordered ? '0px 6px 0px 0px' : '0px 0px 0px 6px'
                        }}>
                            <FA
                                deleteHover
                                name="trash-can delete-color"
                                style={{ fontSize: '.825rem' }}
                                onClick={() => onDelete()}
                            />
                        </div>
                    </>}
                </div>
            </>}
            <div style={{
                opacity: props.disabled ? 0.5 : 1,
                ...allStyles.shadows.lightShadow(),
                ...borders,
                background: utils.darkMode ? 'rgba(255, 255, 255, 0.04)' : 'rgba(200,200,200,0.04)',
                height,
                width,
                border: utils.darkMode ? `1px dashed #434343` : `1px dashed #d9d9d9`,
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center'
            }}>
                <Image src={props.imageURL} alt="Loading..." style={{ ...hwStyle(), ...borders }} />
            </div>

            {/* Priority buttons < and > at bottom */}
            {!!props.changePriority && <>
                <div style={{ position: 'absolute', width, bottom: 0, zIndex: 999 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', color: colors.text.lighterer() }}>
                        <div style={{
                            background: light_black_alpha,
                            padding: '2px 6px',
                            float: 'right',
                            borderRadius: props.bordered ? '0px 0px 0px 6px' : '0px 6px 0px 0px'
                        }}>
                            <FA
                                linkedHover
                                name="chevron-left"
                                onClick={() => onPriorityChange(true)}
                            />
                        </div>
                        <div style={{
                            background: light_black_alpha,
                            padding: '2px 6px',
                            float: 'right',
                            borderRadius: props.bordered ? '0px 0px 6px 0px' : '6px 0px 0px 0px'
                        }}>
                            <FA
                                linkedHover
                                name="chevron-right"
                                onClick={() => onPriorityChange(false)}
                            />
                        </div>
                    </div>
                </div>
            </>}
        </div>
    </>
}