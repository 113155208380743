import { Skeleton, Spin } from 'antd';
import React from 'react';

import utils from '../helpers/utils';
import { Loading } from '@alpine-iq/ui-library';

function Loader(props: {
	fullPage?: boolean;
	noSpin?: boolean;
	text?: React.ReactNode | string;
	skeleton?: any;
	spinner?: any;
	length?: number;
	alt?: boolean;
	ignoreSideBar?: boolean;
}) {
	let { skeleton, spinner, length } = props;
	if (!skeleton) skeleton = { active: true };
	if (!spinner) spinner = { size: 'large', spinning: true };

	const ignoreSideBar = props.ignoreSideBar || utils.sideBarCollapsed || false;
	const sidebarWidth = ignoreSideBar ? 0 : 230;

	if (props.alt) {
		return (
			<>
				<div
					style={{
						top: 0,
						left: sidebarWidth,
						position: 'absolute',
						// width: `calc(100vw - ${sidebarWidth}px)`,
						width: '100%',
						height: '100vh',
						background: 'rgba(0,0,0,0.025)',
						// background: '#fff',
						zIndex: 1009,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						cursor: 'wait',
						overflow: 'hidden',
						scrollbarWidth: 'none',
					}}
				>
					<div
						className="skeleton-loading"
						style={{ height: '100%', minHeight: '100vh', position: 'relative', minWidth: '100%' }}
					/>
					<div
						style={{
							left: 0,
							// width: `calc(100vw - ${sidebarWidth}px)`,
							width: '100%',
							height: '100%',
							minHeight: '100vh',
							position: 'absolute',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{!props.noSpin && (
							<Spin
								{...spinner}
								style={ignoreSideBar ? {} : {}}
								tip={props.text || 'Loading...'}
							/>
						)}
					</div>
				</div>
			</>
		);
	}

	return (
		<div
			css={`
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				// height: ${props.fullPage ? '100vh' : '100%'};
				height: 100vh;
				width: 100%;
			`}
		>
			<Loading {...props} />
		</div>
	);
}

export default Loader;
