import '../../styles/Navbar.css';
import '../../styles/simplebar.css';
import 'simplebar/dist/simplebar.min.css';

import { Badge, Layout, Menu, MenuProps, message, Space, Switch } from 'antd';
import React from 'react';
import { useLocation } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import { asyncWrap } from '../../api';
import { adminUtils, colors, timeUtils, userUtils } from '../../helpers';
import allStyles, { colorUtils } from '../../helpers/allStyles';
import utils from '../../helpers/utils';
import { LoaderState, Login, User } from '../../types';
import { Role } from '../../types/acl';
import GPop from '../global/GPop';
import MenuSwitch from './components/MenuSwitch';

const Clock = React.lazy(() => import('../Clock'));
const CoMarketingTitle = React.lazy(() => import('./components/CoMarketingTitle'));
const ResponsiveAvatar = React.lazy(() => import('./components/ResponsiveAvatar'));
const ManageAlerts = React.lazy(() => import('../../pages/alerts/alertsManager/ManageAlerts'));
const PlannerPage = React.lazy(() => import('../../pages/planner/PlannerPage'));

type MenuItem = Required<MenuProps>['items'][number];
type domainCheck = Record<string, Record<string, domainStatus>>;
interface domainStatus {
	Good: number;
	Bad: number;
	FailRate: number;
}

export interface LeftNavBarState {
	uid: string;
	role: Role;
}

export default function LeftNav(props: { user?: User; collapsed: boolean; setCollapsed: React.Dispatch<React.SetStateAction<boolean>> }): JSX.Element {
	const location = useLocation();

	const [loader, setLoader] = React.useState<LoaderState>({ loading: false, loaded: false });
	const [clock, setClock] = React.useState(userUtils.showClock || false);
	const [healthCount, setHealthCount] = React.useState(0);
	const [statuses, setStatuses] = React.useState<{
		isHalted: boolean;
		cacheLoaded: boolean;
	}>({
		isHalted: false,
		cacheLoaded: false,
	});

	const [globalID, setGlobalID] = React.useState<string | undefined>('');
	const settingsPats = ['settings', 'marketplace', 'fileUploads'].some((v) => location.pathname.includes(v));
	const [showSettings, setShowSettings] = React.useState(utils.local.getLocal('sidebar:showSettings', false) && settingsPats);

	React.useEffect(() => {
		if (loader.loaded) {
			return;
		}
		asyncWrap(async () => {
			if (uid === '1') {
				if (utils.isSuperAdmin()) {
					asyncWrap(async () => {
						const isHalted = (await utils.auth.isHalted()) || false;
						setStatuses((s) => ({ ...s, isHalted }));
					});

					asyncWrap(async () => {
						const cacheLoaded = (await utils.auth.isCacheLoaded()) || false;
						setStatuses((s) => ({ ...s, cacheLoaded }));
					});
				}

				await asyncWrap(async () => {
					const cachedHCKey = 'aiq::admin::domainHealth::hc';
					const cachedHC: number | null = await utils.local.getWithExpiry(cachedHCKey);
					if (cachedHC) {
						setHealthCount(cachedHC);
						return;
					}
					const domainHealth = (await utils.auth.get('domainHealth')) as domainCheck;
					const healthCount =
						Object.values(domainHealth).reduce((acc: number, b) => {
							return acc + Object.values(b).reduce((p: number, d: domainStatus) => p + (d.FailRate >= 10 ? 1 : 0), 0);
						}, 0) || 0;

					await utils.local.setWithExpiry(cachedHCKey, healthCount, timeUtils.units.MINUTE_MS * 5);
					setHealthCount(healthCount);
				});
			} else {
				const globalID = (((await utils.auth.get(`/users/login/current/${utils.uid}`)) || {}) as Login).globalID;
				setGlobalID(globalID);
			}

			setLoader({ loading: false, loaded: true });
		});
	}, []);

	const user = props.user || utils.user,
		{ realID, uid } = utils,
		now = Date.now();
	const username = user?.profile?.name || 'Edit name';

	const isAdminUser = userUtils.isAdmin();
	const currentRole: Role = userUtils.getRoleView();
	const viewingAdmin = user.type === 'admin',
		viewingAgency = user.type === 'agency' || uid === '1';
	const viewingAdvertiser = !viewingAdmin && !viewingAgency;

	const hasStarterMarketing = userUtils.hasPackageTier('marketing', 'starter'),
		hasProMarketing = userUtils.hasPackageTier('marketing', 'pro'),
		hasStarterLoyalty = userUtils.hasPackageTier('loyalty', 'starter'),
		hasProLoyalty = userUtils.hasPackageTier('loyalty', 'pro'),
		hasStarterAnalytics = userUtils.hasPackageTier('analytics', 'starter'),
		hasStarterDataOps = userUtils.hasPackageTier('dataops', 'starter'),
		hasStarterApp = userUtils.hasPackageTier('nativeApps', 'starter'),
		hasBrandPro = userUtils.hasPackageTier('brandAnalytics', 'brand-pro'),
		hasBrandStarter = userUtils.hasPackageTier('brandAnalytics', 'brand-starter');
	// const hasEcommerce = userUtils.hasPackageTier('ecommerce', 'pro')
	const hasEcommerce = user.metadata?.hasEcommerce ?? false;

	// if user has at least this role
	const hasAtLeastRole = {
		owner: userUtils.hasRoleView(Role.OWNER),
		ma: userUtils.hasRoleView(Role.MA),
		marketing: userUtils.hasRoleView(Role.MARKETING),
		analyst: userUtils.hasRoleView(Role.ANALYST),
		operator: userUtils.hasRoleView(Role.OPERATOR),
		manager: userUtils.hasRoleView(Role.MANAGER),
	};

	const isLegacyBrand = userUtils.hasLegacyBilling() && utils.isBrand();
	const isOwnerPlus = isAdminUser || (!utils.isSubUser() && currentRole === Role.OWNER);
	const isNotAgency = !viewingAgency;

	const permissions = {
		canSeeAudiences: (isAdminUser || hasAtLeastRole.analyst) && (hasStarterLoyalty || hasStarterMarketing),
		canSeePersonas: isAdminUser || hasAtLeastRole.manager,
		canSeeMarketing: isAdminUser || (hasAtLeastRole.marketing && hasStarterMarketing),
		canSeeDiscounts: isAdminUser || (hasAtLeastRole.marketing && hasStarterLoyalty),
		canSeeAnalytics: (isAdminUser || hasAtLeastRole.analyst) && hasStarterAnalytics,
		canSeeRecipes: (isAdminUser || hasAtLeastRole.analyst) && hasStarterMarketing,
		hasAlertPerms: (isAdminUser || userUtils.isRoleView(Role.OWNER)) && hasStarterDataOps,
		canSeeBrandAnalytics: isAdminUser || (hasAtLeastRole.analyst && ((hasBrandStarter && !userUtils.hasLegacyBilling()) || isLegacyBrand)),
		canSeeReceipts: isAdminUser || (['1225', '1275', '1034', '1653', '1925', '2336'].includes(uid) && hasAtLeastRole.analyst),
		// Surveys are available in RETAIL accounts who have Marketing Pro +
		// Surveys are available for BRAND accounts who have Loyalty Pro + and / or Marketing Pro +
		canSeesSurveys: isAdminUser || (hasAtLeastRole.analyst && (hasProMarketing || (hasProLoyalty && utils.isBrand()))),
		canViewCrew: isAdminUser && userUtils.isExperimental(),
		canViewEcommerce: isAdminUser || hasAtLeastRole.owner || (hasEcommerce && hasAtLeastRole.marketing),
		canSeeMissionControl: isAdminUser || hasAtLeastRole.analyst,
	};

	const email = utils.auth.getEmail();

	// Hide the personas page for all non-whitelisted users (for uid: 2654) - requested on Jun/14/2023
	if (uid === '2654' && !['mbutera@pax.com', 'sally@pax.com'].includes(email)) permissions.canSeePersonas = isAdminUser;
	// Hide persona tab for every user except admin in UID 2883? As part of the agreement Stiiizy legal team said no DTC marketing or viewing of customer PII. They want pro-marketing for co-marketing only,
	if (uid === '2883') permissions.canSeePersonas = isAdminUser;

	const agencyId = user.agencyID || (viewingAgency ? uid : realID);
	const simpleHome = agencyId !== '1' && agencyId !== '2' && (isAdminUser || utils.auth.getUID() === agencyId);

	// Freemium accounts which are not '2638' or admins users can not see the API & Tracking page
	const canNotSeeAPIAndTracking = utils.isFreemium() && utils.uid !== '2638' && !isAdminUser;

	const whitelistSpotlight = ['1683', '1444', '1055', '1042', '1794', '1091'];
	const isOnPlannerPage = location.pathname?.includes('planner/');

	const haltIcon = statuses.isHalted ? { type: 'fa-solid fa-circle-stop', color: colors.primary.error700 } : { type: 'fa-solid fa-rotate fa-spin', color: colors.primary.blue };
	const cacheIcon = statuses.cacheLoaded
		? { type: 'fa-solid fa-check', color: colorUtils.setHexAlpha(colors.primary.primary, 1) || '' }
		: { type: 'fa-solid fa-xmark', color: colors.primary.error700 };

	const onChangeHalt = (isHalted: boolean) =>
		asyncWrap(async () => {
			await utils.auth.setHalted(isHalted);
			message.info(`Successfully set the halted status to ${isHalted}`);
		});

	const onChangeCache = (cacheLoaded: boolean) =>
		asyncWrap(async () => {
			await utils.auth.setCacheLoaded(cacheLoaded);
			message.info(`Successfully set the cache status to ${cacheLoaded}`);
		});

	const isTesting = utils.isLocal || utils.isStaging;

	const specialPoliticalAgencies = utils.specialPoliticalAgencies;
	const isOfSpecialPoliticalAgency = specialPoliticalAgencies.includes(uid) || specialPoliticalAgencies.includes(agencyId);

	const defaultMenus: MenuProps['items'] = React.useMemo(
		timeUtils.timeFunction(() => {
			let items: MenuProps['items'] = [];
			if (showSettings) {
				items = [
					getItem(
						<Space size={8}>
							<i
								className="fa-regular fa-cloud-plus"
								style={{ color: 'inherit' }}
							/>{' '}
							Data Integrations
						</Space>,
						`/marketplace/${uid}`,
						{ isShown: isOwnerPlus && hasStarterDataOps, disabled: utils.isFreemium() },
					),
					getItem(
						<Space size={8}>
							<i
								className="fa-regular fa-file-import"
								style={{ color: 'inherit' }}
							/>
							Import Personas
						</Space>,
						`/fileUploads/${uid}`,
						{ isShown: isOwnerPlus && hasStarterDataOps },
					),

					getItem('COMPANY ACCOUNT', '', { isShown: (isOwnerPlus || hasAtLeastRole.marketing || currentRole === Role.FINANCIAL) && isNotAgency }, [
						getItem('Account Settings', `/settings/${uid}/accountsettings`, { isShown: isOwnerPlus }),
						getItem('Team Members', `/settings/${uid}/team`, { isShown: isOwnerPlus || viewingAgency }),
						getItem('Retail Stores', `/settings/${uid}/retailstores`, { isShown: (isOwnerPlus || hasAtLeastRole.marketing) && (utils.isRetail() || utils.isSandbox()) }),
						getItem('Loyalty Settings', `/settings/${uid}/loyalty/main`, {
							isShown: (isOwnerPlus || currentRole === Role.FINANCIAL) && hasStarterLoyalty && !isOfSpecialPoliticalAgency,
						}),
						getItem('Texting Settings', `/settings/${uid}/texting`, { isShown: (isOwnerPlus || hasAtLeastRole.marketing) && hasStarterMarketing }),
						getItem('Email / Domain', `/settings/${uid}/email`, { isShown: (isOwnerPlus || hasAtLeastRole.marketing) && !isOfSpecialPoliticalAgency }),
					]),
					getItem('DESIGN', '', { isShown: (isOwnerPlus || hasAtLeastRole.marketing) && isNotAgency }, [
						getItem('Signup Forms', `/settings/${uid}/signup`, { isShown: isOwnerPlus && (hasStarterLoyalty || hasStarterMarketing) }),
						getItem('App Settings', `/settings/${uid}/appsettings`, {
							isShown: (isOwnerPlus || hasAtLeastRole.marketing) && (hasStarterLoyalty || hasStarterApp) && !isOfSpecialPoliticalAgency,
						}),
						// getItem(<>
						// 	Branding & Design
						// 	<GTooltip tooltip='These settings are now considered legacy and their usage is both deprecated and limited. It is strongly advised you use the new "App settings" page'>
						// 		<Tag style={{ marginLeft: 10, zIndex: 1, color: 'white', background: colors.primary.error, borderColor: colors.primary.error }}><i className="fa-duotone fa-circle-exclamation" style={{ fontSize: '.875rem' }} /></Tag>
						// 	</GTooltip>
						// </>, `/settings/${uid}/storeDesign`, { isShown: (isOwnerPlus || hasAtLeast.marketing) && !state.hideLegacyWallet, key: 'branding&design' }),
						// getItem('Survey / Studies', `/settings/${uid}/survey`, { isShown: undefined }),
						getItem('Reviews', `/settings/${uid}/reviews`, { isShown: (isOwnerPlus || hasAtLeastRole.marketing) && hasStarterMarketing && !isOfSpecialPoliticalAgency }),
					]),
					getItem('BRANDS', '', { isShown: isOwnerPlus && isNotAgency && utils.isBrand() && !isOfSpecialPoliticalAgency }, [
						getItem('Brand Stores', `/settings/${uid}/brandstores`, {}),
						getItem('My Products', `/settings/${uid}/products`, {}),
						getItem('Collectibles', `/settings/${uid}/brand`, { isShown: isAdminUser || ['2271', '2521', '2689'].includes(utils.uid) }),
					]),
					getItem('ADVANCED', '', { isShown: isOwnerPlus && isNotAgency && !isOfSpecialPoliticalAgency }, [
						getItem('API & Tracking', `/settings/${uid}/apitracking`, { isShown: isOwnerPlus, disabled: canNotSeeAPIAndTracking }),
						getItem('User Data & Privacy', `/settings/${uid}/userdata`, { isShown: isOwnerPlus }),
					]),

					getItem('OTHER', '', { isShown: isOwnerPlus && isNotAgency }, [
						getItem('Billing & History', `/settings/${uid}/billing`, { isShown: isOwnerPlus }),
						getItem('Support History', `/settings/${uid}/tickets`, { isShown: isOwnerPlus }),
						getItem('Activity Logs', `/settings/${uid}/activity`, { isShown: isOwnerPlus }),
					]),

					getItem('Account Settings ', `/settings/${uid}/accountsettings`, { isShown: viewingAgency }),
					getItem('Team Members', `/settings/${uid}/team`, { isShown: viewingAgency, key: 'teammemberss' }),
					getItem('Login & Password', `/settings/${uid}/profile`, { isShown: undefined }),
					getItem('Admin', `/settings/${uid}/admin`, {
						isShown: isAdminUser && uid !== '1',
						icon: (
							<i
								className="fa-regular fa-lock-keyhole"
								style={{ opacity: 0.8, color: colors.primary.error }}
							/>
						),
					}),
					getItem(
						<>
							<Switch
								style={{ ...allStyles.shadows.lightShadow(false, true), background: !utils.darkMode ? '#28282830' : '' }}
								onChange={() => utils.toggleTheme()}
								checkedChildren="Dark theme"
								unCheckedChildren="Light theme"
								checked={utils.darkMode}
							/>
						</>,
						'',
						{
							isShown: utils.darkMode,
							icon: utils.darkMode ? (
								<i
									className="fa-solid fa-lightbulb"
									style={{ color: colors.primary.warning500, opacity: 0.7, minWidth: 20 }}
								/>
							) : (
								<i
									className="fa-solid fa-lightbulb-on"
									style={{ color: colors.text.lighter(), opacity: 0.8, minWidth: 20 }}
								/>
							),
							key: 'theme',
						},
					),
					getItem(
						<>
							<Switch
								style={{ ...allStyles.shadows.lightShadow(false, true), background: !utils.darkMode ? '' : '' }}
								onChange={() => {
									userUtils.setClock();
									setClock(!clock);
								}}
								checkedChildren="Clock shown"
								unCheckedChildren="Clock hidden"
								checked={clock}
							/>
						</>,
						'',
						{
							isShown: utils.isLocal,
							icon: userUtils.showClock ? (
								<i
									className="fa-regular fa-clock"
									style={{ color: colors.primary.secondary900, opacity: 0.9, minWidth: 20 }}
								/>
							) : (
								<i
									className="fa-regular fa-clock"
									style={{ color: colors.text.light(), opacity: 0.8, minWidth: 20 }}
								/>
							),
							key: 'clock',
						},
					),
					getItem(
						<>
							<Switch
								style={{ ...allStyles.shadows.lightShadow(false, true), background: !utils.darkMode ? '' : '' }}
								onChange={() => userUtils.setDebugMode()}
								checkedChildren="Debug Enabled"
								unCheckedChildren="Debug Disabled"
								checked={userUtils.debugMode()}
							/>
						</>,
						'',
						{
							isShown: isAdminUser,
							icon: userUtils.debugMode() ? (
								<i
									className="fa-solid fa-bug"
									style={{ color: colors.primary.primary, opacity: 0.8, minWidth: 20 }}
								/>
							) : (
								<i
									className="fa-solid fa-bug"
									style={{ color: colors.text.lighter(), opacity: 0.8, minWidth: 20 }}
								/>
							),
							key: 'debug',
						},
					),
				];
			}
			if (!showSettings) {
				items = [
					getItem(
						<Space>
							<div style={{ marginBottom: 1 }}>
								<i
									className="fa-solid fa-crown"
									style={{ color: 'inherit', fontSize: '.75rem' }}
								/>
							</div>{' '}
							Enterprise Home
						</Space>,
						'/admin',
						{ isShown: isAdminUser, key: 'enterprisehome' },
					),
					getItem(
						<Space>
							<div style={{ marginBottom: 2 }}>
								<i
									className="fa-duotone fa-house"
									style={{ color: 'inherit', fontSize: '.75rem' }}
								/>
							</div>{' '}
							Company Home
						</Space>,
						`/agency/${agencyId}`,
						{ isShown: simpleHome, key: 'companyhome' },
					),
					// admin only
					getItem('ACCOUNTS', '', { isShown: isAdminUser && viewingAdmin }, [
						getItem('Account Health', '/accountHealth/accounts', {}),
						getItem('Account Renewals', '/admin/accounts/renewals', { isShown: isAdminUser }),
						getItem('Customer Search', '/search', {}),
						getItem('AIQ User Search', '/loginLookup', {}),
					]),
					// admin only
					getItem('ADMIN', '', { isShown: isAdminUser && viewingAdmin }, [
						getItem('Alerts Manager', '/userAlerts/1', {}),
						getItem(
							<Space size={4}>
								Domain Filtration
								<Badge
									size="small"
									count={healthCount}
									offset={[3, -1]}
								/>
							</Space>,
							'/domainHealth',
							{ key: 'domainHealth' },
						),
						getItem('Receipt Lookup', '/receiptLookup', {}),
						getItem('Greenbits Token', '/greenbits', {}),
						getItem('Global Styles', '/globalStyles', { isShown: isTesting || ['brynner.doyle@alpineiq.com', 'nick@alpineiq.com'].includes(email) }),
						getItem('Media Library', `/mediaLibrary/${uid}`, { isShown: !!adminUtils.canSeeAll() }),
					]),

					getItem(
						<Space>
							<i
								className="fa-regular fa-solar-panel"
								style={{ color: 'inherit', fontSize: '.75rem' }}
							/>{' '}
							Mission Control
						</Space>,
						`/missionControl/${uid}`,
						{ isShown: viewingAdvertiser && permissions.canSeeMissionControl },
					),
					getItem(<ManageAlerts />, '', { isShown: permissions.hasAlertPerms && viewingAdvertiser && !isOfSpecialPoliticalAgency }),

					getItem('CUSTOMERS', '', { isShown: (permissions.canSeePersonas || permissions.canSeeAudiences) && viewingAdvertiser }, [
						// personas page
						getItem('Personas', `/personas/manage/${uid}`, { isShown: permissions.canSeePersonas }),
						// audiences page
						getItem('Audiences', `/audiences/manage/${uid}/${utils.isMessageDigitial() ? 'AIQACT' : 'AIQ'}`, {
							isShown: permissions.canSeeAudiences,
							related: [`/audiences/builder/${uid}`],
						}),
						getItem('Receipt Lookup', `/receiptLookup/${uid}`, { isShown: permissions.canSeeReceipts && !isOfSpecialPoliticalAgency }),
						getItem('Surveys & Reviews', `/surveysreviews/${uid}/surveys`, { isShown: permissions.canSeesSurveys && !isOfSpecialPoliticalAgency }),
					]),

					getItem('MARKETING', '', { isShown: viewingAdvertiser && (permissions.canSeeMarketing || permissions.canSeeDiscounts) }, [
						// planner page
						getItem(isOnPlannerPage ? 'Planner' : <PlannerPage isChild />, '', { isShown: permissions.canSeeMarketing && !isOfSpecialPoliticalAgency }),
						// campaigns page
						getItem('Campaigns', `/campaigns/manage/${uid}/AIQ`, { related: [`/campaign/builder/${uid}`], isShown: permissions.canSeeMarketing }),
						getItem('Chat Inbox', `/chat/${uid}`, { related: [`/chat/${uid}`], isShown: permissions.canSeeMarketing && isOfSpecialPoliticalAgency }),
						// co marketing page
						getItem(
							<CoMarketingTitle isShown={!!(permissions.canSeeRecipes && viewingAdvertiser)} />,
							utils.isBrand() ? `/templates/${uid}/manage` : `/templates/${uid}/co-marketing`,
							{
								isShown: permissions.canSeeRecipes && permissions.canSeeMarketing && !isOfSpecialPoliticalAgency,
								related: [`/templates/${uid}/manage`, `/templates/${uid}/builder`],
							},
						),
						// campaign reports page
						getItem('Campaign Reports', `/campaigns/reports/${uid}`, { isShown: permissions.canSeeMarketing }),
						// discounts page
						getItem('Discounts', `/discounts/manage/${uid}/AIQ`, {
							related: [`/discount/builder/${uid}`],
							isShown: (permissions.canSeeDiscounts || permissions.canSeeMarketing) && !isOfSpecialPoliticalAgency,
						}),
						// templates page
						getItem('Templates', `/templates/${uid}/templates`, {
							isShown: permissions.canSeeRecipes && permissions.canSeeMarketing && !isOfSpecialPoliticalAgency,
							related: [`/templates/${uid}/templates`],
						}),
						// media liability page
						getItem('Media Library', `/mediaLibrary/${uid}`, { isShown: permissions.canSeeMarketing }),
					]),

					getItem(
						'DATA ANALYTICS',
						'',
						{
							isShown: viewingAdvertiser && (permissions.canSeeAnalytics || permissions.canSeeBrandAnalytics) && !isOfSpecialPoliticalAgency,
							disabled: !permissions.canSeeAnalytics && !permissions.canSeeBrandAnalytics,
						},
						[
							getItem('My Analytics ', `/analysis/${uid}`, { isShown: userUtils.hasPackageTier('analytics', 'starter') }),
							getItem('Brand Analytics ', `/brandAnalysis/${uid}`, { isShown: permissions.canSeeBrandAnalytics }),
							getItem(<>Market Spotlight </>, `/marketSpotlight/${uid}`, {
								isShown: (whitelistSpotlight.includes(uid) || permissions.canSeeBrandAnalytics) && user.profile?.industry === 'cannabis',
								key: 'marketspotlight',
							}),
							getItem('Recurring Reports', `/scheduledReports/${uid}/${utils.isRetail() ? 'retail' : 'brand'}`, {
								isShown: userUtils.hasPackageTier('analytics', 'pro') || userUtils.hasPackageTier('brandAnalytics', 'brand-starter'),
							}),
						],
					),

					getItem('ECOMMERCE', '', { isShown: viewingAdvertiser && permissions.canViewEcommerce && !isOfSpecialPoliticalAgency }, [
						getItem('View my site', !hasEcommerce ? `/ecommerce/${uid}` : `/ecommerce/${uid}/menu`, { key: 'ecom-menu' }),
						getItem('Ecommerce settings', !hasEcommerce ? `/ecommerce/${uid}` : '', { key: 'ecom-settings' }),
					]),

					getItem('CREW', '', { isShown: viewingAdvertiser && permissions.canViewCrew && !isOfSpecialPoliticalAgency }, [
						getItem('Leaderboard', `/aiqcrew/leaderboard/${uid}`, {}),
						getItem('Members', `/aiqcrew/members/${uid}`, {}),
						getItem('Goals', `/aiqcrew/goals/${uid}`, {}),
					]),

					getItem(
						<Space>
							<i
								className="fa-regular fa-arrow-right-from-bracket"
								style={{ color: 'inherit', fontSize: '.75rem' }}
							/>{' '}
							Logout
						</Space>,
						'/logout',
						{ key: 'logout' },
					),
					getItem(
						<>
							<GPop
								popConfirm
								type="danger-dark"
								tooltip={{ tooltip: `Halted: ${statuses.isHalted}` }}
								content={
									<div style={{ opacity: 1 }}>
										<i
											className={haltIcon.type}
											style={{ color: haltIcon.color }}
										/>{' '}
										Are you sure?
									</div>
								}
								onConfirm={() => {
									onChangeHalt(!statuses.isHalted);
								}}
								config={{ confirmType: 'danger-dark' }}
							>
								<Switch
									style={{ ...allStyles.shadows.lightShadow(false, true), background: !statuses.isHalted ? '#282828' : '' }}
									checkedChildren="Halted"
									unCheckedChildren="Running"
									checked={statuses.isHalted}
								/>
							</GPop>
						</>,
						'',
						{
							isShown: utils.isSuperAdmin() && viewingAdmin,
							icon: (
								<i
									className={haltIcon.type}
									style={{ color: haltIcon.color, opacity: 0.8 }}
								/>
							),
							key: 'halt',
						},
					),
					getItem(
						<>
							<GPop
								popConfirm
								type="danger-dark"
								tooltip={{ tooltip: `Cache loaded: ${statuses.cacheLoaded}` }}
								content={
									<div style={{ opacity: 1 }}>
										<i
											className={cacheIcon.type}
											style={{ color: cacheIcon.color }}
										/>{' '}
										Are you sure?
										<br />
									</div>
								}
								onConfirm={() => {
									onChangeCache(!statuses.cacheLoaded);
								}}
								config={{ confirmType: 'danger-dark' }}
							>
								<Switch
									style={{ ...allStyles.shadows.lightShadow(false, true), background: !statuses.cacheLoaded ? '#282828' : '' }}
									checkedChildren="Loaded"
									unCheckedChildren="Unloaded"
									checked={statuses.cacheLoaded}
								/>
							</GPop>
						</>,
						'',
						{
							isShown: utils.isSuperAdmin() && viewingAdmin,
							icon: (
								<i
									className={cacheIcon.type}
									style={{ color: cacheIcon.color, opacity: 0.8 }}
								/>
							),
							key: 'cacheLoaded',
						},
					),
				];
			}

			return items.filter((x) => !!x);
		}),
		[clock, showSettings, loader.loaded, statuses.isHalted, statuses.cacheLoaded],
	);

	const openSubMenus = props.collapsed ? [] : defaultMenus.map((m) => m?.key);
	const selectedItems = React.useMemo(() => {
		return getSelectedItems(defaultMenus, location.pathname);
	}, [defaultMenus, location.pathname]);

	const modifySider = showSettings && !utils.darkMode;
	const currentYear = new Date().getFullYear();

	const calc = 'calc(100vh - 180px)';

	React.useMemo(() => {
		if (userUtils.debugMode() && userUtils.isDebugging('navbar')) {
			// ! Remove
			console.log('--------------------LeftNavbar');
			console.log('NavbarInfo', {
				role: currentRole,
				viewingAdmin,
				viewingAgency,
				viewingAdvertiser,
				hasStarterMarketing,
				hasStarterLoyalty,
				hasStarterAnalytics,
				hasStarterDataOps,
				hasStarterApp,
				hasBrandPro,
				hasBrandStarter,
				permissions,
			});
			console.log('--------------------');
			// ! Remove
		}
	}, [
		currentRole,
		viewingAdmin,
		viewingAgency,
		viewingAdvertiser,
		hasStarterMarketing,
		hasStarterLoyalty,
		hasStarterAnalytics,
		hasStarterDataOps,
		hasStarterApp,
		hasBrandPro,
		hasBrandStarter,
		permissions,
	]);

	return (
		<>
			{clock && <Clock />}

			<div
				style={{
					height: '100vh',
					width: props.collapsed ? 0 : 230,
					minWidth: props.collapsed ? 0 : 230,
					maxWidth: props.collapsed ? 0 : 230,
				}}
			/>

			<Layout.Sider
				style={{
					position: 'fixed',
					zIndex: 999,
					userSelect: 'none',
					transition: 'background 0.3s ease, background-color 0.3s ease, color 0.3s ease',
					background: modifySider ? '#ffffff' : '#101010',
					height: '100vh',
					boxShadow: '0 8px 10px 0 rgba(0, 0, 0, 0.175)',
				}}
				onCollapse={(collapsed) => {
					props.setCollapsed(collapsed);
					utils.sideBarCollapsed = collapsed;
				}}
				collapsed={props.collapsed}
				width="230"
				breakpoint="md"
				collapsedWidth="0"
			>
				<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100vh' }}>
					<div>
						<div style={{ height: 180, background: '#111' }}>
							<div style={{ textAlign: 'center', background: 'transparent', paddingBottom: 5, height: 136 }}>
								<div style={{ padding: 8 }}>
									<img
										alt="Alpine IQ"
										height="16"
										src="https://alpineiq.com/dashResources/aiq-logo-nav.png"
										style={{ maxHeight: 16 }}
									/>
								</div>
								<div style={{ padding: uid === '1' ? 8 : 5 }}>
									<ResponsiveAvatar
										uid={utils.realID}
										profilePhoto={`${utils.realDomain}/avatar/${uid}?cacheBuster=${now}`}
									/>
								</div>
								<div style={{ padding: 8, color: '#fff', fontWeight: '', fontSize: 12 }}>{<b>{username}</b>}</div>
							</div>
							<div style={{ textAlign: 'center', padding: '5%', background: 'transparent', paddingBottom: 10, paddingTop: 0 }}>
								<div
									style={{
										borderRadius: 30,
										background: '#282828',
										display: 'flex',
										justifyContent: 'space-between',
										padding: 5,
										...allStyles.shadows.darkShadowHover(false, true),
									}}
								>
									<MenuSwitch
										text="Dashboard"
										{...{ showSettings, setShowSettings }}
									/>
									<MenuSwitch
										text="Settings"
										isSettings
										{...{ showSettings, setShowSettings }}
									/>
								</div>
							</div>
						</div>

						<div
							className={`nav-${modifySider ? 'settings' : 'default'} nav-wrapper`}
							style={{
								height: calc,
								overflowX: 'hidden',
								background: showSettings ? '' : 'linear-gradient(0deg, hsla(0, 0%, 12%, 1) 0%, hsla(0, 0%, 7%, 1) 100%)',
							}}
						>
							<SimpleBar style={{ height: calc, overflowX: 'hidden' }}>
								<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: calc }}>
									<Menu
										mode="inline"
										style={{
											borderRight: 0,
											fontWeight: 300,
											fontSize: '13px',
											lineHeight: 10,
										}}
										key={'admin'}
										selectedKeys={selectedItems}
										openKeys={openSubMenus as any}
										items={defaultMenus}
										onClick={(e) => {
											// On click of ecommerce tabs
											if (e.key.includes('ecom-') && hasEcommerce) {
												// If key is ecom-settings pen in blank page
												if (e.key === 'ecom-settings') {
													window.open(`https://app${isTesting ? '-dev' : ''}.dispenseapp.com/aiq-connect?aiqUid=${utils.uid}&globalId=${globalID}`, '_blank');
												}
											}
										}}
										inlineIndent={20}
									/>
									<div
										style={{
											margin: '16px 0px 16px 0px',
											textAlign: 'center',
											color: modifySider ? 'rgba(0,0,0,0.4)' : 'rgba(255,255,255,0.4)',
											padding: '5%',
											// boxShadow: `0px -8px 4px -8px ${modifySider ? '#00000020' : '#ffffff20'}`
											borderTop: modifySider ? '1px solid #29292920' : '1px solid #29292950',
										}}
									>
										<div style={{ display: 'flex', justifyContent: 'space-around' }}>
											<a
												className={`nav-link-${modifySider ? 'b' : 'a'}`}
												href="https://support.alpineiq.com/terms-and-conditions"
											>
												Terms
											</a>
											<a
												className={`nav-link-${modifySider ? 'b' : 'a'}`}
												href="https://alpineiq.com/privacy-policy/"
											>
												Privacy
											</a>
											<a
												className={`nav-link-${modifySider ? 'b' : 'a'}`}
												href="https://support.alpineiq.com/acceptable-use-policy"
											>
												AUP
											</a>
											<a
												className={`nav-link-${modifySider ? 'b' : 'a'}`}
												href="https://lab.alpineiq.com/trackingPreferences/1034"
											>
												Tracking
											</a>
										</div>
										<span style={{ fontSize: '.55rem', color: modifySider ? '#939399' : '#7B7B80', marginBottom: 16 }}>© {currentYear} Alpine IQ Inc.</span>
									</div>
								</div>
							</SimpleBar>
						</div>
					</div>
				</div>
			</Layout.Sider>
		</>
	);
}

const getSelectedItems = (menus: MenuProps['items'], url: string) => {
	const allMenus: any[] = [];
	const addMenus = (m: any) =>
		m?.forEach((menu: any) => {
			if (menu !== undefined) {
				if (menu.url) allMenus.push(menu);
				if (menu.children) addMenus(menu.children);
			}
		});
	addMenus(menus);
	return allMenus?.find((menu: any) => {
		if (menu === undefined) return false;
		if (menu.url !== undefined && utils.strify(url).startsWith(utils.strify(menu.url))) return true;
		return (menu.related || [])?.find((str: any) => utils.strify(url).startsWith(utils.strify(str))) !== undefined;
	})?.key;
};

interface ItemConfig {
	icon?: React.ReactNode;
	disabled?: boolean;
	type?: 'group' | 'divider';
	style?: React.CSSProperties;
	isShown?: boolean | string;
	related?: string[];
	key?: React.Key;
}

function getItem(label: React.ReactNode, url: string, config: ItemConfig, children?: MenuItem[]): MenuItem {
	const key = config.key || typeof label === 'string' ? utils.strify(`${label}`) : utils.getUUID();
	if (config.disabled)
		label = (
			<span
				key={key}
				style={{ all: 'inherit', opacity: 0.5 }}
			>
				{label}
			</span>
		);
	else if (url)
		label = (
			<a
				key={key}
				href={url}
				style={{ all: 'inherit' }}
			>
				{label}
			</a>
		);
	if (config.isShown === false) return undefined as any;
	// biome-ignore lint/performance/noDelete: isShown is not a key on the MenuItem type
	delete config.isShown;
	return { url, key, children, label, ...config } as MenuItem;
}
