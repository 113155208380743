import { utils } from "../../../helpers";
import { Discount, ID } from "../../../types";
import bwipjs from 'bwip-js';
import { DiscountCouponType, DiscountCouponTypesMapping, DiscountCouponUniqueCodeType, DiscountCouponUniqueCodeTypesMapping, DiscountRedemptionType, DiscountRedemptionTypesMapping, DiscountType, DiscountTypesMapping, IntegrationRedemptionMethod, IntegrationRedemptionMethodsMapping } from "./disc.builder.types";

export function cleanCopyDiscount(discount: Discount): Discount {
    const copy: Discount = utils.clone(discount)
    // copy.clonedFrom = `${copy.id || ''}`
    if (copy.internalName) copy.internalName += ' (Copy)';
    copy.clonedFrom = copy.id;
    copy.recipe = {};
    copy.isLocked = false
    copy.id = undefined;
    return copy
}

export function cleanInputDiscount(discount: Discount): Discount {
    if (discount.percentageValue === 0) discount.percentageValue = undefined
    if (discount.dollarValue === 0) discount.dollarValue = undefined
    if (discount.ecomCoupon === '') discount.ecomCoupon = undefined
    if (discount.posDiscountID === '') discount.posDiscountID = undefined
    if (discount.barcodeType === '') discount.barcodeType = undefined
    if (discount.barcodeValue === '') discount.barcodeValue = undefined
    if (discount.logo === '') discount.logo = undefined

    const hasStarted = discount.startTime && discount.startTime > 0
    const hasRedeemptions = discount.redemptionCount && discount.redemptionCount > 0
    const hasOpens = discount.linkOpenCount && discount.linkOpenCount > 0

    const isActive = hasStarted || hasRedeemptions || hasOpens
    // if there is no redemptionType && this discount is active, set it to default
    if (!discount.redemptionType && isActive) {
        discount.redemptionType = 'default'
    }
    if (!!discount.redemptionType) discount.hasCompletedRedemptionPage = true
    discount = setDiscountTypeInformation(discount)
    return discount
}

// Discount used in preview mode of the builder
export const getDummyDiscount = (uid: string, userName: string, discount: Partial<Discount>, style: any): Discount => ({
    userName,
    avatar: '/cover/' + uid,
    expiration: 1606278142,
    startTime: 0,
    name: 'Awesome product',
    pageBody: 'A special message for the customer!',
    pageTitle: '10% off awesome product, limited time!',
    percentageValue: 10,
    pointsDeduction: 10,
    relativeExpiration: 3,
    reusable: true,
    storeIDs: ['-1'],
    tierDiscount: false,
    // lastRedemptionTS: Date.now() / 1000,
    style,
    ...utils.clone(discount)
});

// Discount used in preview mode of the builder
export const getDefaultDiscount = (uid: string) => {
    const discount: Discount = ({
        avatar: `/cover/${uid}`,
        storeIDs: ['-1'],
        audienceIDs: ['-1'],
    })
    return discount
};

export const updateBarcode = (type: string, value: string) => {
    if (!type || !value) return '';
    try {
        const canvas = document.createElement('canvas');
        bwipjs.toCanvas(canvas, {
            bcid: type, // Barcode type
            text: value, // Text to encode
            scale: 5, // 3x scaling factor
            height: 8, // Bar height, in millimeters
            includetext: true, // Show human-readable text
            textxalign: 'center' // Always good to set this
        });
        return canvas.toDataURL('image/png');
    } catch (err) {
        utils.showErr('invalid barcode value');
        return '';
    }
};

export function canSaveDiscount(discount: Discount): boolean {
    const hasName = discount.internalName && discount.internalName.length > 3
    const hasStartDate = discount.startTime && discount.startTime > 0
    const hasPageTitle = discount.pageTitle && discount.pageTitle.length > 3
    return (!!hasName && !!hasStartDate && !!hasPageTitle)
}

export function showSidebarInDiscounts() {
    return utils.local.getLocal('showSidebarInDiscounts') || false
}

export function setShowSidebarInDiscounts(show: boolean) {
    if (show) utils.local.setLocal('showSidebarInDiscounts', true)
    else utils.local.deleteLocal('showSidebarInDiscounts')
}

export type RedeemButtonProps = ({
    key: DiscountRedemptionType | IntegrationRedemptionMethod | DiscountCouponType | DiscountCouponUniqueCodeType
    title: string
    description: string
    check: (discount: Discount) => boolean
    enable: (discount: Discount, values?: string[]) => Discount
    visisble: () => boolean
})

export function getDiscountType(discount: Discount): DiscountType {
    const channelTypes = (Object.keys(DiscountTypesMapping) as DiscountType[]).map(key => {
        const typeInfo = DiscountTypesMapping[key];
        const hasType = typeInfo.check(discount)
        return { key, hasType }
    })

    return channelTypes.find(type => type.hasType)?.key || 'normal'
}

export const getDiscountLogoImage = (discount: Discount, storeID?: ID | undefined, noDefault?: boolean) => {
    if (storeID && storeID !== '-1') {
        const storeConfig = discount.config?.storeConfigs?.[storeID]
        if (storeConfig?.logo?.imageSource || noDefault) {
            return storeConfig?.logo?.imageSource
        }
    }
    return discount.config?.logo?.imageSource || discount.logo
}

export const setDiscountLogoImage = (discount: Discount, imageSource: string | undefined, storeID?: ID | undefined) => {
    if (storeID && storeID !== '-1') {
        discount.config = {
            ...discount.config,
            storeConfigs: {
                ...discount.config?.storeConfigs,
                [storeID]: {
                    ...discount.config?.storeConfigs?.[storeID],
                    logo: {
                        ...discount.config?.storeConfigs?.[storeID]?.logo, imageSource
                    }
                }
            }
        }
    } else discount.logo = imageSource
    return discount
}

export const getDiscountCoverImage = (discount: Discount, storeID?: ID | undefined, noDefault?: boolean) => {
    if (storeID && storeID !== '-1') {
        const storeConfig = discount.config?.storeConfigs?.[storeID]
        if (storeConfig?.cover?.imageSource || noDefault) {
            return storeConfig?.cover?.imageSource
        }
    }
    return discount.avatar;
}

export const setDiscountCoverImage = (discount: Discount, imageSource: string | undefined, storeID?: ID | undefined) => {
    if (storeID && storeID !== '-1') {
        discount.config = {
            ...discount.config,
            storeConfigs: {
                ...discount.config?.storeConfigs,
                [storeID]: {
                    ...discount.config?.storeConfigs?.[storeID],
                    cover: {
                        ...discount.config?.storeConfigs?.[storeID]?.cover, imageSource
                    }
                }
            }
        }
    } else discount.avatar = imageSource
    return discount
}

export const getDiscountBodyImage = (discount: Discount, storeID?: ID | undefined, noDefault?: boolean) => {
    if (storeID && storeID !== '-1') {
        const storeConfig = discount.config?.storeConfigs?.[storeID]
        if (storeConfig?.discountImage?.imageSource || noDefault) {
            return storeConfig?.discountImage?.imageSource
        }
    }
    return discount.body;
}

export const setDiscountBodyImage = (discount: Discount, imageSource: string | undefined, storeID?: ID | undefined) => {
    if (storeID && storeID !== '-1') {
        discount.config = {
            ...discount.config,
            storeConfigs: {
                ...discount.config?.storeConfigs,
                [storeID]: {
                    ...discount.config?.storeConfigs?.[storeID],
                    discountImage: {
                        ...discount.config?.storeConfigs?.[storeID]?.discountImage, imageSource
                    }
                }
            }
        }
    } else discount.body = imageSource
    return discount
}

type DiscountRedemptionTypeInformation = {
    RedemptionButtons: (JSX.Element | undefined)[]
    discountType: DiscountRedemptionType
    discountTypeConfig?: typeof DiscountRedemptionTypesMapping[DiscountRedemptionType]
    IntregrationRedeemButtons: (JSX.Element | undefined)[]
    integrationDiscountType: IntegrationRedemptionMethod
    integrationDiscountTypeConfig?: typeof IntegrationRedemptionMethodsMapping[IntegrationRedemptionMethod]
    CouponCodeTypeButtons: (JSX.Element | undefined)[]
    couponType: DiscountCouponType
    couponTypeConfig?: typeof DiscountCouponTypesMapping[DiscountCouponType]
    UniqueCodeTypeButtons: (JSX.Element | undefined)[]
    uniqueCodeType: DiscountCouponUniqueCodeType
    uniqueCodeTypeConfig?: typeof DiscountCouponUniqueCodeTypesMapping[DiscountCouponUniqueCodeType]
}

export function getDiscountTypeInformation(discount: Discount, getButton?: (props: RedeemButtonProps, primary?: boolean) => JSX.Element): DiscountRedemptionTypeInformation {

    const RedeemTypes = (Object.keys(DiscountRedemptionTypesMapping) as DiscountRedemptionType[])
    const IntegrationRedeemTypes = (Object.keys(IntegrationRedemptionMethodsMapping) as IntegrationRedemptionMethod[])
    const CouponTypes = (Object.keys(DiscountCouponTypesMapping) as DiscountCouponType[])
    const UniqueTypes = (Object.keys(DiscountCouponUniqueCodeTypesMapping) as DiscountCouponUniqueCodeType[])

    let discountType: DiscountRedemptionType = 'default' as DiscountRedemptionType
    let discountTypeConfig: typeof DiscountRedemptionTypesMapping[DiscountRedemptionType] | undefined
    const RedemptionButtons = RedeemTypes
        .filter(key => DiscountRedemptionTypesMapping[key].visisble())
        .map((key) => {
            if (DiscountRedemptionTypesMapping[key].check(discount)) {
                discountType = key as DiscountRedemptionType
                discountTypeConfig = DiscountRedemptionTypesMapping[key]
            }
            return getButton?.({ key, ...DiscountRedemptionTypesMapping[key] }, true) || undefined;
        });

    let integrationDiscountType: IntegrationRedemptionMethod = 'couponCode' as IntegrationRedemptionMethod
    let integrationDiscountTypeConfig: typeof IntegrationRedemptionMethodsMapping[IntegrationRedemptionMethod] | undefined
    const IntregrationRedeemButtons = IntegrationRedeemTypes
        .filter(key => IntegrationRedemptionMethodsMapping[key].visisble())
        .map((key) => {
            if (IntegrationRedemptionMethodsMapping[key].check(discount)) {
                integrationDiscountType = key as IntegrationRedemptionMethod
                integrationDiscountTypeConfig = IntegrationRedemptionMethodsMapping[key]
            }
            return getButton?.({ key, ...IntegrationRedemptionMethodsMapping[key] }) || undefined;
        });

    let couponType: DiscountCouponType = 'sameCode' as DiscountCouponType
    let couponTypeConfig: typeof DiscountCouponTypesMapping[DiscountCouponType] | undefined
    const CouponCodeTypeButtons = CouponTypes
        .filter(key => DiscountCouponTypesMapping[key].visisble())
        .map((key) => {
            if (DiscountCouponTypesMapping[key].check(discount)) {
                couponType = key as DiscountCouponType
                couponTypeConfig = DiscountCouponTypesMapping[key]
            }
            return getButton?.({ key, ...DiscountCouponTypesMapping[key] }) || undefined;
        });

    let uniqueCodeType: DiscountCouponUniqueCodeType = 'randomAIQ' as DiscountCouponUniqueCodeType
    let uniqueCodeTypeConfig: typeof DiscountCouponUniqueCodeTypesMapping[DiscountCouponUniqueCodeType] | undefined
    const UniqueCodeTypeButtons = UniqueTypes
        .filter(key => DiscountCouponUniqueCodeTypesMapping[key].visisble())
        .map((key) => {
            if (DiscountCouponUniqueCodeTypesMapping[key].check(discount)) {
                uniqueCodeType = key as DiscountCouponUniqueCodeType
                uniqueCodeTypeConfig = DiscountCouponUniqueCodeTypesMapping[key]
            }
            return getButton?.({ key, ...DiscountCouponUniqueCodeTypesMapping[key] }) || undefined;
        });

    console.log('%c--------------------%cDiscountRedemption%c--------------------', 'color: green', 'color: aqua', 'color: green');
    console.log('discountType:', discountType);
    console.log('integrationDiscountType:', integrationDiscountType);
    console.log('couponType:', couponType);
    console.log('uniqueCodeType:', uniqueCodeType);
    console.log('%c--------------------%cDiscountRedemption%c--------------------', 'color: green', 'color: aqua', 'color: green');

    return {
        RedemptionButtons,
        discountType, discountTypeConfig,
        IntregrationRedeemButtons,
        integrationDiscountType, integrationDiscountTypeConfig,
        CouponCodeTypeButtons,
        couponType, couponTypeConfig,
        UniqueCodeTypeButtons,
        uniqueCodeType, uniqueCodeTypeConfig
    }
}

export function setDiscountTypeInformation(discount: Discount): Discount {

    const RedeemTypes = (Object.keys(DiscountRedemptionTypesMapping) as DiscountRedemptionType[])
    const IntegrationRedeemTypes = (Object.keys(IntegrationRedemptionMethodsMapping) as IntegrationRedemptionMethod[])
    const CouponTypes = (Object.keys(DiscountCouponTypesMapping) as DiscountCouponType[])
    const UniqueTypes = (Object.keys(DiscountCouponUniqueCodeTypesMapping) as DiscountCouponUniqueCodeType[])

    RedeemTypes.forEach(key => discount = DiscountRedemptionTypesMapping[key].applyInitial(discount))
    IntegrationRedeemTypes.forEach(key => discount = IntegrationRedemptionMethodsMapping[key].applyInitial(discount))
    CouponTypes.forEach(key => discount = DiscountCouponTypesMapping[key].applyInitial(discount))
    UniqueTypes.forEach(key => discount = DiscountCouponUniqueCodeTypesMapping[key].applyInitial(discount))

    return discount
}