import { timeUtils, utils } from '../../../../../helpers';
import {
    MiniContactInfo,
    SignupError,
    Survey,
    Survey_Cleaned,
    SurveyAnswer,
    SurveyAnswerValueType,
    SurveyQuestion,
    SurveyQuestionConfig,
    SurveyQuestionType,
    SurveySubmission,
    SurveyWithInsights,
} from '../../../types';

type Impression = {
    uid: string
    contactID?: string
    surveyID: string
    timestamp: number
}
type LocalImpressionTracker = {
    [key: string]: Impression
}

// Determine whether to trigger an impression for this survey
function isImpression(uid: string, contactID?: string, surveyID?: string, isDemo?: boolean,): boolean {
    if (!isDemo && !!surveyID) {
        const key = `${uid}-${surveyID}`
        // In order to prevent multiple impressions, we need to check if this is a new impression
        const impressions: LocalImpressionTracker = (utils.local.getWithExpiry('impressions') || {})

        const impression: Impression | undefined = (impressions[key]), impressionTS = impression?.timestamp
        const newContact = !impression || (contactID && impression?.contactID !== contactID)
        // Can only view same survey as an impression once per day
        const isNotCooldown = !impressionTS || (impressionTS && (Date.now() - impressionTS) > (timeUtils.units.DAY_MS * 1))

        // If new contact, or cooldown has passed, then we can add impression
        if (newContact || isNotCooldown) {
            impressions[key] = {
                uid,
                contactID,
                surveyID: surveyID || '',
                timestamp: Date.now()
            }

            utils.local.setWithExpiry('impressions', impressions, (timeUtils.units.DAY_MS * 1))
            return true
        }
    }
    return false
}


type AnswersMap = { [key: string]: SurveyAnswer }

function validateSurvey(survey: Survey, answers: AnswersMap): { [key: string]: SignupError[] } {
    const errorMap = (survey.questions || [])
        .filter((question: SurveyQuestion) => {
            if (question.disabled) return false
            // IF question is not required and has no value, then skip
            if (!question.required && !answers[question.id]) return false
            return true
        })
        .reduce((p, question: SurveyQuestion) => {
            const surveyAnswer: SurveyAnswer | undefined = (answers[question.id] || undefined)
            const value = surveyAnswer?.value || undefined
            const isString = typeof value === 'string'
            const errors = (p[question.id] || [])

            if (!validateQuestionType(question.type)) {
                errors.push({
                    id: question.id,
                    label: question.label || '',
                    message: 'This input is invalid'
                })
                return { ...p, [question.id]: errors }
            }

            if (question.type === 'email' && value && !utils.emailRE.test(value as string)) {
                errors.push({
                    id: question.id,
                    label: question.label || '',
                    message: 'You must enter a valid email address'
                })
            }

            if (question.type === 'phone' && value && !utils.phoneRex.test(value as string)) {
                errors.push({
                    id: question.id,
                    label: question.label || '',
                    message: 'You must enter a valid phone number'
                })
            }

            // If question is required and has no value
            if (question.required && value === undefined) {
                errors.push({
                    id: question.id,
                    label: question.label || '',
                    message: 'This is a required field'
                })
                return { ...p, [question.id]: errors }
            }

            // If question has a value && a validator, then test it as regex
            if (value !== undefined && question.validator) {
                const regex = new RegExp(question.validator)
                if (isString && !regex.test(value)) {
                    errors.push({
                        id: question.id,
                        label: question.label || '',
                        message: question.validatorMessage || 'This input is invalid'
                    })
                }
            }

            return { ...p, [question.id]: errors }
        }, {} as { [key: string]: SignupError[] })
    // Remove empty keys
    Object.keys(errorMap).forEach((key: string) => {
        if (!errorMap[key].length) delete errorMap[key]
    })
    return errorMap
}

function cleanSurveySubmission(submission: SurveySubmission, survey: Survey, answersMap: AnswersMap, contactInfo: MiniContactInfo): SurveySubmission {
    const phone = contactInfo?.phone || submission?.phone || ''
    const email = contactInfo?.email || submission?.email || ''

    const answers = cleanSurveyAnswers(survey.questions, answersMap)

    return {
        ...submission,
        id: submission?.id || utils.getUUID(),
        surveyID: survey.id || submission?.surveyID,
        contactID: submission?.contactID || '',
        phone, email,
        answers
    }
}

function cleanSurveyAnswers(questions: SurveyQuestion[], answersMap: AnswersMap): SurveyAnswer[] {
    return questions
        .filter((question: SurveyQuestion) => {
            if (question.disabled) return false
            return true
        })
        .map((question: SurveyQuestion) => {
            const surveyAnswer: SurveyAnswer | undefined = (answersMap[question.id] || undefined)
            const value = formatInputValue(question?.type, surveyAnswer?.value)

            return {
                ...surveyAnswer,
                questionID: surveyAnswer?.questionID || question.id,
                value
            }
        })
}

function formatInputValue(type: SurveyQuestionType, value?: SurveyAnswerValueType) {
    const isBoolean = SurveyQuestionConfig[type]?.isBoolean
    // 1. convert booleans to "yes" or "no"
    if (isBoolean) return value ? 'yes' : 'no'
    // 2. set empty arrays to null
    if (Array.isArray(value) && !value.length) return null
    return value || ''
}

function validateQuestionType(type: SurveyQuestionType) {
    const allTypes = Object.keys(SurveyQuestionConfig) as SurveyQuestionType[]
    return allTypes.includes(type)
}

function hasSubmitPII(contactInfo: MiniContactInfo): boolean {
    let canSubmit = !!contactInfo.email || !!contactInfo.phone
    if (contactInfo.email && !utils.emailRE.test(contactInfo.email)) canSubmit = false
    if (!canSubmit && contactInfo.phone && !utils.phoneRex.test(contactInfo.phone)) canSubmit = false
    return canSubmit
}


function isValidPII(str: string, type: 'email' | 'phone'): boolean {
    let valid = false
    if (type === 'email') valid = utils.emailRE.test(str)
    if (type === 'phone') valid = utils.phoneRex.test(str)
    return valid
}

function cleanSurvey(survey_insight: SurveyWithInsights | Survey | Survey_Cleaned): Survey_Cleaned {

    const survey: Survey = (survey_insight as SurveyWithInsights)?.survey || (survey_insight as Survey)
    const insights = ((survey_insight as SurveyWithInsights).insights || [])

    const cleaned = (survey as Survey_Cleaned)
    // Highest insights.totalCompletions value is the total submissions
    const highestInsight = (insights || []).reduce((p, insight) => {
        if (insight.totalCompletions > p) return insight.totalCompletions
        return p
    }, 0)

    const submissions = cleaned?.submissions || highestInsight || 0
    const lastTS = submissions > 0 ? Math.max(...(insights || []).map((insight) => insight.lastSubmission ? (new Date(insight.lastSubmission).getTime() / 1000) : 0)) : 0
    const lastSubmission = cleaned?.lastSubmission || lastTS

    return ({
        ...survey,
        visits: (survey_insight as SurveyWithInsights)?.visits || 0,
        sends: (survey_insight as SurveyWithInsights)?.sends || 0,
        lastSubmission,
        submissions,
    })
}



export const SurveyHelpers = {
    cleanSurvey,
    isValidPII,
    hasSubmitPII,
    isImpression,
    validateSurvey,
    validateQuestionType,
    cleanSurveySubmission
}