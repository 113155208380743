import { Drawer } from 'antd';
import React from 'react';

import Break from '../../../components/Break';
import FA from '../../../components/global/FA';
import GPop from '../../../components/global/GPop';
import {
	getAcknowledgement,
	isStillSnoozed,
	isUnViewed,
	sortUserAlerts,
	submitUserAlertAction,
	timeUtils,
} from '../../../helpers';
import allStyles, { colors } from '../../../helpers/allStyles';
import userUtils from '../../../helpers/userUtils';
import utils from '../../../helpers/utils';
import { AlertsContextProvider, DrawerStateContextProvider } from '../../../hooks/useContext';
import useQuery from '../../../hooks/UseQuery';
import { AlertsContext, DrawerStateContext, UserAlert } from '../../../types';
import { AlertMessage } from './AlertMessage';

export default function NotificationsDrawer({ ...props }: {
	noLayout?: boolean,
	previewOpen?: boolean,
	onClose?: () => void,
	previewAlerts?: UserAlert[],
}) {

	// ! IF no layout, must be budtender on budtender page otherwise it fails/ returns null
	const dontShowNotifications = props.noLayout && !utils.strify(location.pathname).includes('walletsearch');

	const isPreview = !!props.previewAlerts?.length;
	const query = useQuery();
	const currentEmail = utils.auth.getEmail();

	const drawerContext = React.useContext(DrawerStateContextProvider) as DrawerStateContext;
	const alertContext = React.useContext(AlertsContextProvider) as AlertsContext;
	const [alertData, setAlertData] = alertContext;

	const [loader, setLoader] = React.useState({ loading: false, loaded: false, });
	const [collapables, setCollapables] = React.useState({
		featureHighlights: false,
		accountNotifications: false,
	});

	// get un-viewed alerts & sort by created date
	const {
		unViewedCount,
		requiredAcknowledgement,
		nonFeatureHighlights,
		featureHighlights,
	} = React.useMemo(() => {

		const config = {
			unViewedCount: 0,
			requiredAcknowledgement: [] as string[],
			nonFeatureHighlights: [] as UserAlert[],
			featureHighlights: [] as UserAlert[],
		};

		if (!loader.loaded || query.get('ignoreAlerts') || dontShowNotifications) {
			return config
		};


		const allAlerts: UserAlert[] = (props.previewAlerts || (Object.values(alertData.alerts || {})))
		config.nonFeatureHighlights = (allAlerts || [])
			.filter((alert: UserAlert) => {
				if (!!alert?.id && !isStillSnoozed(alert, utils.uid, currentEmail)) {
					if (alert.required && getAcknowledgement(alert, utils.uid, currentEmail) === undefined) {
						config.requiredAcknowledgement.push(`Alert: ${alert.prefix?.value || ''} is required!`);
					}
					if (isUnViewed(alert, utils.uid, currentEmail)) {
						config.unViewedCount++;
					}
					if (alert.featureHighlight) {
						config.featureHighlights.push(alert);
						return false
					}
					return true

				}
				return false
			})
			.sort((a, b) => sortUserAlerts(a, b));

		return config
	}, [
		loader.loaded,
		alertData.updated,
		props.previewAlerts
	]);

	React.useEffect(() => {
		if (alertData.updated !== 0 && !loader.loaded) {
			// auto open if there are un-viewed alerts and the user is an advertiser
			// const autoOpen: boolean = (unViewedCount > 0 && utils.user?.type === 'advertiser');
			// drawerContext.setNotificationDrawerOpen(autoOpen);
			setLoader(s => ({ ...s, loaded: true }));
		}
	}, [alertData.updated, unViewedCount, loader.loaded]);


	const handleClose = () => {

		if (props.onClose) props.onClose();
		if (props.previewAlerts?.length) {
			return;
		}

		const acknowledgementRequired: boolean = requiredAcknowledgement.length > 0;
		// if there are any required alerts that have not been acknowledged, we disable the close ability
		if (acknowledgementRequired) {
			const errors: string[] = requiredAcknowledgement
			return utils.showErrs(errors, 'You must acknowledge required alerts!');
		};

		// set all visible alerts to viewed and close the drawer
		drawerContext.setNotificationDrawerOpen(false);
		submitUserAlertAction(alertContext, nonFeatureHighlights, { action: 'view', });
	}

	const itemStyle: React.CSSProperties = {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		columnGap: 16,
		cursor: 'pointer',
		borderRadius: 6,
		padding: `2px 4px`
	}

	if (dontShowNotifications) {
		return null
	}

	if (userUtils.debugMode() && userUtils.isExperimental()) {
		(window as any)['notifyDrawerDebug'] = {
			openDrawer: () => drawerContext.setNotificationDrawerOpen(true),
			alertData,
			unViewedCount,
			requiredAcknowledgement,
			nonFeatureHighlights,
			featureHighlights,
		};
	}

	return (<>
		<Drawer
			width={'60vw'}
			closable={false}
			mask={!isPreview}
			placement="right"
			open={props.previewOpen || drawerContext.notificationDrawerOpen}
			onClose={handleClose}
			contentWrapperStyle={{ minWidth: 450, maxWidth: 600, borderLeft: '1px dashed #C4C5CC' }}
			headerStyle={{ ...utils.noPadding, ...utils.noMargin }}
			footerStyle={{ ...utils.noPadding, ...utils.noMargin }}
			bodyStyle={{ ...utils.noPadding, ...utils.noMargin }}
			title={undefined}
			footer={undefined}
		>
			{/* <div style={{ padding: (!!featureHighlights.length) ? '8px 32px 16px 32px' : '16px 32px' }}> */}
			<div style={{ padding: '16px 32px' }}>
				<div>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
						<span style={{ fontWeight: 600, fontSize: '1.1rem' }}>
							<FA name='bullhorn' style={{ marginRight: 6 }} /> ACCOUNT NOTIFICATIONS
						</span>
						<div style={{ display: 'flex', alignContent: 'center', alignItems: 'center', gap: 10 }}>
							{!!nonFeatureHighlights.length && <>
								<GPop
									placement='bottomRight'
									style={{ ...utils.noPadding }}
									overlayInnerStyle={{ ...utils.noPadding }}
									rawProps={{
										overlayStyle: { ...utils.noPadding },
									}}
									content={<>
										<div style={{ display: 'flex', justifyContent: 'center', padding: `0px 0px 6px 0px`, }}>
											<b>Snooze all notifications</b>
										</div>
										<div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
											<div
												className='errorHover hover-menu-item-bad'
												onClick={() => submitUserAlertAction(alertContext, nonFeatureHighlights, { action: 'snooze', snoozeDuration: timeUtils.units.DAY_S }, props.noLayout)}
												style={itemStyle}
											>
												{'Remind me in 24 hours'} <FA name="alarm-clock" />
											</div>
											<div
												className='errorHover hover-menu-item-bad'
												onClick={() => submitUserAlertAction(alertContext, nonFeatureHighlights, { action: 'snooze', snoozeDuration: timeUtils.units.WEEK_S }, props.noLayout)}
												style={itemStyle}
											>
												{'Dismiss for a week'} <FA name="clock" />
											</div>
											<div
												className='errorHover hover-menu-item-bad'
												onClick={() => submitUserAlertAction(alertContext, nonFeatureHighlights, { action: 'snooze', snoozeDuration: -1 }, props.noLayout)}
												style={itemStyle}
											>
												{`Don't remind me again`} <FA name="bell-slash" />
											</div>
										</div>
									</>}
								>
									<span className='linked'>
										<FA name='bell-slash' style={{ marginRight: 6 }} />
									</span>
								</GPop>
							</>}
							<span>
								<FA
									name={collapables.accountNotifications ? 'chevron-up' : 'chevron-down'}
									onClick={() => setCollapables(s => ({ ...s, accountNotifications: !s.accountNotifications }))}
								/>
							</span>
						</div>
					</div>
					<Break v='10' s='0' />
				</div>

				{!collapables.accountNotifications && <>
					{nonFeatureHighlights.length ? <>
						<RenderAlertMessages activeAlerts={nonFeatureHighlights} preview={isPreview} />
					</> : <>
						<div style={{ padding: '5px 0px 12px 0px', borderBottom: `1px solid ${colors.primary.dividerColor()}`, }}>
							<AlertMessage
								noLayout={props.noLayout}
								noButtons
								alert={{
									type: 'success',
									prefix: { value: 'No alerts exist at this time', color: '#000' },
								}} />
						</div>
					</>}
				</>}

			</div>

			{(!!featureHighlights.length) && <>
				<div style={{ padding: '16px 32px 8px 32px' }}>
					<div>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}>
							<span style={{ fontWeight: 600, fontSize: '1.1rem' }}>
								<FA name='stars' style={{ marginRight: 6 }} /> FEATURE HIGHLIGHT
							</span>
							<span>
								<FA
									name={collapables.featureHighlights ? 'chevron-up' : 'chevron-down'}
									onClick={() => setCollapables(s => ({ ...s, featureHighlights: !s.featureHighlights }))}
								/>
							</span>
						</div>
						<Break v='10' s='0' />
					</div>

					{!collapables.featureHighlights && <>
						{featureHighlights.length ? <>
							<RenderAlertMessages activeAlerts={featureHighlights} preview={isPreview} />
						</> : <>
							<div style={{ padding: '5px 0px 12px 0px', borderBottom: `1px solid ${colors.primary.dividerColor()}`, }}>
								<AlertMessage
									noLayout={props.noLayout}
									noButtons
									alert={{
										type: 'success',
										prefix: { value: 'No alerts exist at this time', color: '#000' },
									}} />
							</div>
						</>}
					</>}

				</div>
			</>}
		</Drawer >
	</>);
}

export interface RenderALertMessageProps {
	activeAlerts: UserAlert[],
	containerStyle?: React.CSSProperties,
	wrapperStyle?: React.CSSProperties

	isExternal?: boolean, // is this being rendered in a popup or modal?
	dontSort?: boolean, // don't sort the alerts by created date
	noLayout?: boolean,
	preview?: boolean,
}

export function RenderAlertMessages({ activeAlerts, ...props }: RenderALertMessageProps) {
	// memoize the alerts
	const alerts = React.useMemo(() => {
		// sort alerts by created date
		if (!props.dontSort) activeAlerts = (activeAlerts || []).sort((a, b) => (b.created || 0) - (a.created || 0));
		// return the alerts
		return activeAlerts;
	}, [activeAlerts, props.dontSort]);

	return <>
		<div style={{ display: 'grid', gridTemplateColumns: 'repeat(1,1fr)', overflowX: 'hidden', overflowY: 'auto', maxHeight: 'auto', scrollbarWidth: 'thin', rowGap: 0, ...props.wrapperStyle }}>
			{(alerts || []).map((alert: UserAlert, i: number) => {
				const isLast = i === alerts.length - 1;
				const styles = {
					borderBottom: (isLast ? '' : `1px solid ${colors.primary.dividerColor()}`),
					padding: props?.isExternal ? '10px 0px' : (i === 0 ? `5px 0px 10px 0px` : `10px 0px`),
				}
				return <div key={i} style={{ gridColumn: 'span 1', ...styles, ...props.containerStyle }}>
					<AlertMessage alert={alert} noLayout={props.noLayout} preview={props.preview} />
				</div>;
			})}
		</div>
	</>
}
