import { CSSProperties } from 'react';


import utils from '../../../helpers/utils';
import { mergeThese } from '../../../pages/wallet/utils';

// * Selecting these colors can default most of the style out
export const defaultColors = {
	color: '#FFFFFF',
	primary: '#191919',
	secondary: '#161616',
	highlight: '#13CF80',
	error: '#FA4D4F',
	link: '#1890FF'
}

export const defaultDividerColor = '#E6E8F0'

export const defaultText = {
	title: {
		color: '#191919',
		fontWeight: 600,
		fontSize: 16
	},
	description: {
		color: '#191919',
		fontWeight: 400,
		fontSize: 14
	},
	small: {
		color: '#191919',
		fontWeight: 400,
		fontSize: 12
	}
}

export const defaultButton = (colors: any) => ({
	borderRadius: 6,
	borderWidth: 1,
	borderColor: colors.highlight,
	backgroundColor: colors.highlight,
	color: colors.color,
	fontWeight: 400,
})
export const defaultButtonSecondary = (colors: any) => ({
	borderRadius: 6,
	borderWidth: 1,
	borderColor: colors.highlight,
	backgroundColor: 'transparent',
	color: colors.highlight,
	fontWeight: 400,
})
export const defaultButtonDanger = (colors: any) => ({
	borderRadius: 6,
	borderWidth: 1,
	borderColor: colors.error,
	backgroundColor: 'transparent',
	color: colors.error,
	fontWeight: 400,
})

export interface ColorSet {
	color?: string | undefined | any
	primary?: string
	secondary?: string
	highlight?: string
	error?: string
	link?: string
}

export interface ButtonStyle extends ColorSet {
	borderRadius?: number
	borderTopRightRadius?: number
	borderTopLeftRadius?: number
	borderBottomRightRadius?: number
	borderBottomLeftRadius?: number
	borderWidth?: number
	borderColor?: string,
	backgroundColor?: string,
	color?: string | undefined
	fontSize?: number;
	fontWeight?: string | number
	enabled?: boolean // ! for optional buttons in future?
	customCSS?: CSSProperties
	demoCSS?: CSSProperties
}

export interface InputStyle extends ColorSet, CSSProperties {
	shadow?: {
		alpha: number
		enabled?: boolean
		color?: string
		inset?: boolean
		config?: string
		raw?: string
	},
	label: CSSProperties | any
	borderRadius?: number
	borderTopRightRadius?: number
	borderTopLeftRadius?: number
	borderBottomRightRadius?: number
	borderBottomLeftRadius?: number
	borderWidth?: number
	borderColor?: string,
	backgroundColor?: string,
	color?: string | undefined
	fontSize?: number;
	fontWeight?: string | number
	enabled?: boolean // ! for optional inputs in future?
	customCSS?: CSSProperties
	demoCSS?: CSSProperties
}

export interface StyleItem extends CSSProperties {
	[key: string]: any
	primary?: string
	secondary?: string
	highlight?: string
}

export interface MessageCardConfig extends StyleItem {
	timestamp?: {
		color: string
		enabled: boolean
	}
}

export interface DynamicContentConfig extends StyleItem {
	button?: ButtonStyle
}

export interface GeneralConfig extends StyleItem {
	button?: ButtonStyle
	text?: TextStyleItem
	container?: CSSProperties
}

export interface OrderHistoryConfig extends StyleItem {
	text?: TextStyleItem
	container: CSSProperties
}

export interface HeaderConfig extends StyleItem {
	[key: string]: any
	type: 'a' | 'b' | 'c' | 'd'
	addrColor: string
	hideStoreInfo?: boolean
	logo?: string
	logoStyle: CSSProperties
}

export interface TextStyleItem {
	title: CSSProperties,
	description: CSSProperties
	small: CSSProperties
	label: CSSProperties
	link: CSSProperties
}


interface Config extends ColorSet {
	dividerColor: string,

	text: TextStyleItem

	login: GeneralConfig

	header: HeaderConfig,
	footer: StyleItem,
	body: StyleItem,

	pointsIcon: ColorSet,

	tabs: ColorSet,

	messageCard: MessageCardConfig,
	discountCard: StyleItem,

	pointsAndPin: GeneralConfig,
	profile: GeneralConfig,

	dynamicContent: DynamicContentConfig
	reviewPage: GeneralConfig
	faqs: GeneralConfig

	orderHistory: OrderHistoryConfig
	orderHistoryItem: GeneralConfig

	input: InputStyle,
	button: ButtonStyle,
	buttonSecondary: ButtonStyle,
	buttonHighlight: ButtonStyle,
	buttonDanger: ButtonStyle,
}

export interface WalletStyles {
	config?: Config
	discountRenderType?: 'row' | 'card' | 'default'
}

const walletStyles = (colors?: any, text?: any, dividerColor?: any, buttons?: any, ss?: any) => {

	const button = mergeThese(defaultButton(colors), buttons.button)
	const buttonSecondary = mergeThese(defaultButtonSecondary(colors), buttons.buttonSecondary)
	const buttonDanger = mergeThese(defaultButtonDanger(colors), buttons.buttonDanger)

	const input = mergeThese({
		label: {
			color: '#191919',
		},
		borderRadius: 6,
		borderWidth: 1,
		borderColor: colors.highlight,
		backgroundColor: 'transparent',
	}, ss.config?.input)

	return ({
		//! New style
		config: {
			...colors,
			button,
			buttonSecondary,
			buttonDanger,
			text,

			login: {
				...colors,
				text: mergeThese({ ...text, }, {
					title: {
						color: text.title.color,
						fontWeight: 600,
						fontSize: 16
					},
					description: {
						color: '#4A4A4D',
						fontSize: 14
					},
					small: {
						color: '#4A4A4D',
						fontSize: 12
					}
				}),
				inputLabelColor: '#7B7B80',

				input: mergeThese({ ...input, }, {
					borderColor: `rgb(217, 217, 217)`
				}),
			},

			dividerColor,

			header: {
				...colors,
				type: 'a',
				addrColor: '#AEAEAE',
				logoStyle: {
					height: 42,
					width: 42
				}
			},
			footer: {
				...colors
			},
			body: {
				backgroundColor: '#FFFFFF',
			},

			pointsIcon: {
				...colors
			},

			tabs: {
				...colors
			},

			discountCard: {
				borderRadius: 0,
				...colors,

				color: colors.primary,

				button: mergeThese({ ...button, }, {

				}),

				progress: {
					...colors,
					height: 12,
				}
			},

			messageCard: {
				...colors,

				button: mergeThese({ ...button, }, {

				}),

				text: mergeThese({ ...text, }, {
					small: {
						color: '#7B7B80'
					}
				}),
			},

			dynamicContent: {
				color: colors.primary,
				backgroundColor: '#FFF',


				aButtonText: 'Buy Now',
				bButtonText: 'Buy Again',


				borderTopRightRadius: 10,
				borderTopLeftRadius: 10,

				button: mergeThese({ ...button, }, {
					borderRadius: 0,
					fontWeight: 600
				}),
			},

			reviewPage: {
				text: mergeThese({ ...text, }, {
					title: {
						...text.title,
						fontSize: 20
					}
				}),

				container: {
					borderRadius: 10,
					padding: 10,
					backgroundColor: colors.color,
					borderColor: colors.highlight,
					borderWidth: 1,
					borderStyle: 'dashed'

				},
				button: mergeThese({ ...button, }, {
					fontWeight: 600
				}),
			},

			orderHistory: {
				text,

				container: {
					padding: 15,
					borderWidth: 1,
					borderBottomStyle: 'solid',
					borderBottomWidth: 2,
					borderBottomColor: dividerColor
				},

			},

			orderHistoryItem: {
				text,

				showRating: true,
				reviewStars: {
					color: '#FDD923',
					fontSize: 32
				},

				container: {
					paddingTop: 15,
					paddingBottom: 15,
					borderWidth: 1,
					borderBottomStyle: 'solid',
					borderBottomWidth: 1,
					borderBottomColor: dividerColor,
				},

				// text: {
				// 	title: {
				// 		color: '#191919',
				// 		fontWeight: 600,
				// 		fontSize: 16
				// 	},
				// 	description: {
				// 		color: '#191919',
				// 		fontSize: 14
				// 	},
				// 	small: {
				// 		color: '#191919',
				// 		fontSize: 12
				// 	}
				// },

				button: mergeThese({ ...button, }, {

				}),
			},

			pointsAndPin: {
				text: mergeThese({ ...text, }, {
					title: {
						color: text.title.color,
						fontWeight: 700,
						fontSize: 40
					},
					description: {
						color: text.description.color,
						fontSize: 12
					},
					small: {
						color: '#7B7B80',
						fontSize: 12
					}
				}),
				button: mergeThese({ ...button, }, {

				}),
			},

			profile: {
				...colors,
				text: mergeThese({ ...text, }, {
					title: {
						color: text.title.color,
						fontWeight: 700,
						fontSize: 16
					},
					description: {
						color: text.description.color,
						fontWeight: 700,
						fontSize: 14
					},
					small: {
						color: text.small.color,
						fontSize: 12
					}
				}),
				input: mergeThese({ ...input, }, {
					borderRadius: 0,
					borderWidth: 0,
					backgroundColor: 'transparent',
					color: '#191919',
				}),

			},


			faqs: {
				...colors,
				text: mergeThese({ ...text, }, {
					...text,
					title: {
						color: text.title.color,
						fontWeight: 600,
						fontSize: 16
					},
					description: {
						color: text.description.color,
						fontWeight: 400,
						fontSize: 14
					}
				}),
			},

			input,

		}
	} as WalletStyles)
};

export const getWalletButtonStyle = (b?: ButtonStyle) => {
	return utils.trimObject({
		...b,
		color: b?.color,
		border: `${b?.borderWidth}px solid ${b?.borderColor}`,
		borderRadius: b?.borderRadius,
		borderTopRightRadius: b?.borderTopRightRadius,
		borderTopLeftRadius: b?.borderTopLeftRadius,
		borderBottomRightRadius: b?.borderBottomRightRadius,
		borderBottomLeftRadius: b?.borderBottomLeftRadius,
		borderWidth: b?.borderWidth,
		borderColor: b?.borderColor,
		background: b?.backgroundColor,
		fontSize: b?.fontSize,
		fontWeight: b?.fontWeight,
	})
}



export default walletStyles;
