import './styles/gtag.css';

import { CloseCircleOutlined } from '@ant-design/icons';
import React, { CSSProperties, useRef, useState } from 'react';

import { colors, colorToHex, colorUtils } from '../../helpers/allStyles';
import utils from '../../helpers/utils';
import useMountedEffect from '../../hooks/useMountedEffect';
import FA from './FA';
import GTooltip, { GlobalTooltipProps } from './GTooltip';

export type GlobalStyleType =
	| 'default'
	| 'dashed'
	| 'ghost'
	| 'primary'
	| 'primary-outlined'
	| 'primary-dashed'
	| 'secondary'
	| 'secondary-outlined'
	| 'secondary-dashed'
	| 'pink'
	| 'pink-outlined'
	| 'pink-dashed'
	| 'danger'
	| 'danger-outlined'
	| 'danger-dashed'
	| 'danger-dark'
	| 'danger-dark-outlined'
	| 'danger-dark-dashed'
	| 'link'
	| 'default-outlined'
	| 'primary-green-outlined'
	| 'primary-orange-outlined';
export interface GlobalTagProps {
	children?: React.ReactNode; // Can use either or
	text?: React.ReactNode; // Can use either or

	color?: string; // Text color
	autoBG?: boolean; // Automatically set background color to match text color
	hoverColor?: string;
	background?: string; // Background color
	borderColor?: string; // Border (uses background by default)
	hoverable?: boolean; // should this be hoverable (true by default)
	selected?: boolean; // use hover styles (as if selected)
	noSelectImage?: boolean; // to hide select image or not
	style?: CSSProperties;

	size?: 'large' | 'default' | 'small';

	tooltip?: GlobalTooltipProps | React.ReactNode;
	type?: GlobalStyleType;

	linkTo?: string;

	onClick?: () => void;

	hidden?: boolean;

	copyable?: {
		text: string;
		tooltip?: GlobalTooltipProps;
	};
}

// New Tag style to be used across the dash as we roll out new global styles
export default function GTag({ ...props }: GlobalTagProps) {
	const tid = useRef<any>(null);
	const [copied, setCopied] = useState(false);
	const [hover, setHover] = useState(false);

	useMountedEffect(() => {
		return () => {
			if (tid.current) clearTimeout(tid.current);
		};
	}, []);

	let style: CSSProperties = {
		//     borderRadius: 6,
		// padding: '2px 8px 2px 8px',
		//     fontSize: '.75rem',
		//     background: props.background || '#F5F7FF',
		//     border: `1px solid ${props.borderColor || props.background || '#F5F7FF'}`,
		//     color: props.color || '#4A4A4D',
		minWidth: 'max-content',
		width: 'max-content',
		maxWidth: 'max-content',
	};

	const hoverColor = hover && props.hoverable != false ? (props.hoverColor || utils.darkMode ? '#1890FF' : '#1890FF') : undefined;

	if (props.color) {
		style.color = (props.hoverable ? hoverColor : '') || props.color;
		if (props.autoBG) style.background = colorUtils.setHexAlpha(colorToHex(props.color) || '', 0.1) || '';
	}
	if (props.background) style = { ...style, background: props.background, borderColor: (props.hoverable ? hoverColor : '') || props.background };
	if (props.borderColor) style.borderColor = (props.hoverable ? hoverColor : '') || props.borderColor;
	if (props.selected) {
		style.borderColor = utils.darkMode ? '#CCE7FF' : '#CCE7FF';
		style.background = utils.darkMode ? '#131313' : '#F5F7FF';
		style.color = utils.darkMode ? '#1890FF' : '#1890FF';
	}

	let tagClass = `gtag-${props.type || 'default'} gtag-size-${props.size || 'default'} gtag ${!props.hoverable ? 'nothoverable' : ''}`;

	if (props.linkTo && !props.onClick) {
		props.onClick = () => utils.reload(props.linkTo);
		style.cursor = 'pointer';
	}

	if ((!props.children && props.text) || props.hidden) return <></>;

	const tooltipProps: GlobalTooltipProps = {
		tooltip: typeof props.tooltip === 'string' ? props.tooltip : undefined,
		...(!!(props?.tooltip as any)?.tooltip ? (props.tooltip as any) : {}),
	};

	const copyText = props.copyable?.text;
	const isCopyable = !!copyText;

	const onCopy = () => {
		if (!isCopyable) return;
		navigator.clipboard.writeText(copyText);
		setCopied(true);
		tid.current = setTimeout(() => setCopied(false), 2000);
	};

	return (
		<>
			<div
				className={`gtag ${utils.darkMode ? 'gtag-dark' : ''} ${props.hoverable ? 'gtag-hoverable' : ''}`}
				onClick={() => {
					if (isCopyable) onCopy();
					props.onClick?.();
				}}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				<GTooltip {...tooltipProps}>
					<div
						className={tagClass}
						style={{ ...style, ...props.style }}
					>
						{props.children || props.text}
						{!props.noSelectImage && props.selected && <CloseCircleOutlined style={{ fontSize: props.style?.fontSize || 'inherit', marginLeft: 5 }} />}
						{isCopyable && (
							<>
								<FA
									linked
									style={{ fontSize: props.style?.fontSize || '.75rem' || 'inherit', color: copied ? colors.primary.primary : undefined, marginLeft: 5 }}
									name={copied ? 'check' : 'copy'}
									tooltip={{
										...props.copyable?.tooltip,
										tooltip: copied ? 'Copied!' : props.copyable?.tooltip?.tooltip || 'Copy',
									}}
								/>
							</>
						)}
					</div>
				</GTooltip>
			</div>
		</>
	);
}
