import { DBSchema, IDBPDatabase, IDBPObjectStore, openDB } from 'idb';

import { AclPolicy } from '../../types';
import userUtils from '../userUtils';

interface PermDB extends DBSchema {
    alpinePerms: {
        value: {
            route: string;
            method: string;
            perm: string;
            role: string;
        };
        key: string;
        indexes: {
            'route': string;
            'method': string;
            'perm': string;
            'role': string;
            'route_method'?: string[];
            'route_method_role': string[];
        };
    };
}

class PermissionsService {
    private static instance: PermissionsService;
    private permDB?: IDBPDatabase<PermDB>;

    private constructor() { }

    public static getInstance(): PermissionsService {
        if (!PermissionsService.instance) {
            PermissionsService.instance = new PermissionsService();
        }
        return PermissionsService.instance;
    }

    async setPerms(permissions: AclPolicy[]) {
        if (!permissions || !permissions.length) return;

        try {
            await this.openPerms();
            if (!this.permDB) return;
            const transaction = this.permDB.transaction('alpinePerms', 'readwrite');
            const store = transaction.objectStore('alpinePerms');

            transaction.onerror = (event) => {
                if (userUtils.debugMode()) console.error('Transaction error:', event);
            };

            transaction.onabort = (event) => {
                if (userUtils.debugMode()) console.error('Transaction aborted:', event);
            };

            await store.clear();

            const promises: Promise<any>[] = [];

            for (const permission of permissions) {
                const { route, method, perm, role } = permission;
                promises.push(store.put({ route, method, perm, role, }));
            };

            await Promise.all(promises);

            transaction.commit();
        } catch (err) {
            if (userUtils.debugMode()) console.error('setPerms', err);
        }
    }

    async getPerm(policy: AclPolicy): Promise<boolean> {
        try {
            const { role, route, method, perm } = policy;

            if (!this.permDB) {
                await this.openPerms();
                if (!this.permDB) {
                    if (userUtils.debugMode()) console.error('permDB not open');
                    return false;
                }
            }

            const val = await this.permDB.getFromIndex('alpinePerms', 'route_method_role', [route, method, role]);
            return val ? val.perm === perm : false;
        } catch (err) {
            if (userUtils.debugMode()) console.error('getPerm', err);
            return false;
        }
    }

    async getPerms(uid: string, domain?: string, fetchFunc?: (url: string, method?: string, payload?: any, opts?: any, signal?: any, forceToken?: string) => Promise<any>): Promise<AclPolicy[]> {
        try {
            // Assuming you have a fetch method here or use any other method to retrieve permissions.
            const response: AclPolicy[] = await fetchFunc?.(`${domain}/acl/dash/${uid}`);
            if (!response) {
                throw new Error(`Failed to fetch permissions: ${response}`);
            }

            return response;
        } catch (err) {
            if (userUtils.debugMode()) console.error('getPerms', err);
            return [];
        }
    }

    openPerms = async () => {
        const permDB = await openDB<PermDB>('AlpineIQ', 3, {
            upgrade(db, oldVersion, newVersion, transaction) {
                db.onerror = (event) => {
                    if (userUtils.debugMode()) console.error('permDB error upgrading', event);
                }

                let store: IDBPObjectStore<PermDB, ArrayLike<"alpinePerms">, "alpinePerms", "versionchange">;

                if (!oldVersion) {
                    store = db.createObjectStore('alpinePerms', { autoIncrement: true });

                    store.createIndex('route', ['route'], { unique: false });
                    store.createIndex('method', ['method'], { unique: false });
                    store.createIndex('perm', ['perm'], { unique: false });
                    store.createIndex('role', ['role'], { unique: false });
                    store.createIndex('route_method_role', ['route', 'method', "role"], { unique: true });

                } else {
                    store = transaction.objectStore('alpinePerms');
                }

                if (oldVersion < 3) {
                    // Check if the old index exists and delete it
                    if (store.indexNames.contains('route_method')) {
                        store.deleteIndex('route_method');
                    }

                    store.createIndex('role', ['role'], { unique: false });
                    store.createIndex('route_method_role', ['route', 'method', "role"], { unique: true });
                }
            }
        });
        this.permDB = permDB;
    }
}

export default PermissionsService;