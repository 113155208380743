import { fetchAPI } from '.';
import { PointsTimeline } from '../types';

function getPointsTimeline(contactID: string, options?: { start?: number, end?: number, uid?: string, forceToken?: string }): Promise<PointsTimeline> {
    const start = options?.start || -1;
    const end = options?.end || Math.floor(new Date().getTime() / 1000);
    return fetchAPI(`/contact/loyaltyPoints/timeline/:uid/${contactID}?start=${start}&end=${end}`, { ...options, method: 'GET' })
}



export const PersonasAPI = {
    getPointsTimeline,
}