import React from 'react';
import { utils } from '../helpers';

export default function useQuery() {

    type GetConfig = {
        defaultValue: string
        validatorArray: string[]
    }

    function get<T = string>(key: string, config?: GetConfig): T | null {
        const url = new URL(window.location.href)
        const value = url.searchParams.get(key)
        if (config?.defaultValue) {
            const isValid = (config?.validatorArray)?.map((str) => utils.strify(str))?.includes(utils.strify(value) || '')
            if (!isValid) {
                return config.defaultValue as T
            }
        }
        return value as T
    }

    const set = (key: string, value: any) => {
        const url = new URL(window.location.href)
        if (!value) url.searchParams.delete(key)
        else url.searchParams.set(key, value)
        window.history.pushState({}, '', url.toString())
    }

    return { get, set }
}

