import { Space } from 'antd';
import React, { useState } from 'react'
import HoverQuestion from '../../../HoverQuestion';
import GButton from '../../../global/GButton';
import { colors } from '../../../../helpers/allStyles';
import utils from '../../../../helpers/utils';
import Break from '../../../Break';
import { AppSettingsTabProps } from '../NativeAppSettings';
import StoreSelect from '../../../loyalty/storeSelect';

export default function AppSettingsStoreSelector({ state, setState, }: AppSettingsTabProps) {

    const [loading, setLoading] = useState(false);

    const changeStore = (storeID: string) => utils.asyncFetch(async () => {
        const s: any = { storeID, storeName: storeID == '-1' ? 'All Stores' : (state.stores || []).find((st: any) => st.id == storeID)?.name || 'N/A' }
        const storeData = (state.settings.byStore || {})[storeID]
        if (storeID != '-1' && !storeData) {
            setLoading(true)
            const settings = state.settings
            const storeSettings = await utils.auth.get(`settings/${utils.uid}/${storeID}`) || {}
            settings.byStore = { ...settings.byStore, [storeID]: storeSettings }
            s.settings = settings
            setLoading(false)
        }
        setState(s)
    })

    return (<>
        <div>
            <Space>
                <span style={{ color: colors.text.lighter() }}>Currently editing:</span>
                <StoreSelect
                    loading={loading}
                    stores={state.stores}
                    // defaultValue={state.storeID}
                    value={state.storeID}
                    onChange={changeStore}
                    global
                />
                <HoverQuestion
                    style={{ color: colors.text.lighterer() }}
                    title="Optionally, you can customize the settings for each store location. The preview will display the changes for the chosen store."
                />
                <GButton
                    popover={{
                        popConfirm: true,
                        content: 'Are you sure?',
                        useAsync: true,
                        onConfirm: async () => await utils.asyncFetch(async () => {
                            const { storeID, settings } = state,
                                updatedSettings = { ...settings }

                            if (updatedSettings.byStore && updatedSettings.byStore[storeID]) {
                                delete updatedSettings.byStore[storeID]
                                await utils.auth.put(`settings/${state.uid}/${storeID}?notify=false`, updatedSettings)
                                utils.reload()
                            } else {
                                utils.showErr('No settings to reset')
                                return false
                            }

                            return true;
                        }, () => false)
                    }}
                    type='link'
                >
                    Reset <span style={{ fontWeight: 600 }}>{state.storeName}</span>  to default settings
                </GButton>
            </Space>
            <Break air n='10' />
        </div>
    </>)
}
