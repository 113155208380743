import React from "react";
import { MailingAddress } from "../types";

export function cleanString(str: string) {
    if (typeof str === "string") return str.toString().replace(/\s+/g, "").toLowerCase().trim();
    if (React.isValidElement(str)) return str;
    if (Array.isArray(str) || (typeof str === "object" && str !== null)) return JSON.stringify(str || {});
    return str || "";
}

// Clean any input
export function clean(input: any) {
    if (typeof input === "string") return cleanString(input);
    return input
}

export function addressToString(
    address: Partial<MailingAddress> | undefined,
    options?: {
        useRaw?: boolean
        useLatLong?: boolean
        useCountry?: boolean
        clean?: boolean;
    }
): string {
    if (!address) return "";

    const { street, city, state, zipCode, country, lat, long, raw } = address;
    const { useRaw, useLatLong, useCountry, clean = true } = options || {};

    if (raw && useRaw) return raw;
    if (lat && long && useLatLong) return `${lat},${long}`;
    if (country && useCountry) return country;

    const formatAddressPart = (part: string | undefined): string => {
        if (!part) return "";
        return part.split(' ').map(word => {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        }).join(' ');
    };

    const formattedStreet = clean ? formatAddressPart(street) : (street || "");
    const formattedCity = clean ? formatAddressPart(city) : (city || "");
    const formattedState = clean ? formatAddressPart(state) : (state || "");

    return `${formattedStreet}, ${formattedCity}, ${formattedState} ${zipCode || ""}`.trim(); 
}