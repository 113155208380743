import {
    AlertOutlined,
    BugOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    ExperimentOutlined,
    InfoCircleOutlined,
    QuestionCircleOutlined,
    RocketOutlined,
    SettingOutlined,
    StarOutlined,
    ToolOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import React from 'react';

import utils from '../../helpers/utils';
import { LegacyAlert, UserAlert } from '../../types/userAlerts';
import { colors } from '../../helpers';


export const defaultColorsByAlertType: { [key: string]: string } = {
    'critical': colors.primary.error,
    'error': colors.primary.error700,
    'warning': colors.primary.warning,
    'info': colors.primary.info,
    'success': colors.primary.success,
    'recipe': colors.primary.recipe,
    'debug': colors.primary.debug,
    'feature-highlight': '#000000',
    'default': '#000000',
    'marketing': colors.primary.primary,
}

export const defaultIconByAlertType: { [key: string]: string } = {
    'critical': 'fa-regular fa-circle-exclamation',
    'error': 'fa-regular fa-triangle-exclamation',
    'warning': 'fa-regular fa-triangle-exclamation',
    'info': 'fa-regular fa-circle-info',
    'success': 'fa-solid fa-check',
    'recipe': 'fa-regular fa-megaphone',
    'debug': 'fa-solid fa-flask-round-potion',
    'default': '',
    'dataConnect': 'fa-regular fa-cloud-exclamation',
    'marketing': 'fa-regular fa-megaphone',
}

export const getDefaultErrorAlertTitle = (alert: LegacyAlert) => {
    const subject = alert.Subject || '';
    if (subject.includes('There are unmapped stores for')) return 'Unmapped Stores';
    if (subject.includes('Error retrieving from importer')) return 'Importer Error';
    if (subject.includes('Error sending to destination')) return 'Destination Error';
    if (subject.includes('Error with email settings')) return 'Email Settings Error';
    return "Error"
}

export const defaultAlert = (title = '', description = '', alertType = 'alert', renderType = 'default') => ({
    title,
    avatar: '',
    description,
    dateText: 'Date',
    aTooltip: 'View documentation on this message',
    aText: 'Documentation',
    aLink: '',
    bTooltip: 'Show me more!',
    bText: 'Take me',
    bLink: '',
    targetEmails: [],
    targetIDs: [],
    permissionLevels: [],
    viewOnlyOnce: false,
    required: false,
    acknowledged: {},
    alertType,
    renderType,
    // date: moment(new Date).unix(),
    // date: moment(new Date).unix(),
    platformImpacted: '',
    archived: false,
    avatarShape: 'square',
    borderColor: 'default',
    creator: utils.auth.getEmail(),
    aClicks: 0,
    bClicks: 0,
    borderOpacity: 30,
    icon: 'alert',
} as UserAlert);

export const borderColors = (color?: string, opacity = 30) => {
    const colors: { [key: string]: string } = {
        'default': '',
        'red': `rgba(255,77,79,${opacity / 100})`,
        'blue': `rgba(24,142,244,${opacity / 100})`,
        'green': `rgba(44,222,157,${opacity / 100})`,
        'greenDarker': `rgba(52,184,125,${opacity / 100})`,
        'pink': `rgba(255,77,126,${opacity / 100})`,
        'cyan': `rgba(49,196,255,${opacity / 100})`,
        'yellow': `rgba(227,245,37,${opacity / 100})`,
        'orange': `rgba(232,160,86,${opacity / 100})`,
        'purple': `rgba(115,120,245,${opacity / 100})`
    };
    if (!color) return colors;
    return colors[color];
};

export const alertIcons: any = {
    'alert': <AlertOutlined />,
    'update': <ToolOutlined />,
    'error': <CloseCircleOutlined />,
    'question': <QuestionCircleOutlined />,
    'warning': <WarningOutlined />,
    'info': <InfoCircleOutlined />,
    'clock': <ClockCircleOutlined />,
    'test': <ExperimentOutlined />,
    'bug': <BugOutlined />,
    'rocket': <RocketOutlined />,
    'setting': <SettingOutlined />,
    'star': <StarOutlined />
};

export const tagColors = ['pink', 'red', 'yellow', 'orange', 'cyan', 'green', 'blue', 'purple', 'geekblue', 'magenta', 'volcano', 'gold', 'lime'];

