import React from 'react';
import { z } from 'zod';
import { TelnyxVerticalTypes, TelynxEntityTypes, TelynxStockExchanges, TelynxUseCaseTypes } from '../constants';
import {
	isRestricted,
	TelnyxBrand,
	TelnyxBrandSchema,
	TelnyxGetBrand,
	TelnyxVerticalType,
	TelynxEntityType,
	TelynxStockExchange,
	TelynxUseCaseType,
} from '../types';
import utils from './utils';
import { validateURL } from './validation';
import { InfoBoxProps } from '@alpine-iq/ui-library';

export type ValidResponse = undefined;
export type InvalidResponse = Record<string, string>;
export type ValidationResult = ValidResponse | InvalidResponse;

export function validateTelynxBrandSettings(telnyxSettings: Partial<TelnyxBrand>): ValidationResult {
	const errors: InvalidResponse = {};

	try {
		// If entity type is for profit, require additional fields
		if (telnyxSettings.entityType === 'PUBLIC_PROFIT') {
			z.object({
				stockSymbol: z.string(),
				stockExchange: z.string().refine((value) => isValidTelnyxStockExchange(value as TelynxStockExchange), {
					message: 'Invalid stock exchange',
				}),
			}).parse(telnyxSettings);
		}

		// Perform validation
		TelnyxBrandSchema.parse(telnyxSettings);
	} catch (e) {
		if (e instanceof z.ZodError) {
			e.errors.forEach((error) => {
				errors[error.path.join('.')] = error.message;
			});
		} else if (e instanceof Error) {
			errors._ = e.message;
		}
	}

	return Object.keys(errors).length > 0 ? errors : undefined;
}

export function getDefaultTelnyxSettings(): Partial<TelnyxBrand> | undefined {
	if (isRestricted()) {
		return {
			vertical: 'RETAIL',
		};
	}
	return undefined;
}

export function isValidTelynxVerticalType(verticalType?: TelnyxVerticalType): boolean {
	if (!verticalType) return false;
	if (isRestricted() && verticalType !== 'RETAIL') {
		return false;
	}
	return !!TelnyxVerticalTypes?.[verticalType];
}

export function isValidTelnyxEntityType(entityType?: TelynxEntityType): boolean {
	if (!entityType) return false;
	return !!TelynxEntityTypes?.[entityType];
}

export function isValidTelnyxStockExchange(entityType?: TelynxStockExchange): boolean {
	if (!entityType) return false;
	return !!TelynxStockExchanges?.[entityType];
}

export function isValidTelnyxUseCase(entityType?: TelynxUseCaseType): boolean {
	if (!entityType) return false;
	return !!TelynxUseCaseTypes?.[entityType];
}

export function isValidWebsite(website?: string, email?: string): string[] | undefined {
	const errors = [];
	if (!website || !validateURL(website)) {
		errors.push('Invalid website URL. Please make sure to use http:// or https://');
		return errors;
	}

	const websiteDomain = new URL(website).hostname.replace(/^www\./, '');
	const emailDomain = email?.split('@')?.[1] ?? '';
	if (utils.strify(websiteDomain) !== utils.strify(emailDomain)) {
		errors.push('Website URL domain and email domain must match.');
	}

	return errors.length > 0 ? errors : undefined;
}

export function canSeeRegistrationForm(): boolean {
	if (utils.isDevUID() || localStorage.getItem('show-register-form')) return true;
	return false;
	const noInfoBip = !utils.user.infobipCmpID;
	return utils.isDevUID() || !noInfoBip;
}

type AlertInfo = {
	type: InfoBoxProps['type'];
	message: React.ReactNode;
};

export function getAlertInfoForTelynxBrandSettings(telnyxSettings: Partial<TelnyxGetBrand> | Partial<TelnyxBrand> | undefined): AlertInfo | undefined {
	const asGetBrand = (telnyxSettings ?? {}) as Partial<TelnyxGetBrand>;
	const status = asGetBrand?.status ?? undefined;

	// Cannabis operators get a special message about 10DLC registration prior to registration
	if (!status && utils.isCannabis()) {
		return {
			type: 'danger',
			message: (
				<>
					Cannabis operators: You may now register for 10DLC cannabis messaging within the AIQ system. We have received approval from carrier aggregators to use a
					specific age gated message delivery flow. AIQ has automated this flow so that you are cleanly transitioned to our new methodology. Previously saved
					campaigns will be set to “Inactive” status automatically and you will not be able to turn them on until you have edited the raw notice message within the
					campaign builder. Net new campaigns created will automatically force the required/ compliant setup.
				</>
			),
		};
	}

	if (status === 'REGISTRATION_FAILED') {
		const { failureReasons } = asGetBrand;
		return {
			type: 'danger',
			message: (
				<>
					Your information has been reviewed and did not meet the requirements for registration. Please review the information you submitted and make any necessary
					changes. If you have any questions, please contact support.
					{!!failureReasons?.length && (
						<>
							<br />
							<br />
							<strong>Reasons for failure:</strong>
							<ul style={{ marginLeft: '20px' }}>
								{failureReasons.map((reason, i) => (
									<li
										key={i}
										style={{ listStyleType: 'disc' }}
									>
										{reason}
									</li>
								))}
							</ul>
						</>
					)}
				</>
			),
		};
	}

	if (status === 'REGISTRATION_PENDING') {
		return {
			type: 'info',
			message: (
				<>
					Your information has been submitted for audit. Depending on your industry and use case this can take up to 7 days. However, in most cases you can expect
					sub 48 hour completion. We will notify you if your registration fails or information does not meet requirements. Otherwise, you will automatically be
					assigned a campaign registration ID and be able to create campaigns without interference.
				</>
			),
		};
	}

	if (status === 'OK') {
		return {
			type: 'success',
			message: (
				<>
					Your information has been approved. You can now create and send campaigns. Keep in mind that you are responsible for ensuring that your campaigns are
					compliant with all applicable laws and regulations.
				</>
			),
		};
	}

	return undefined;
}
