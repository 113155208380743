import { Avatar, Drawer, Space } from 'antd';
import React from 'react';

import { allStyles, colors, timeUtils, userUtils, utils } from '../../helpers';
import { Fees, FeesV2 } from '../../types';
import Break from '../../components/Break';
import FA from '../../components/global/FA';
import GTag from '../../components/global/GTag';
import GTooltip from '../../components/global/GTooltip';
import useMountedEffect from '../../hooks/useMountedEffect';

// TODO: Add this in future 

type WhiteList = { [key: string]: WhiteListEntry }
type ClientHacks = { [key: string]: ClientHack }

interface WhiteListEntry {
    enabled: boolean // Is the user enabled? (true/false)
    lastUpdated: number // timestamp of last update
    lastUpdater: string // email of user who last updated the whitelist
    startOn?: number // timestamp of when the user should be enabled
    endOn?: number // timestamp of when the user should be disabled
}

interface ClientHack {
    name: string // Name of the hack (basically key)
    description: string // Description of the hack (what it does)
    enabled: boolean // Is the hack enabled? (true/false) IF false, the hack is disabled for ALL clients even if whitelist is true

    whitelist: WhiteList // UID -> true/false
    restrictEnablers?: string[] // When enabled, only these user emails can enable the hack
}

// Example
const clientHacks: ClientHacks = {
    'mms-only-campaigns': {
        name: 'mms-only-campaigns',
        description: 'Allow MMS-only campaigns within the campaign builder (text template)',
        enabled: true,
        whitelist: {
            '1034': {
                enabled: true,
                lastUpdated: 1629321600,
                lastUpdater: 'admin@alpineiq.com'
            }
        }
    }
}


export default function CH({ uid = utils.uid }) {
    const [visible, setVisible] = React.useState(false)
    const [sections, setSections] = React.useState({
        client: false,
        account: false,
        settings: false,
        billing: false,
        clientHacks: false,
    })

    const user = utils.user;

    const isLegacyBilling = userUtils.hasLegacyBilling();
    const fees = userUtils.getUserFees();
    const feesV1 = (fees as Fees)
    const feesV2 = (fees as FeesV2)

    useMountedEffect(() => {

    }, [uid])

    const handleCancel = () => {
        setVisible(false)
    }

    if (!uid || uid === '1') {
        return <></>
    }

    return (<>

        <Drawer
            open={visible}
            onClose={handleCancel}
            placement="right"
            contentWrapperStyle={{ width: `55%`, borderLeft: '1px dashed #C4C5CC' }}
            closable={false}
            headerStyle={{ padding: '0px 24px', borderBottom: 'none' }}
            bodyStyle={{ ...utils.noPadding, }}
            title={<>
                <div style={{ margin: '24px 0px', }}>

                </div>

                <div style={{ display: 'flex', marginBottom: 32, justifyContent: 'space-between', }}>
                    <div style={{ flex: 1 }}>
                        <Space align='center'>
                            <Avatar size={32} src={`https://lab.alpineiq.com/avatar/${1034}`} shape="square" style={{ borderRadius: 6, ...allStyles.shadows.darkShadowHover() }} />
                            <span style={{ fontWeight: 600, fontSize: '1.5rem' }}>ADMIN PANEL</span>
                        </Space>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Space wrap>
                        </Space>
                    </div>
                </div>

                <div style={{ margin: '24px 0px', }}>
                    <GTag background={colors.primary.error500} color='#fff' style={{ borderRadius: 0, fontSize: '.625rem', fontWeight: 600, padding: '0px 15px' }}>
                        Admin Panel
                    </GTag>
                </div>

                <Break />

                <div style={{ margin: '24px 0px', }}>
                </div>

                <div style={{ display: 'flex', marginBottom: 32, justifyContent: 'space-between', }}>
                    <div style={{ flex: 1 }}>
                        <Space align='center'>
                            <Avatar size={32} src={`https://lab.alpineiq.com/avatar/${uid}`} shape="square" style={{ borderRadius: 6, ...allStyles.shadows.darkShadowHover() }} />
                            <span style={{ fontWeight: 600, fontSize: '1.5rem' }}>{`${user?.profile?.name?.toUpperCase()} (${user?.id})`}</span>
                        </Space>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Space wrap>
                        </Space>
                    </div>
                </div>

                <div style={{ margin: '24px 0px', }}>
                    <div style={{ cursor: 'pointer' }} onClick={() => setSections(s => ({ ...s, client: !s.client }))}>
                        <Space>
                            <GTag background={colors.primary.blue} color='#fff' style={{ borderRadius: 0, fontSize: '.625rem', fontWeight: 600, padding: '0px 15px' }}>
                                Client Information
                            </GTag>
                            <FA
                                style={{ fontSize: '.75rem', cursor: 'pointer', }}
                                name={sections.client ? 'chevron-down' : 'chevron-right'}
                            />
                        </Space>
                    </div>
                </div>

                {(sections.client) && <>
                    <div style={{ margin: '24px 0px', }}>
                        <div style={{ display: 'flex', gap: 32, flexWrap: 'wrap' }}>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <DisplayItem
                                    title='Client Name' value={user?.profile?.name}
                                    tooltip={`The client's name`}
                                />
                                <DisplayItem
                                    title='Client Email' value={user?.profile?.email}
                                    tooltip={`The client's email`}
                                />
                                <DisplayItem
                                    title='Client Phone' value={user?.profile?.phone}
                                    tooltip={`The client's phone number`}
                                />
                                <DisplayItem
                                    title='Website' value={user?.profile?.website}
                                    tooltip={`The client's website`}
                                />
                                <DisplayItem
                                    title='Industry' value={user?.profile?.industry}
                                    tooltip={`The client's industry`}
                                />
                                <DisplayItem
                                    title='Subtype' value={user?.profile?.subType}
                                    tooltip={`The client's subtype`}
                                />
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <DisplayItem
                                    title='Is Active' value={user?.active || false}
                                    tooltip={`If "Yes", the client is active. If "No", the client is inactive.`}
                                />
                                <DisplayItem
                                    title='Is Probation' value={user?.probation || false}
                                    tooltip={`If "Yes", the client is on probation. If "No", the client is not on probation.`}
                                />
                                <DisplayItem
                                    title='Invoice Freeze' value={user?.invoiceFreeze || false}
                                    tooltip={`If "Yes", the client is on invoice freeze. If "No", the client is not on invoice freeze.`}
                                />
                                <DisplayItem
                                    title='Archived' value={user?.archived || false}
                                    tooltip={`If "Yes", the client is archived. If "No", the client is not archived.`}
                                />
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <DisplayItem
                                    title='Created' value={timeUtils.formatTimestamp(user?.created)}
                                    tooltip={`The date the client was created`}
                                />
                                <DisplayItem
                                    title='Last Updated' value={timeUtils.formatTimestamp(user?.lastUpdated)}
                                    tooltip={`The date the client was last updated`}
                                />
                                <DisplayItem
                                    title='Last Login' value={timeUtils.formatTimestamp(user?.lastAccess)}
                                    tooltip={`The date the client last logged in`}
                                />
                                <DisplayItem
                                    title='Last Capture' value={timeUtils.formatTimestamp(user?.lastCapture)}
                                    tooltip={`The date the client last captured`}
                                />
                                <DisplayItem
                                    title='Last Rebirth' value={timeUtils.formatTimestamp(user?.lastRebirth)}
                                    tooltip={`The date the client last rebirthed`}
                                />
                                <DisplayItem
                                    title='Last Audience' value={timeUtils.formatTimestamp(user?.lastAudienceRun)}
                                    tooltip={`The date the client last ran an audience`}
                                />
                            </div>
                        </div>
                    </div>
                </>}

                {/* <div style={{ margin: '24px 0px', }}>
                    <Space>
                        <GTag background={colors.primary.blue} color='#fff' style={{ borderRadius: 0, fontSize: '.625rem', fontWeight: 600, padding: '0px 15px' }}>
                            Account Information
                        </GTag>
                        <FA
                            style={{ fontSize: '.75rem', cursor: 'pointer', }}
                            name={sections.account ? 'chevron-down' : 'chevron-right'}
                            onClick={() => setSections(s => ({ ...s, account: !s.account }))}
                        />
                    </Space>
                </div>

                {(sections.account) && <>
                    <div style={{ margin: '24px 0px', }}>
                        <div style={{ display: 'flex', gap: 32, flexWrap: 'wrap' }}>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <DisplayItem
                                    title='Anonymous loyalty' value={!user.disableAnonLoyalty || false}
                                    tooltip='IF "No", anonymous loyalty members. In order for a persona to be considered a loyalty member, they must have PII'
                                />
                                <DisplayItem
                                    title='Has Dynamic Content' value={user?.hasDynamicContent || false}
                                    tooltip='If "Yes", the client has dynamic content enabled. If "No", the client does not have dynamic content enabled.'
                                />
                                <DisplayItem
                                    title='Walled Garden' value={!user.disableWalledGarden || false}
                                    tooltip={`If "No", the client is not behind a walled garden. If "Yes", the client is behind a walled garden.`}
                                />
                                <DisplayItem
                                    title='Message DOB' value={user.messageDOB || false}
                                    tooltip={`Require personas confirm their age before viewing texted links? (Default for Bev/ alcohol)`}
                                />
                                <DisplayItem
                                    title='Message 2FA' value={user.message2FA || false}
                                    tooltip={`Require personas to enter a one-time PIN code per device before accessing the landing page?`}
                                />
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <DisplayItem
                                    title='Is MMS Logo' value={user.mmsLogo || false}
                                    tooltip={`If "Yes", the client is sending MMS. If "No", the client is sending SMS.`}
                                />
                                <DisplayItem
                                    title='Implicit Email Opt-in' value={!user.disableImplicitEmail || false}
                                    tooltip={`If "No", the client is not requiring email opt-in. If "Yes", the client is requiring email opt-in.`}
                                />
                                <DisplayItem
                                    title='Allow Ads' value={user.allowAds || false}
                                    tooltip='Allow ads to be sent to personas'
                                />
                                <DisplayItem
                                    title='Allow Banned' value={user.allowBanned || false}
                                    tooltip='Allow T-Mobile/ Sprint texting'
                                />
                                <DisplayItem
                                    title='Block Adjustments' value={!user.disableBlockAdjustments || false}
                                    tooltip='If "No", the client is not allowing block adjustments. If "Yes", the client is allowing block adjustments.'
                                />
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <DisplayItem
                                    title='Name DOB Merge' value={user.nameDOBMerge || false}
                                    tooltip='If "Yes", the client is merging name and DOB. If "No", the client is not merging name and DOB.'
                                />
                                <DisplayItem
                                    title='New Web Wallet' value={user.newWebWallet || false}
                                    tooltip='If "Yes", the client is using the new web wallet. If "No", the client is not using the new web wallet.'
                                />
                                <DisplayItem
                                    title='Microsoft SSO' value={user.microsoft?.enabled || false}
                                    tooltip='If "Yes", the client is using Microsoft SSO. If "No", the client is not using Microsoft SSO.'
                                />
                                <DisplayItem
                                    title='Google SSO' value={user.google?.enabled || false}
                                    tooltip='If "Yes", the client is using Google SSO. If "No", the client is not using Google SSO.'
                                />
                            </div>
                        </div>
                    </div>
                </>} */}

                <div style={{ margin: '24px 0px', }}>
                    <div style={{ cursor: 'pointer' }} onClick={() => setSections(s => ({ ...s, settings: !s.settings }))}>
                        <Space>
                            <GTag background={colors.primary.blue} color='#fff' style={{ borderRadius: 0, fontSize: '.625rem', fontWeight: 600, padding: '0px 15px' }}>
                                Important settings
                            </GTag>
                            <FA
                                style={{ fontSize: '.75rem', cursor: 'pointer', }}
                                name={sections.settings ? 'chevron-down' : 'chevron-right'}
                            />
                        </Space>
                    </div>
                </div>

                {(sections.settings) && <>
                    <div style={{ margin: '24px 0px', }}>
                        <div style={{ display: 'flex', gap: 32, flexWrap: 'wrap' }}>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <DisplayItem
                                    title='Anonymous loyalty' value={!user.disableAnonLoyalty || false}
                                    tooltip='IF "No", anonymous loyalty members. In order for a persona to be considered a loyalty member, they must have PII'
                                />
                                <DisplayItem
                                    title='Has Dynamic Content' value={user?.hasDynamicContent || false}
                                    tooltip='If "Yes", the client has dynamic content enabled. If "No", the client does not have dynamic content enabled.'
                                />
                                <DisplayItem
                                    title='Walled Garden' value={!user.disableWalledGarden || false}
                                    tooltip={`If "No", the client is not behind a walled garden. If "Yes", the client is behind a walled garden.`}
                                />
                                <DisplayItem
                                    title='Message DOB' value={user.messageDOB || false}
                                    tooltip={`Require personas confirm their age before viewing texted links? (Default for Bev/ alcohol)`}
                                />
                                <DisplayItem
                                    title='Message 2FA' value={user.message2FA || false}
                                    tooltip={`Require personas to enter a one-time PIN code per device before accessing the landing page?`}
                                />
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <DisplayItem
                                    title='Is MMS Logo' value={user.mmsLogo || false}
                                    tooltip={`If "Yes", the client is sending MMS. If "No", the client is sending SMS.`}
                                />
                                <DisplayItem
                                    title='Implicit Email Opt-in' value={!user.disableImplicitEmail || false}
                                    tooltip={`If "No", the client is not requiring email opt-in. If "Yes", the client is requiring email opt-in.`}
                                />
                                <DisplayItem
                                    title='Allow Ads' value={user.allowAds || false}
                                    tooltip='Allow ads to be sent to personas'
                                />
                                <DisplayItem
                                    title='Allow Banned' value={user.allowBanned || false}
                                    tooltip='Allow T-Mobile/ Sprint texting'
                                />
                                <DisplayItem
                                    title='Block Adjustments' value={!user.disableBlockAdjustments || false}
                                    tooltip='If "No", the client is not allowing block adjustments. If "Yes", the client is allowing block adjustments.'
                                />
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                <DisplayItem
                                    title='Name DOB Merge' value={user.nameDOBMerge || false}
                                    tooltip='If "Yes", the client is merging name and DOB. If "No", the client is not merging name and DOB.'
                                />
                                <DisplayItem
                                    title='New Web Wallet' value={user.newWebWallet || false}
                                    tooltip='If "Yes", the client is using the new web wallet. If "No", the client is not using the new web wallet.'
                                />
                                <DisplayItem
                                    title='Microsoft SSO' value={user.microsoft?.enabled || false}
                                    tooltip='If "Yes", the client is using Microsoft SSO. If "No", the client is not using Microsoft SSO.'
                                />
                                <DisplayItem
                                    title='Google SSO' value={user.google?.enabled || false}
                                    tooltip='If "Yes", the client is using Google SSO. If "No", the client is not using Google SSO.'
                                />
                            </div>
                        </div>
                    </div>
                </>}

                <div style={{ margin: '24px 0px', }}>
                    <div style={{ cursor: 'pointer' }} onClick={() => setSections(s => ({ ...s, clientHacks: !s.clientHacks }))}>
                        <Space>
                            <GTag background={colors.primary.error500} color='#fff' style={{ borderRadius: 0, fontSize: '.625rem', fontWeight: 600, padding: '0px 15px' }}>
                                Client Hacks
                            </GTag>
                            <FA
                                style={{ fontSize: '.75rem', cursor: 'pointer', }}
                                name={sections.clientHacks ? 'chevron-down' : 'chevron-right'} />
                        </Space>
                    </div>
                </div>

                {(sections.clientHacks) && <>
                    <div style={{ margin: '24px 0px', }}>

                    </div>
                </>}


                <Break />
            </>}
        >

        </Drawer>


    </>)
}

const DisplayItem = ({ title, value, ...props }: {
    title: string,
    value: any,
    tooltip?: string | React.ReactNode,
}) => {

    let showValue = value === undefined ? 'N/A' : value
    const isBoolean = typeof value === 'boolean'
    if (isBoolean) {
        showValue = value ? 'Yes' : 'No'
    }

    return (<GTooltip tooltip={props.tooltip} placement='topRight'>
        <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'space-between', gap: 8, padding: '2px 0px' }}>
            <span style={{ fontWeight: 600, fontSize: '.875rem' }}>{title}</span>
            <GTag
                size='small'
                style={{ fontSize: '.875rem', }}
            >
                <Space>
                    {isBoolean && <FA name={value ? 'check' : 'xmark'} />}
                    {showValue}
                </Space>
            </GTag>
        </div>
    </GTooltip>)
}
