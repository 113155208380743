import React from 'react';

import utils from '../helpers/utils';

export default function updateHS(user: any) {
	const _hsq = (window as any)._hsq || [];

	if (location.pathname.toLowerCase().includes('walletsearch')) {
		return
	}

	const bannedUIDS = ['1', '0',];

	if (!_hsq || !user || !user.id || bannedUIDS.includes(user.uid)) return;

	_hsq.push(['identify', {
		email: utils.auth.getEmail(),
		company_name: user?.profile?.name,
		uid: user.id,
	}]);

	_hsq.push(['trackPageView']);
}
