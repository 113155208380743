import { InputNumber, Select, Space } from 'antd';
import React from 'react';

import { UAClickType } from '../../../api/userAlerts';
import Break from '../../../components/Break';
import FA from '../../../components/global/FA';
import GPop from '../../../components/global/GPop';
import GTooltip from '../../../components/global/GTooltip';
import ImageWithBackground from '../../../components/global/ImageWithBackground';
import { formatUserAlertLink, getAcknowledgement, submitUserAlertAction } from '../../../helpers';
import { colors } from '../../../helpers/allStyles';
import { timeUtils } from '../../../helpers/timeUtils';
import utils from '../../../helpers/utils';
import { AlertsContextProvider, DrawerStateContextProvider } from '../../../hooks/useContext';
import { Acknowledgement, AlertsContext, DrawerStateContext, UserAlert, UserAlertPartition } from '../../../types';
import { defaultColorsByAlertType, defaultIconByAlertType } from '../alerts.helpers';


const tooHighMapping = {
	MINUTE_S: 60,
	HOUR_S: 24,
	DAY_S: 7,
	WEEK_S: 4,
	MONTH_S: 12,
	YEAR_S: 2,
}

export function AlertMessage({ ...props }: {
	alert: UserAlert;
	preview?: boolean
	noButtons?: boolean
	noLayout?: boolean
}) {

	const drawerContext = React.useContext(DrawerStateContextProvider) as DrawerStateContext;
	const context = React.useContext(AlertsContextProvider) as AlertsContext;
	const [customSnooze, setCustomSnooze] = React.useState<{
		open: boolean;
		value: number;
		unit: keyof typeof tooHighMapping;
	}>({
		open: false,
		value: 15,
		unit: 'MINUTE_S',
	});
	const [showSnoozePopup, setShowSnoozePopup] = React.useState<boolean>(false);

	const alert = props.alert

	if (alert.type !== 'recipe' && alert.prefix?.value?.startsWith('Co-Marketing Update from')) alert.type = 'recipe'

	const alertStatus: Acknowledgement | undefined = getAcknowledgement(alert, utils.uid, utils.auth.getEmail())
	const icon = alert.faIcon || {}
	const prefix = alert.prefix || {}
	const text = alert.text || {}
	const suffix = alert.suffix || {}
	const hover = alert.hover || {}
	const timestamp = alert.timestamp || {}

	// update default color
	const defaultColor = defaultColorsByAlertType[alert.type || 'default']
	const color = alert.color || defaultColor

	// update default icon
	const defaultIcon = defaultIconByAlertType[alert.type || 'default']
	if (!icon?.value) icon.value = defaultIcon

	// base style applied to all partitions
	const baseStyle = {} as React.CSSProperties
	// base style by partition
	const baseStyles = {
		icon: { fontSize: '.75rem' },
		prefix: { fontSize: '.925rem' },
		text: { fontSize: '.875rem' },
		suffix: { fontSize: '.75rem' },
		hover: {},
		timestamp: {},
	}

	const getImage = () => {
		let image = undefined
		try {
			image = require(`../../../assets/imgs/${customImageURL}`)
		} catch (err) {
			console.log('err', err)
		}
		return image
	}

	const hasCustomImage = alert?.text?.value?.includes('::customImage::')
	const customImageURL = alert?.text?.value?.replace('::customImage::', '')
	const customImage = hasCustomImage && !customImageURL?.includes('https://') ? getImage() : customImageURL

	// wrap link if needed
	const LinkWrap = ({ children, partition, clickType }: { children: any, partition: UserAlertPartition, clickType: UAClickType | '' }) => {
		if (!partition.link || alert.featureHighlight) return children
		const link = formatUserAlertLink(partition?.link || '')

		return <a
			className='linked'
			href={link}
			target='_blank'
			style={{ textDecoration: 'none', cursor: 'pointer', color: partition.color }}
			onClick={async (ev) => {
				ev.preventDefault();
				if (clickType) await submitUserAlertAction(context, alert, { action: 'acknowledge', clickType }, props.noLayout)
				window.open(link, '_blank');
			}}
		>
			{children}
		</a>
	}

	const isEmptyAlert = !icon?.value && !prefix?.value && !text?.value && !suffix?.value

	if (isEmptyAlert && props.preview) {
		return <>
			<div style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				padding: 16,
				border: `1px dashed  ${colors.text.lighter()}`,
				borderRadius: 6,
			}}>
				<span style={{ color: colors.text.lowAlpha() }}>
					Preview will appear after content is added
				</span>
			</div>
		</>
	}

	const featureLink = alert.featureHighlight || alert.type === 'feature-highlight' ? alert?.text?.link : ''
	const viewLink = formatUserAlertLink(alert.link || alert?.suffix?.link || featureLink)
	const customEndDuration = customSnooze.value * (timeUtils.units as any)[customSnooze?.unit || ''];
	const customEndDate = customEndDuration ? new Date((new Date().getTime()) + (customEndDuration * 1000)) : 0;
	const formattedDate = timeUtils.formatTimestamp(customEndDate)
	const thumbnailImage = alert.thumbnailImage

	const showAvatar = (alert.type === 'recipe' && alert.avatar)
	const wrapperStyle: React.CSSProperties = {
		cursor: alert.featureHighlight ? 'pointer' : 'default',
	}
	const hasAcknowledged = alertStatus && alertStatus.acknowledged

	const whiteListedEmails = [
		'admin@alpineiq.com',
		'brynner.doyle@alpineiq.com'
	]

	const itemStyle: React.CSSProperties = {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		columnGap: 16,
		cursor: 'pointer',
		borderRadius: 6,
		padding: `2px 4px`
	}

	const onClickAlert = () => {
		if (!alert.featureHighlight) return
		drawerContext.setFeatureHighlightDrawerOpen(alert.id)
		drawerContext.setNotificationDrawerOpen(false)
	}

	return <>
		<GPop
			width={400}
			borderLeft={hover?.color || color}
			disabled={!hover?.value}
			placement='left'
			content={<>
				<LinkWrap clickType='' partition={hover}>
					<GTooltip placement='topRight' tooltip={hover?.tooltip}>
						<div
							style={{
								color: hover?.color, ...baseStyle, ...hover?.style,
								wordBreak: 'break-word',
								overflowWrap: 'break-word',
								whiteSpace: 'pre-wrap',
							}}
							dangerouslySetInnerHTML={{ __html: hover?.value || '' }}
						/>
					</GTooltip>
				</LinkWrap>
			</>}

		>
			<div style={{ display: 'flex', gap: 6, minWidth: '100%', ...wrapperStyle }} onClick={onClickAlert}>
				{!!thumbnailImage ? <>
					<div style={{ display: 'flex', alignItems: 'center', paddingRight: 8 }}>
						<LinkWrap clickType='' partition={{ link: viewLink, color: 'inherit' }}>
							<GTooltip placement='topRight' tooltip={alert?.text?.tooltip}>
								<img style={{ borderRadius: 6, height: 56, width: 56 }} src={thumbnailImage} />
							</GTooltip>
						</LinkWrap>
					</div>
				</> : <>
					<div>
						{(!!icon?.value && icon.value !== 'none') && <>
							<LinkWrap clickType='' partition={icon}>
								<GTooltip placement='topRight' tooltip={icon?.tooltip}>
									{(!showAvatar) && <i className={`${icon?.value}`} style={{ color: icon?.color || color, ...baseStyle, ...baseStyles.icon, ...icon?.style, }} />}
									{(showAvatar) && <ImageWithBackground style={{ borderRadius: 33, height: 24, width: 24 }} src={alert.avatar || ''} />}
								</GTooltip>
							</LinkWrap>
						</>}
					</div>
				</>}
				<div style={{ flex: 1 }}>

					{!!prefix?.value && <>
						<div style={{ display: 'flex', justifyContent: 'space-between', minWidth: '100%' }}>
							<div>
								<LinkWrap clickType='prefix' partition={prefix}>
									<GTooltip placement='topLeft' tooltip={prefix?.tooltip}>
										<span>
											<div style={{ color: prefix?.color || color, ...baseStyle, ...baseStyles.prefix, ...prefix?.style, }}
												dangerouslySetInnerHTML={{ __html: prefix?.value || '' }}
											/>
										</span>
									</GTooltip>
								</LinkWrap>
							</div>
							{(!props?.noButtons) && <div style={{ color: '#939399' }}>
								<Space size={10}>
									{!!viewLink && <>
										<LinkWrap partition={{ link: viewLink, color: 'inherit' }} clickType='link'>
											<GTooltip placement='topRight' tooltip={hasAcknowledged ? 'View content again' : 'Click to view content and mark the notification as read'}>
												<i className={`fa-regular fa-${alert.type === 'feature-highlight' ? 'arrow-up-right-from-square' : 'eye'} ${hasAcknowledged ? 'linked-hover' : 'linked'}`} style={{}} />
											</GTooltip>
										</LinkWrap>
									</>}

									{(alert.type !== 'feature-highlight' && !alert.featureHighlight) && <>
										<GPop
											open={showSnoozePopup}
											setOpen={setShowSnoozePopup}
											placement='bottomRight'
											style={{ ...utils.noPadding }}
											overlayInnerStyle={{ ...utils.noPadding }}
											rawProps={{ overlayStyle: { ...utils.noPadding }, }}
											content={<>
												<div style={{ display: 'flex', justifyContent: 'center', padding: `0px 0px 6px 0px`, }}>
													<b>Snooze notification</b>
												</div>
												<div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
													<div
														className='errorHover hover-menu-item-bad'
														onClick={() => {
															if (!showSnoozePopup) return
															setShowSnoozePopup(false)
															submitUserAlertAction(context, alert, { action: 'snooze', snoozeDuration: timeUtils.units.DAY_S }, props.noLayout)
														}}
														style={itemStyle}
													>
														{'Remind me in 24 hours'} <FA name="alarm-clock" />
													</div>
													<div
														className='errorHover hover-menu-item-bad'
														onClick={() => {
															if (!showSnoozePopup) return
															setShowSnoozePopup(false)
															submitUserAlertAction(context, alert, { action: 'snooze', snoozeDuration: timeUtils.units.WEEK_S }, props.noLayout)
														}}
														style={itemStyle}
													>
														{'Dismiss for a week'} <FA name="clock" />
													</div>
													<div
														className='errorHover hover-menu-item-bad'
														onClick={() => {
															setShowSnoozePopup(false)
															submitUserAlertAction(context, alert, { action: 'snooze', snoozeDuration: -1 }, props.noLayout)
														}}
														style={itemStyle}
													>
														{`Don't remind me again`} <FA name="bell-slash" />
													</div>
													{(whiteListedEmails.includes(utils.auth.getEmail())) && <>
														<GPop
															popConfirm
															width={400}
															overlayInnerStyle={{ borderTop: `3px solid ${colors.primary.primary}` }}
															placement='topRight'
															zIndex={99999}
															mouseLeaveDelay={1}
															onConfirm={() => {
																if (!customEndDuration || tooHighMapping[customSnooze.unit] < customSnooze.value || customSnooze.value < 1) {
																	return utils.showErr('Please select a valid duration')
																}
																if (!showSnoozePopup) return
																setShowSnoozePopup(false)
																submitUserAlertAction(context, alert, { action: 'snooze', snoozeDuration: customEndDuration }, props.noLayout);
																setCustomSnooze({ ...customSnooze, open: false, value: 15, unit: 'MINUTE_S', })
															}}
															content={<>
																<Space>
																	Snooze for
																	<InputNumber
																		style={{ borderRadius: 6 }}
																		value={customSnooze.value}
																		onChange={(value) => setCustomSnooze({ ...customSnooze, value })}
																	/>
																	<Select
																		dropdownStyle={{ zIndex: 9999999 }}
																		value={customSnooze.unit}
																		onChange={(unit) => setCustomSnooze({ ...customSnooze, unit })}
																		options={[
																			// { label: 'Seconds', value: 'SECOND_S' },
																			{ label: 'Minutes', value: 'MINUTE_S' },
																			{ label: 'Hours', value: 'HOUR_S' },
																			{ label: 'Days', value: 'DAY_S' },
																			{ label: 'Weeks', value: 'WEEK_S' },
																			{ label: 'Months', value: 'MONTH_S' },
																			{ label: 'Years', value: 'YEAR_S' },
																		]}
																	/>
																</Space>
																<Break air />
																<span style={{ color: colors.text.lighterer() }}><i className='fa-light fa-calendar-alt' style={{ marginRight: 4 }} /> {' '} {formattedDate}</span>
															</>}
														>
															<div
																className='errorHover hover-menu-item-bad'
																style={itemStyle}
															>
																{`Custom duration`} <FA name="alarm-snooze" />
															</div>
														</GPop>
													</>}
												</div>
											</>}
										>
											<div>
												<FA name="xmark-large dark-hover" />
											</div>
										</GPop>
									</>}
								</Space>
							</div>}
						</div>
					</>}

					<div>
						{(hasCustomImage || !!text?.value) && <>
							<LinkWrap clickType='text' partition={text}>
								<GTooltip placement='topLeft' tooltip={text?.tooltip}>
									{(!hasCustomImage) && <>
										<span>
											<div
												style={{
													color: text?.color, ...baseStyle, ...baseStyles.text, ...text?.style,
													wordBreak: 'break-word',
													overflowWrap: 'break-word',
													whiteSpace: 'pre-wrap',
												}}
												dangerouslySetInnerHTML={{ __html: text?.value || '' }}
											/>
										</span>
									</>}
									{hasCustomImage && <div style={{ paddingTop: 4 }}>
										<img
											src={customImage}
											style={{ maxWidth: '100%', ...baseStyle, ...baseStyles.text, ...text?.style, }}
										/>
									</div>}
								</GTooltip>
							</LinkWrap>
						</>}
					</div>

					<div>
						{(!!suffix?.value && !hasAcknowledged) && <>
							<LinkWrap clickType='suffix' partition={suffix}>
								<GTooltip placement='topLeft' tooltip={suffix?.tooltip}>
									<span>
										<div style={{ color: suffix?.color, ...baseStyle, ...baseStyles.suffix, ...suffix?.style, }}
											dangerouslySetInnerHTML={{ __html: suffix?.value || '' }}
										/>
									</span>
								</GTooltip>
							</LinkWrap>
						</>}
					</div>

					<div>
						{!!timestamp?.value && <>
							<LinkWrap clickType='' partition={timestamp}>
								<GTooltip placement='topLeft' tooltip={timestamp?.tooltip}>
									<span>
										<div style={{ fontSize: '.75rem', color: timestamp?.color, ...baseStyle, ...baseStyles.timestamp, ...timestamp?.style, }}
											dangerouslySetInnerHTML={{ __html: timeUtils.formatTimestamp(timestamp?.value) }}
										/>
									</span>
								</GTooltip>
							</LinkWrap>
						</>}
					</div>

				</div>
			</div>
		</GPop >
	</>
}
