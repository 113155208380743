import React from 'react'
import MediaSelector from '../../../../mediaSelector/MediaSelector'
import Break from '../../../../Break'
import HoverQuestion from '../../../../HoverQuestion'
import { hoverStyle } from '../StylingTab'
import { AppSettingsTabProps } from '../../NativeAppSettings'
import { Row, Space, Switch } from 'antd'
import AppSettingsStoreSelector from '../../components/StoreSelector'
import userUtils from '../../../../../helpers/userUtils'

const restrictTypes = {
    'image/apng': false,
    'image/avif': false,
    'image/gif': false,
    'image/png': true,
    'image/jpeg': false,
    'image/svg+xml': false,
    'image/webp': false,
}
export default function BrandingTab({ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }: AppSettingsTabProps) {

    const hasEnterpriseApp = userUtils.hasPackageTier('nativeApps', 'enterprise')

    return (<>
        <AppSettingsStoreSelector {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, ...props }} />
        <Row style={{ justifyContent: 'space-between', paddingTop: 10, marginBottom: 30 }}>
            <div style={{ width: '30%' }}>
                <span style={{ color: '#8c8c8c' }}>Header Logo </span>
                <HoverQuestion style={hoverStyle} title="The logo shown at the top of app pages. Max Size Allowed: 800KB" />

                <Break air />
                <MediaSelector
                    style={{ width: 210, height: 140 }}
                    {...ibnd('brand/logo', '', state.storeID != '-1')}
                    bordered
                    dragAndDrop
                    allowDelete

                    config={{
                        type: 'image',
                        maxSize: 800,
                        restrictTypes,
                        maxDimensions: { width: 600, height: 600 },
                    }}
                />
            </div>
            <div style={{ width: '30%' }}>
                <span style={{ color: '#8c8c8c' }}>Cover Photo </span>
                <HoverQuestion style={hoverStyle} title="Cover photo for the top of app pages which appears behind your logo. Max Size Allowed: 800KB" />

                <Break air />
                <MediaSelector
                    style={{ width: 210, height: 140 }}
                    {...ibnd('brand/cover', '', state.storeID != '-1')}
                    bordered
                    dragAndDrop
                    allowDelete

                    config={{
                        type: 'image',
                        maxSize: 800,
                        restrictTypes,
                    }}
                />
            </div>
            <div style={{ width: '30%' }}>
                <span style={{ color: '#8c8c8c' }}>Tab Background Photo </span>
                <HoverQuestion style={hoverStyle} title="The background image of the app's tabs. This icon must be a 1080x1920 .PNG file without a TRANSPARENCY channel. Max Size Allowed: 1.5MiB" />

                <Break air />
                <MediaSelector
                    style={{ width: 210, height: 140 }}
                    {...ibnd('brand/bgImage', '', state.storeID != '-1')}
                    bordered
                    dragAndDrop
                    allowDelete

                    config={{
                        type: 'image',
                        maxSize: 800,
                        restrictTypes,
                        maxDimensions: { width: 1080, height: 1920 },
                    }}
                />
            </div>
        </Row>
        <div style={{ marginBottom: 15 }}>
            <b style={{ color: '#7B7B80' }}>Not available for preview:</b>
        </div>
        <Row style={{ justifyContent: 'space-between', marginBottom: 30 }}>
            <div style={{ width: '30%' }}>
                <span style={{ color: '#8c8c8c' }}>Splash Loading Image </span>
                <HoverQuestion style={hoverStyle} title="A full screen 'loading' image that is shown to the user during app loading. Max Size Allowed: 800KB" />

                <Break air />
                <MediaSelector
                    tooltip={!hasEnterpriseApp ? 'Requires Enterprise APP' : ''}
                    disabled={!hasEnterpriseApp}
                    style={{ width: 210, height: 140 }}
                    {...ibnd('brand/splash', '', state.storeID != '-1')}
                    bordered
                    dragAndDrop
                    allowDelete

                    config={{
                        type: 'image',
                        maxSize: 800,
                        restrictTypes,
                        maxDimensions: { width: 1356, height: 2934 },
                    }}
                />
            </div>
            <div style={{ width: '30%' }}>
                <span style={{ color: '#8c8c8c' }}>Splash Fallback Image </span>
                <HoverQuestion style={hoverStyle} title="A full screen image to replace the white image before the splash loading image if splash doesn't load. Max Size Allowed: 800KB" />

                <Break air />
                <MediaSelector
                    tooltip={!hasEnterpriseApp ? 'Requires Enterprise APP' : ''}
                    disabled={!hasEnterpriseApp}
                    style={{ width: 210, height: 140 }}
                    {...ibnd('brand/loading', '', state.storeID != '-1')}
                    bordered
                    dragAndDrop
                    allowDelete

                    config={{
                        type: 'image',
                        maxSize: 800,
                        restrictTypes,
                        maxDimensions: { width: 1356, height: 2934 },
                    }}

                />
            </div>
            <div style={{ width: '30%' }}></div>{/* placeholder to maintain spacing */}
        </Row>
        <div style={{ padding: '10px', cursor: 'pointer', display: 'flex', justifyContent: 'space-between' }} >
            <Space>
                <b style={{ fontWeight: 600 }}>OPTIONS</b>
            </Space>
        </div>
        <Row style={{ justifyContent: 'space-between', marginBottom: 30 }}>
            <div style={{
                padding: 10,
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gridGap: 20,
            }}>
                <div style={{ gridColumn: 'span 1' }}>
                    <Space>
                        <Switch size='small' style={{ minWidth: 36 }} {...bbnd('hideCoverAvatarLogo', false, state.storeID != '-1')} />
                        <span style={{ color: '#8c8c8c', marginRight: 5, marginLeft: 5 }}>Hide header logo photo</span>
                        <HoverQuestion style={hoverStyle} title="Hides the header logo photo which is centered inside the cover photo on the login page, and within the app." />
                    </Space>
                </div>
            </div>
        </Row>
    </>)
}
