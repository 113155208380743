import React, { lazy } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import '../components/navbar/FooterNav';
import '../components/navbar/LeftNavbar';
import useAuthentication from '../hooks/useAuthentication';
// import Home from './Home';
import history from '../history';
import utils from '../helpers/utils';

const EcomHome = lazy(() => import('../pages/ecom/EcomHome'));
const GlobalStyles = lazy(() => import('../components/global/GlobalStyles'));
const Login = lazy(() => import('./Login'));
const Logout = lazy(() => import('./Logout'));
const Admin = lazy(() => import('../pages/admin/AdminHomePage'));
const Search = lazy(() => import('./Search'));
const Agency = lazy(() => import('./Agency'));
const Integrations = lazy(() => import('../pages/integrations/IntegrationsPage'));
const ManageAlerts = lazy(() => import('../pages/alerts/ManageAlerts'));
const MarketingTemplates = lazy(() => import('../pages/recipes/TemplatesPage'));
const Audiences = lazy(() => import('./Audiences'));
const LegacyDiscountsManager = lazy(() => import('./Discounts'));
const LegacyDiscountBuilder = lazy(() => import('./DiscountBuilder'));
const DiscountBuilder = lazy(() => import('../pages/discounts/builder/DiscountBuilder'));
const DiscountManager = lazy(() => import('../pages/discounts/manage/DiscountManager'));
const CampaignManager = lazy(() => import('../pages/campaigns/manage/CampaignsManager'));
const CampaignReports = lazy(() => import('../pages/campaigns/reports/CampaignReports'));
const CampaignBuilder = lazy(() => import('../pages/campaigns/builder/CampaignBuilder'));
const LegacyCampaignBuilder = lazy(() => import('../pages/campaigns/legacyBuilder/CampaignBuilder'));
const AudienceBuilder = lazy(() => import('../pages/audiences/builder/AudienceBuilder'));
// const Reviews = lazy(() => import('./Reviews'));
const SettingsManager = lazy(() => import('../components/settings/SettingsManager'));
const Compliance = lazy(() => import('./Compliance'));
const Tracking = lazy(() => import('./Tracking'));
const SignupBuilder = lazy(() => import('../pages/signupForms/builder/SignupBuilder'));
const BrandInsights = lazy(() => import('../pages/analytics/insights/brand/BrandInsightsPage'));
const RetailInsights = lazy(() => import('../pages/analytics/insights/retail/RetailInsightsPage'));
const ScheduledReports = lazy(() => import('../pages/analytics/insights/reports/ScheduledReports'));
const InviteSignupFlow = lazy(() => import('../pages/signupflow/InviteSignupFlowRoute'));
const DataNetwork = lazy(() => import('./DataNetwork'));
const Personas = lazy(() => import('../pages/personas/PersonasPage'));
const Sources = lazy(() => import('./Sources'));
const RedeemLoyalty = lazy(() => import('./RedeemLoyalty'));
// const RedeemDiscountLegacy = lazy(() => import('./RedeemDiscount'));
const RedeemDiscountPage = lazy(() => import('../pages/discounts/redeem/RedeemDiscountPage'));
const SignupForm = lazy(() => import('../pages/signupForms/form/SignupForm'));
const ReviewUs = lazy(() => import('./ReviewUs'));
const WebWallet = lazy(() => import('../pages/wallet/WebWallet'));
const ProductLandingPage = lazy(() => import('./ProductLandingPage'));
// const TrackingPreferences = lazy(() => import('./TrackingPreferences'));
const TrackingPreferences = lazy(() => import('../pages/auth/trackingPreferences'));
const MediaLibrary = lazy(() => import('./MediaLibrary'));
const BudTenderPage = lazy(() => import('../pages/budTender/BudTenderPage'));
const StoreDesign = lazy(() => import('./StoreDesign'));
const PageNotFoundOrShortyLink = lazy(() => import('../components/error/PageNotFoundOrShortyLink'));
const ThankYou = lazy(() => import('./ThankYou'));
const MissionControl = lazy(() => import('../pages/missionControl/MissionControlPage'));
const AddBilling = lazy(() => import('./AddBilling'));
const UserEdit = lazy(() => import('./AccountEdit'));
const ReceiptLookup = lazy(() => import('./ReceiptLookup'));
const MarketAnalysis = lazy(() => import('./MarketAnalytics'));
const FileUploader = lazy(() => import('./FileUploader'));
const DomainHealth = lazy(() => import('./DomainHealth'));
const AccountHealth = lazy(() => import('./AccountHealth'));
const Greenbits = lazy(() => import('./Greenbits'));
const LoginLookup = lazy(() => import('./LoginLookup'));
const ForgotPassword = lazy(() => import('../pages/auth/forgotPassword'));
const AccountRenewals = lazy(() => import('../pages/admin/AccountRenewals'));
const CustomerChat = lazy(() => import('../pages/chat/CustomerChat'));

const SurveyPage = lazy(() => import('../pages/surveysAndReviews/pages/survey/SurveyPage'));
const SurveyAndReviewsPage = lazy(() => import('../pages/surveysAndReviews/SurveyAndReviewsPage'));
const PlannerPage = lazy(() => import('../pages/planner/PlannerPage'));
const Swagger = lazy(() => import('./Swagger'));
const EmailOptOutPage = lazy(() => import('../pages/public/EmailPreferences'));
const CrewPage = lazy(() => import('../pages/crew/CrewPage'));

function redirWithID(props: any) {
	const { match } = props, uid = utils.auth.getUID();
	if (uid && match && match.path) return <Redirect to={`${match.path}/${uid}`} />;
	return <Redirect to="/" />;
}

function goHome() {
	return <Redirect to={utils.home()} />;
}

export default () => (
	<Router history={history}>
		<Switch>

			<Route path="/" exact component={useAuthentication(goHome)} />
			<Route path="/login" exact component={Login} />
			<Route path="/login/:sso" exact component={Login} />
			<Route path="/login/:uid/:token" exact component={Login} />

			<Route path="/swagger" exact component={Swagger} />
			<Route path="/emailSettings/:uid" exact component={EmailOptOutPage} />
			<Route path="/emailOptOut/:uid" exact component={EmailOptOutPage} />

			<Route path="/wallet/:uid/:contactID/:tab" exact component={WebWallet} />
			<Route path="/wallet/:uid/:contactID" exact component={WebWallet} />
			<Route path="/wallet/:uid" exact component={WebWallet} />
			<Route path="/wallet/" exact component={WebWallet} />

			<Route path="/planner/:uid" exact component={useAuthentication(PlannerPage)} />

			<Route path="/surveysreviews/:uid" exact component={useAuthentication(SurveyAndReviewsPage)} />
			<Route path="/surveysreviews/:uid/:page" exact component={useAuthentication(SurveyAndReviewsPage)} />
			<Route path="/product/:uid/:productID/:batchID/:collectibleID" exact component={ProductLandingPage} />
			<Route path="/walletsearch/:uid" exact component={useAuthentication(BudTenderPage, true)} />
			<Route path="/walletsearch/:uid/:phone" exact component={useAuthentication(BudTenderPage, true)} />
			<Route path="/walletsearch/byID/:uid/:contactID" exact component={useAuthentication(BudTenderPage, true)} />
			<Route path="/review/:uid/:contactID" exact component={ReviewUs} />
			<Route path="/review/:uid" exact component={ReviewUs} />

			{/* <Route path="/redeemLoyalty/:uid/:contactID/:cmpID" exact component={RedeemLoyalty} /> */}
			{/* <Route path="/redeemDiscount/:uid/:storeID/:templateID/:key" exact component={RedeemDiscountLegacy} /> */}
			{/* <Route path="/redeemDiscount/:uid/:templateID/:key" exact component={RedeemDiscountLegacy} /> */}

			<Route path="/redeemLoyalty/:uid/:contactID/:cmpID" exact component={RedeemLoyalty} />

			<Route path="/redeemDiscount/:uid/:storeID/:templateID/:key" exact component={RedeemDiscountPage} />
			<Route path="/redeemDiscount/:uid/:templateID/:key" exact component={RedeemDiscountPage} />

			<Route path="/survey/c/:uid/:surveyID" exact component={SurveyPage} />
			<Route path="/survey/c/:uid/:surveyID/:contactID" exact component={SurveyPage} />
			<Route path="/join/c/:uid/:formID" exact component={SignupForm} />
			<Route path="/joins/c/:uid/:formID" exact component={SignupForm} />
			<Route path="/join/c/:uid/:formID/:attrs" exact component={SignupForm} />
			<Route path="/joinMembers/:uid" exact component={SignupForm} />
			<Route path="/joinLoyalty/:uid" exact component={SignupForm} />
			<Route path="/join/c/:uid/:formID/:attrs" exact component={SignupForm} />

			<Route path="/trackingPreferences/:uid" exact component={TrackingPreferences} />
			<Route path="/trackingPreferences/:step/:uid/:reqKey" exact component={TrackingPreferences} />
			<Route path="/forgotPassword" exact component={ForgotPassword} />
			<Route path="/resetPassword/:token" exact component={ForgotPassword} />
			<Route path="/newLogin/:token" exact component={ForgotPassword} />
			<Route path="/logout" exact component={Logout} />

			<Route path="/signupflow/:token" exact component={InviteSignupFlow} />
			<Route path="/signupflow/:step/:uid/:token" exact component={InviteSignupFlow} />
			<Route path="/signupflow" exact component={useAuthentication(InviteSignupFlow, true)} />
			<Route path="/signupflow/:step/:uid" exact component={useAuthentication(InviteSignupFlow, true)} />

			<Route path="/globalStyles" exact component={useAuthentication(GlobalStyles)} />
			<Route path="/admin" exact component={useAuthentication(Admin)} />
			<Route path="/search" exact component={useAuthentication(Search)} />
			<Route path="/agency/:uid" exact component={useAuthentication(Agency)} />

			<Route path="/storeDesign/:uid" exact component={useAuthentication(StoreDesign)} />
			<Route path="/storeDesign/:uid/:storeID" exact component={useAuthentication(StoreDesign)} />
			<Route path="/receiptLookup" exact component={useAuthentication(ReceiptLookup)} />
			<Route path="/receiptLookup/:uid" exact component={useAuthentication(ReceiptLookup)} />
			<Route path="/fileUploads/:uid" exact component={useAuthentication(FileUploader)} />
			<Route path="/userAlerts/:uid" exact component={useAuthentication(ManageAlerts)} />
			<Route path="/domainHealth" exact component={useAuthentication(DomainHealth)} />
			<Route path="/admin/accounts/renewals" exact component={useAuthentication(AccountRenewals)} />
			<Route path="/admin/accounts/renewals/:tab" exact component={useAuthentication(AccountRenewals)} />
			<Route path="/accountHealth" exact component={useAuthentication(AccountHealth)} />
			<Route path="/accountHealth/:tab" exact component={useAuthentication(AccountHealth)} />
			<Route path="/greenbits" exact component={useAuthentication(Greenbits)} />
			<Route path="/loginLookup" exact component={useAuthentication(LoginLookup)} />

			{/* remove these later after verifying they all work with single page setup */}
			<Route path="/sources/:uid" exact component={useAuthentication(Sources)} />
			{/* <Route path="/destinations/:uid" exact component={useAuthentication(Destinations)} />*/}
			<Route path="/audiences/builder/:uid" exact component={useAuthentication(AudienceBuilder)} />
			<Route path="/audiences/builder/:uid/:audienceID" exact component={useAuthentication(AudienceBuilder)} />

			<Route path="/chat/:uid" exact component={useAuthentication(CustomerChat)} />

			<Route path="/campaign/builder/:uid" exact component={useAuthentication(CampaignBuilder, true)} />
			<Route path="/campaign/builder/:uid/:campaignID" exact component={useAuthentication(CampaignBuilder, true)} />
			{/* Legacy builder */}
			<Route path="/campaign/legacyBuilder/:uid" exact component={useAuthentication(LegacyCampaignBuilder)} />
			<Route path="/campaign/legacyBuilder/:uid/:campaignID" exact component={useAuthentication(LegacyCampaignBuilder)} />

			<Route path="/audiences/manage/:uid" exact component={useAuthentication(Audiences)} />
			<Route path="/audiences/manage/:uid/:groupId" exact component={useAuthentication(Audiences)} />

			<Route path="/discounts/manage/:uid" exact component={useAuthentication(DiscountManager)} />
			<Route path="/discounts/manage/:uid/:groupId" exact component={useAuthentication(DiscountManager)} />

			<Route path="/discounts/manageLegacy/:uid" exact component={useAuthentication(LegacyDiscountsManager)} />
			<Route path="/discounts/manageLegacy/:uid/:groupId" exact component={useAuthentication(LegacyDiscountsManager)} />

			<Route path="/discount/builder/:uid" exact component={useAuthentication(DiscountBuilder, true)} />
			<Route path="/discount/builder/:uid/:discountID" exact component={useAuthentication(DiscountBuilder, true)} />
			<Route path="/discount/legacyBuilder/:uid" exact component={useAuthentication(LegacyDiscountBuilder)} />
			<Route path="/discount/legacyBuilder/:uid/:templateID" exact component={useAuthentication(LegacyDiscountBuilder)} />
			<Route path="/campaigns/manage/:uid" exact component={useAuthentication(CampaignManager)} />
			<Route path="/campaigns/manage/:uid/:groupId" exact component={useAuthentication(CampaignManager)} />
			<Route path="/campaigns/reports/:uid" exact component={useAuthentication(CampaignReports)} />
			{/* <Route path="/reviews/manage/:uid" exact component={useAuthentication(Reviews)} /> */}

			<Route path="/settings/:uid" exact component={useAuthentication(SettingsManager)} />
			<Route path="/settings/:uid/signup/builder/" exact component={useAuthentication(SignupBuilder)} />
			<Route path="/settings/:uid/signup/builder/:formID" exact component={useAuthentication(SignupBuilder)} />
			<Route path="/settings/:uid/:tab" exact component={useAuthentication(SettingsManager)} />
			<Route path="/settings/:uid/:tab/:subTab" exact component={useAuthentication(SettingsManager)} />

			<Route path="/compliance/:uid" exact component={useAuthentication(Compliance)} />
			<Route path="/tracking/:uid" exact component={useAuthentication(Tracking)} />

			<Route path="/ecommerce/:uid" exact component={useAuthentication(EcomHome)} />
			<Route path="/ecommerce/:uid/:page" exact component={useAuthentication(EcomHome)} />

			{/* #f0f Routes for my retail/brand insights */}
			<Route path="/public/analysis/:uid/:reportID" exact component={RetailInsights} />
			<Route path="/public/analysis/:uid" exact component={RetailInsights} />
			<Route path="/public/report/:uid/:reportID" exact component={RetailInsights} />
			<Route path="/public/report/:uid" exact component={RetailInsights} />
			<Route path="/scheduled/analysis/:uid/:scheduledID" exact component={RetailInsights} />
			<Route path="/analysis/:uid" exact component={useAuthentication(RetailInsights)} />
			<Route path="/analysis/:uid/:audienceIDs" exact component={useAuthentication(RetailInsights)} />
			<Route path="/analysis/:uid/group/:groupID" exact component={useAuthentication(RetailInsights)} />

			<Route path="/public/brandAnalysis/:uid/:reportID" exact component={BrandInsights} />
			<Route path="/scheduled/brandAnalysis/:uid/:scheduledID" exact component={BrandInsights} />
			<Route path="/brandAnalysis/:uid" exact component={useAuthentication(BrandInsights)} />
			<Route path="/brandAnalysis/:uid/:savedID" exact component={useAuthentication(BrandInsights)} />
			{/* #f0f Routes for my retail/brand insights */}

			<Route path="/marketSpotlight/:uid" exact component={useAuthentication(MarketAnalysis)} />

			<Route path="/scheduledReports/:uid/:tab" exact component={useAuthentication(ScheduledReports)} />

			<Route path="/dataNetwork/:uid" exact component={useAuthentication(DataNetwork)} />
			<Route path="/personas/manage/:uid" exact component={useAuthentication(Personas)} />
			<Route path="/missionControl/:uid" exact component={useAuthentication(MissionControl)} />
			<Route path="/marketplace/:uid" exact component={useAuthentication(Integrations)} />
			<Route path="/billing/add/:uid" exact component={useAuthentication(AddBilling)} />
			<Route path="/thankyou/:uid" exact component={useAuthentication(ThankYou)} />
			<Route path="/user/edit/:uid" exact component={useAuthentication(UserEdit)} />

			{/* New recipes */}
			<Route path="/templates/:uid" exact component={useAuthentication(MarketingTemplates)} />
			<Route path="/templates/:uid/:page" exact component={useAuthentication(MarketingTemplates)} />
			<Route path="/templates/:uid/:page/:subPage" exact component={useAuthentication(MarketingTemplates)} />

			<Route path="/mediaLibrary/:uid" exact component={useAuthentication(MediaLibrary)} />

			{/* Crew related pages */}
			<Route path="/aiqcrew/:crewPage/:uid" exact component={useAuthentication(CrewPage)} />

			<Route path="/agency" exact render={redirWithID} />
			<Route path="/user/edit" exact render={redirWithID} />
			<Route path="/settings" exact render={redirWithID} />
			<Route path="/marketplace" exact render={redirWithID} />
			<Route path="/userAlerts" exact render={redirWithID} />
			<Route path="/sources/create" exact render={redirWithID} />
			<Route path="/sources" exact render={redirWithID} />
			<Route path="/audiences/manage/" exact render={redirWithID} />
			<Route path="/discounts/manage/" exact render={redirWithID} />

			<Route path="/campaign/builder/" exact render={redirWithID} />
			<Route path="/campaigns/manage/" exact render={redirWithID} />
			<Route path="/campaigns/reports/" exact render={redirWithID} />
			{/* <Route path="/reviews/manage/" exact render={redirWithID} /> */}
			<Route path="/settings/" exact render={redirWithID} />
			<Route path="/compliance" exact render={redirWithID} />
			<Route path="/media" exact render={redirWithID} />
			<Route path="/tracking" exact render={redirWithID} />
			<Route path="/email/settings" exact render={redirWithID} />
			<Route path="/app/settings" exact render={redirWithID} />
			<Route path="/analysis" exact render={redirWithID} />
			<Route path="/dataNetwork" exact render={redirWithID} />
			<Route path="/personas/manage" exact render={redirWithID} />
			<Route path="/thankyou" exact render={redirWithID} />
			<Route path="/missionControl" exact render={redirWithID} />

			<Route path="/sms/builder/:uid" exact render={() => <Redirect to="/campaign/builder" />} />
			<Route path="/sms/builder/:uid/:campaignID" exact render={() => <Redirect to="/campaign/builder" />} />
			<Route path="/sms/manage/:uid" exact render={() => <Redirect to="/campaigns/manage/" />} />
			<Route component={PageNotFoundOrShortyLink} />
		</Switch>
	</Router>
);
