import { GeneratedAudience, GeneratedAudienceDescriptions, GeneratedAudienceRating } from './../pages/audiences/builder/audience.builder.types';
import { FetchOptions, fetchAPI } from ".";
import { GenAudienceResponse } from "../pages/audiences/builder/audience.builder.types";
import { Audience, AudienceGroup, BulkUpdateItem } from "../types";

function getAudiences(options?: FetchOptions): Promise<Audience[] | null> {
    return fetchAPI(`/audiences/:uid`, { method: 'GET', ...options })
}

function getAudience(id?: string, options?: FetchOptions): Promise<Audience> {
    return fetchAPI(`/audience/:uid/${id}`, { method: 'GET', ...options })
}

function createAudience(audience: Audience, options?: FetchOptions): Promise<Audience> {
    return fetchAPI(`/audience/:uid`, { method: 'POST', payload: audience, ...options })
}

function updateAudience(audience: Audience, options?: FetchOptions): Promise<Audience> {
    return fetchAPI(`/audience/:uid/${audience.id}`, { method: 'PUT', payload: audience, ...options })
}

function archiveAudience(id?: string, status?: boolean, options?: FetchOptions): Promise<void> {
    return fetchAPI(`/audience/:uid/${id}/${status}`, { method: 'PUT', ...options })
}

function setAudienceStatus(id?: string, status?: boolean, options?: FetchOptions): Promise<void> {
    return fetchAPI(`/audience/status/:uid/${id}/${status}`, { method: 'PUT', ...options })
}

function forecastAudience(id?: string, options?: FetchOptions): Promise<void> {
    return fetchAPI(`/audience/forecastv2/:uid/${id}`, { method: 'POST', ...options })
}

function getAudienceGroups(options?: FetchOptions): Promise<AudienceGroup[]> {
    return fetchAPI(`/audience/groups/:uid`, { method: 'GET', ...options })
}

function getAudienceGroup(id?: string, options?: FetchOptions): Promise<AudienceGroup> {
    return fetchAPI(`/audience/groups/:uid/${id}`, { method: 'GET', ...options })
}

function createAudienceGroup(group: AudienceGroup, options?: FetchOptions): Promise<AudienceGroup> {
    return fetchAPI(`/audience/group/:uid`, { method: 'POST', payload: group, ...options })
}

function updateAudienceGroup(group: AudienceGroup, options?: FetchOptions): Promise<AudienceGroup> {
    return fetchAPI(`/audience/group/:uid/${group.id}`, { method: 'PUT', payload: group, ...options })
}

function archiveAudienceGroup(id?: string, status?: boolean, options?: FetchOptions): Promise<void> {
    return fetchAPI(`/audience/group/:uid/${id}/${status}`, { method: 'PUT', ...options })
}

function bulkAudienceAction(payload: BulkUpdateItem[], options?: FetchOptions): Promise<void> {
    return fetchAPI(`/audience/:uid`, { method: 'PUT', payload, ...options })
}

function lockAudience(id?: string, status?: boolean, options?: FetchOptions): Promise<void> {
    return fetchAPI(`/audience/lock/:uid/${id}/${status}`, { method: 'PUT', ...options })
}

function segementAudience(id?: string, options?: FetchOptions): Promise<void> {
    return fetchAPI(`/audience/segment/:uid/${id}`, { method: 'PUT', ...options })
}

function sendEmailReport(id?: string, options?: FetchOptions): Promise<void> {
    return fetchAPI(`/audience/report/:uid/${id}`, { method: 'PUT', ...options })
}

function sendPointsReport(id?: string, options?: FetchOptions): Promise<void> {
    return fetchAPI(`/audience/points/:uid/${id}`, { method: 'PUT', ...options })
}

function sendSalesReport(id?: string, options?: FetchOptions): Promise<void> {
    return fetchAPI(`/audience/sales/:uid/${id}`, { method: 'PUT', ...options })
}

function sendStoresReport(id?: string, options?: FetchOptions): Promise<void> {
    return fetchAPI(`/audience/stores/:uid/${id}`, { method: 'PUT', ...options })
}

function sendReferReport(id?: string, options?: FetchOptions): Promise<void> {
    return fetchAPI(`/audience/referrals/:uid/${id}`, { method: 'PUT', ...options })
}

// Audience generation
function generateAudienceFromPrompt(payload: { prompt: string }, options?: FetchOptions): Promise<GenAudienceResponse> {
    return fetchAPI(`/audgen/generate/:uid`, { method: 'POST', payload, ...options })
}

// Rate audience generation
function rateAudienceGeneration(payload: Partial<GeneratedAudienceRating>, options?: FetchOptions): Promise<void> {
    return fetchAPI(`/audgen/setscore/:uid`, { method: 'POST', payload, ...options })
}

// Generate audience description
function generateAudienceDescription(payload: {
    audience: GeneratedAudience
    multiple?: boolean
}, options?: FetchOptions): Promise<GeneratedAudienceDescriptions> {
    return fetchAPI(`/descgen/generate/:uid`, { method: 'POST', payload, ...options })
}

export const AudiencesAPI = {
    getAudiences,
    getAudience,
    createAudience,
    updateAudience,
    archiveAudience,
    setAudienceStatus,
    forecastAudience,
    getAudienceGroups,
    getAudienceGroup,
    createAudienceGroup,
    updateAudienceGroup,
    archiveAudienceGroup,
    bulkCampaignAction: bulkAudienceAction,
    lockAudience,
    segementAudience,
    sendEmailReport,
    sendPointsReport,
    sendSalesReport,
    sendStoresReport,
    sendReferReport,
    generateAudienceFromPrompt,
    rateAudienceGeneration,
    generateAudienceDescription
}
