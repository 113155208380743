import { CSSProperties } from 'react';

import { AppSettings } from '../../../types/users';

export const collapseColor = '#333';

const legacyWalletStyles = {
	//
	// Container Styles //
	//
	container: {
		flex: 1,
		backgroundColor: '#fff',
		color: '#000'
	},
	containerInternal: {
		flex: 1,
		backgroundColor: '#fff',
		color: '#000',
		borderRadius: 10,
		padding: 10,
		borderWidth: 1,
		borderTopWidth: 1,
		borderBottomWidth: 1
	},
	input: {
		borderWidth: 1,
		paddingVertical: 2,
		paddingHorizontal: 5,
		borderColor: collapseColor // @Nick do your thing
	},
	inputV2: {
		borderWidth: 1,
		paddingVertical: 2,
		paddingHorizontal: 5,
		borderColor: 'rgba(0,0,0,0.1)',
		shadowColor: 'rgba(183,192,206,.2)',
		shadowOffset: { width: 2, height: 2 },
		shadowRadius: 10
	},
	row: {
		margin: 5
	},
	col: {
		margin: 5
	},
	item: {
		margin: 5
	},
	itemLeft: {
		margin: 0,
		marginEnd: 5
	},
	itemRight: {
		margin: 0,
		marginStart: 5
	},
	collapse: {
		paddingLeft: 5,
		margin: 0,
		borderWidth: 1,
		borderRadius: 10,
		borderColor: '#6CD9A0',
		marginBottom: 5
	},
	collapseBody: {
		borderTopWidth: 1,
		borderColor: '#6CD9A0',
		padding: 5,
		paddingTop: 10
	},
	centeredView: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	modalView: {
		margin: 20,
		borderRadius: 20,
		padding: 30,
		alignItems: 'center',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2
		},
		shadowOpacity: 0.25,
		shadowRadius: 4,
		elevation: 5
	},
	body: {
		backgroundColor: '#fff'
	},
	round: {
		borderRadius: 10,
		borderWidth: 1,
		borderColor: '#fff'
	},
	referBox: {
		padding: 10,
		borderWidth: 1,
		borderStyle: 'dashed',
		borderRadius: 8
	},
	fullWidth: {
		flex: 1
	},
	col60: {
		flex: 0.6,
		flexDirection: 'column',
		paddingHorizontal: 4
	},
	col50: {
		flex: 0.5,
		flexDirection: 'column',
		paddingHorizontal: 4
	},
	col20: {
		flex: 0.2,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		paddingHorizontal: 4
	},
	//
	// Text & Icon Styles //
	//
	sectionTitle: {
		color: '#000',
		fontWeight: 'bold',
		fontSize: 18,
		width: '100%'
	},
	h2: {
		color: '#000',
		fontWeight: 'bold',
		fontSize: 16,
		width: '100%',
		marginTop: 5,
		marginBottom: 5,
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center'

	},
	p: {
		fontWeight: 'normal',
		fontSize: 14
	},
	bold: {
		fontWeight: 'bold',
		fontSize: 16
	},
	ts: {
		color: '#000',
		fontWeight: 'normal',
		fontSize: 11
	},
	buttonText: {
		color: '#fff',
		fontWeight: 'normal',
		textTransform: 'none',
		fontSize: 16
	},
	buttonGhostText: {
		color: '#6CD9A0',
		fontWeight: 'normal',
		textTransform: 'none',
		fontSize: 16
	},
	buttonTextSm: {
		color: '#fff',
		fontWeight: 'normal',
		textTransform: 'none',
		fontSize: 12
	},
	iconButtonText: {
		color: '#000'
	},
	buttonDangerText: {
		color: '#fff',
		fontWeight: 'normal',
		textTransform: 'none',
		fontSize: 16
	},
	buttonDeleteText: {
		color: '#fff',
		fontWeight: 'normal',
		textTransform: 'none',
		fontSize: 16
	},
	buttonCancelText: {
		color: '#147EFB',
		fontWeight: 'normal',
		textTransform: 'none',
		fontSize: 16
	},
	modalTitle: {
		color: '#000',
		fontWeight: 'bold',
		fontSize: 18,
		marginBottom: 10,
		textAlign: 'center'
	},
	modalText: {
		color: '#000',
		marginBottom: 30,
		textAlign: 'center'
	},
	error: {
		fontWeight: 'bold',
		color: 'red',
		padding: 5,
		borderColor: 'red'
	},
	caption: {
		fontSize: 14,
		lineHeight: 14
	},
	referLink: {
		textAlign: 'center',
		color: 'blue',
		marginBottom: 5
	},
	icon: {
		size: 'md',
		color: '#000'
	},
	xxsIcon: {
		style: { fontSize: 9 },
		color: '#000'
	},
	xxsIconClr: {
		fontSize: 9,
		color: '#fff'
	},
	iconButton: {
		fontSize: 16,
		height: 30,
		width: 30,
		padding: 7,
		color: '#000'
	},
	caret: { fontSize: 10, color: '#b0b0b0' },
	//
	// Input & Button Styles //
	//
	button: {
		backgroundColor: '#6CD9A0',
		borderRadius: 10,
		borderColor: '#6CD9A0',
		borderWidth: 1,
		borderStyle: 'solid',
		color: '#000'
	},
	buttonIcon: {
		width: 40,
		height: 40,
		backgroundColor: '#fff',
		borderRadius: 100,
		borderColor: '#fff',
		borderWidth: 1,
		borderStyle: 'solid',
		// padding: 4,
		color: '#000'
	},
	buttonGhost: {
		backgroundColor: '#fff',
		borderRadius: 10,
		borderColor: '#6CD9A0',
		borderWidth: 1,
		borderStyle: 'solid'
	},
	buttonDanger: {
		backgroundColor: '#FF4D7E',
		borderRadius: 10,
		borderColor: '#FF4D7E',
		borderWidth: 1,
		borderStyle: 'solid'
	},
	buttonSignout: {
		backgroundColor: '#6CD9A0'
	},
	buttonConfirmGreen: {
		display: 'flex',
		borderColor: '#6CD9A0',
		backgroundColor: '#6CD9A0',
		borderRadius: 10,
		marginLeft: 5,
		width: '50%'
	},
	buttonDelete: {
		display: 'flex',
		backgroundColor: '#FF4D7E',
		borderRadius: 10,
		marginLeft: 5,
		width: '50%'
	},
	buttonCancel: {
		display: 'flex',
		backgroundColor: '#fff',
		borderRadius: 10,
		marginRight: 5,
		width: '50%'
	},
	switch: {
		color: '#6CD9A0'
	},
	link: {
		cursor: 'pointer',
		color: '#007BFF'
	},
	progress: {
		height: 15,
		fullColor: '#1890ff',
		color: '#52c41a',
		// you can play with this if you want
		background: 'rgba(250, 250, 250, 0.1)'
	},
	//
	// Misc Styles
	//
	backgroundImage: {
		flex: 1,
		resizeMode: 'cover',
		justifyContent: 'center',
		alignItems: 'center'
	},
	splash: {
		width: '100%',
		height: '100%',
		flex: 1,
		resizeMode: 'stretch' // or 'stretch'
	},
	borderBottom: {
		borderBottomWidth: 1,
		borderBottomColor: 'rgba(183,192,206,.2)'
	}
} as AppSettings;
export default legacyWalletStyles;
