import { fetchAPI } from '.';
import { utils } from '../helpers';
import { ProductReviewAggregateStats, ProductReviewPlus } from '../pages/surveysAndReviews/types/reviews';

const forceToken = "" || utils.TOKEN
const testUID = '' || utils.TEST_UID

function getReviewProducts(options?: { uid?: string }): Promise<{ [key: string]: ProductReviewAggregateStats }> {
    return fetchAPI('/reviewWidget/stats/:uid', { ...options, method: 'GET', forceToken, uid: options?.uid || testUID })
}

function getProductReviews(product: ProductReviewAggregateStats, options?: { uid?: string }): Promise<ProductReviewPlus[]> {
    return fetchAPI(`/reviewWidget/stats/product/:uid/${product.productID}`, { ...options, method: 'GET', forceToken, uid: options?.uid || testUID })
}

export const ReviewsAPI = {
    getReviewProducts,
    getProductReviews,
}