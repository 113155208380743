import React from 'react';
import { utils } from '../helpers';
import Loader from '../components/Loader';

const LightTheme = React.lazy(() => import('../themes/Light'));
const DarkTheme = React.lazy(() => import('../themes/Dark'));

export default function ThemeSelector({ children }: { children: any }) {
	let darkMode = utils.darkMode;

	const exemptFromDarkMode = [/^\/joins\/c\/(\d+)\/(\d+)$/];

	if (darkMode && exemptFromDarkMode.find((regex) => new RegExp(regex).test(location.pathname))) {
		utils.notify('Disabling dark mode', 'This page does not have dark mode support', 8, 'info');
		darkMode = false;
	}

	return (
		<>
			<React.Suspense fallback={<Loader fullPage />}>
				{!darkMode && <LightTheme>{children}</LightTheme>}
				{darkMode && <DarkTheme>{children}</DarkTheme>}
			</React.Suspense>
		</>
	);
}
