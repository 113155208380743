import '@natscale/react-calendar/dist/main.css';
import 'antd/dist/antd.css';

import { message } from 'antd';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import utils from './helpers/utils';
import { setPageTitle } from './hooks/UsePageViews';
import SentryStart from './start/SentryStart';
import ThemeSelector from './start/ThemeSelector';
import Loader from './components/Loader';

const ErrorBoundary = React.lazy(() => import('./components/error/ErrorBoundary'));
const APIDown = React.lazy(() => import('./components/error/APIDown'));

const initApp = (attempt = 0, error = undefined) => {
	if (attempt >= 5) return message.error('Failed to load dashboard, please try again later.');
	else if (attempt !== 0) message.loading('Attempting reload...', 8);

	const rootElement = document.getElementById('root');
	const root = createRoot(rootElement);

	utils.auth
		.dashData()
		.then(async (data) => {
			utils.dashData = data;
			document.title = data.fullTitle;
			setPageTitle();

			// if (data.favicon && !utils.isLocal && !utils.isStaging) document.querySelector('link[rel="shortcut icon"]').href = data.favicon;

			if (utils.strify(window.location.pathname) === '/swagger') utils.removeScript('hs-script-loader', true);

			rootElement.innerHTML = '';
			root.render(
				<ThemeSelector>
					<ErrorBoundary sendAlert>
						<App />
					</ErrorBoundary>
				</ThemeSelector>,
			);
		})
		.catch((err) => {
			if (attempt === 0) {
				if (rootElement.innerHTML) rootElement.innerHTML = '';
				root.render(
					<React.Suspense fallback={<Loader fullPage />}>
						<APIDown err={err} />
					</React.Suspense>,
				);
			}
			setTimeout(() => initApp(attempt + 1, err), 10000);
		});
};

SentryStart();
initApp();
