import { CrewMember } from "../types"
export * from '../pages/goals/crewgoal.helpers'

export function validateCrewMember(crewMember?: Partial<CrewMember>): [boolean, string[]] {
    if (!crewMember) return [false, ["No crew member provided."]]
    const errors: string[] = []

    // We require at least a phone or email
    if (!crewMember.phone && !crewMember.email) {
        errors.push("You must provide either a phone number or email address.")
    }

    // We require store
    // if (!crewMember.locationID) {
    //     errors.push("You must provide a store.")
    // }

    return [!errors.length, errors]
}