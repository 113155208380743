import * as msgpack from 'msgpack-lite';

export type DefaultObject = Record<string, unknown>;

export function encodeMSGPack<T = DefaultObject>(data: T): Uint8Array {
    return msgpack.encode(data);
}

export function decodeMSGPack<T = DefaultObject>(data: Uint8Array): T | undefined {
    try {
        return msgpack.decode(data);
    } catch (e) {
        return undefined as T;
    }
}
