import { AutoComplete, Avatar, Empty, Modal, Select, Space } from 'antd'
import React from 'react'
import { EntryMap, LoaderState, getColumn, getColumns, } from '../../../../types'
import { StaticProduct } from '../cmp.builder.types'
import { API, asyncWrap } from '../../../../api'
import GButton from '../../../../components/global/GButton'
import { SearchOutlined } from '@ant-design/icons'
import { colors, userUtils, utils } from '../../../../helpers'
import { useSearchDebounce } from '../../../../hooks/useSearchDebounce'
import FA from '../../../../components/global/FA'
import Break from '../../../../components/Break'
import GTable from '../../../../components/global/GTable'
import HighlightText from '../../../../components/HighlightText'

export interface ProductSelectorModalContextState {
    ecomBrands: string[]
    ecomCategories: string[]
}

export type ProductSelectorModalContext = [
    ecomData: ProductSelectorModalContextState,
    setEcomData: React.Dispatch<React.SetStateAction<ProductSelectorModalContextState>>
]

export const ProductSelectorModalContextProvider = React.createContext<ProductSelectorModalContext | {}>({})

type ProductSelectorModalProps = {
    slugs?: string[]
    onConfirm: (products: EntryMap<StaticProduct>) => void
}

export default function ProductSelectorModal(props: ProductSelectorModalProps) {

    const [open, setOpen] = React.useState(false)
    const [inputValue, search, setSearch] = useSearchDebounce()
    const [hasSearched, setHasSearched] = React.useState(false)
    const [loader, setLoader] = React.useState<LoaderState>({ loading: false, loaded: false });
    const [staticProducts, setStaticProducts] = React.useState<EntryMap<StaticProduct & { selected?: boolean }>>({})

    const [ecomData, setEcomData] = React.useContext(ProductSelectorModalContextProvider) as ProductSelectorModalContext
    const [filters, setFilters] = React.useState({
        categories: [],
        brands: [],
    })

    React.useEffect(() => {
        if (!loader.loaded) asyncWrap(async () => {
            const ecomBrands = await API.getEcomBrands() || []
            const ecomCategories = await API.getEcomCategories() || []

            // If they have slugs, load the products
            if (props.slugs?.length) {
                handleSearch(true)
            }

            setEcomData({ ecomBrands, ecomCategories });
        }, {
            finallyCallback(wasError) {
                setLoader({ loading: false, loaded: true, })
            },
            cancelError: utils.isDemo() || utils.isStaging
        })
    }, [open])


    const handleSearch = (initial?: boolean) => {
        asyncWrap(async () => {
            let productsMap: EntryMap<StaticProduct & { selected?: boolean }> = {}
            // Load loacal products if we're in sandbox
            if ((utils.isLocal || utils.isStaging || utils.isDemo())) {
                productsMap = await import('../../../../assets/sandbox/ecomProducts.json') as EntryMap<StaticProduct>
            } else {
                productsMap = await API.getStaticProducts({
                    slugs: initial ? props.slugs : undefined,
                    categories: filters.categories || [],
                    brands: filters.brands || [],
                }) || {}
            }

            // Let user know if we found/didn't find any products
            if (Object.keys(productsMap).length === 0) {
                utils.showErr('No products found')
                return
            } else {
                utils.showSuccess(`${utils.formatNumber(Object.keys(productsMap).length)} products found`)
            }

            // if intital load we onCOnfirm with the products that match props.slugs
            if (initial) {
                const selectedProducts = Object.entries(productsMap).reduce((acc, [slug, product]) => {
                    if (props.slugs?.includes(slug || '')) {
                        acc[slug || ''] = product
                        // Set selected to true 
                        productsMap[slug || ''].selected = true

                    }
                    return acc
                }, {} as EntryMap<StaticProduct>)
                props.onConfirm(selectedProducts)
            }

            setStaticProducts(productsMap)
            setHasSearched(true)
        })
    }

    const selectProduct = (product: StaticProduct) => {
        const isSelected = !!staticProducts[product.slug || '']?.selected
        const newProducts = { ...staticProducts }
        newProducts[product.slug || ''].selected = !isSelected
        setStaticProducts(newProducts)
    }

    const query = utils.strify(search)
    const productList = React.useMemo(() => {
        let results = Object.values(staticProducts)
        if (query) {
            results = results.filter((product) => {
                return utils.strify(product.productName).includes(query) || utils.strify(product.slug).includes(query)
            })
        }
        return results.map((product, key) => ({
            ...product, key,
            label: product.productName,
            value: product.slug,
        }))
    }, [staticProducts, query])

    const categoryOptions = React.useMemo(() => {
        return ecomData.ecomCategories.map((category) => ({ label: category, value: category }))
    }, [ecomData.ecomCategories])

    const brandOptions = React.useMemo(() => {
        return ecomData.ecomBrands.map((brand) => ({ label: brand, value: brand }))
    }, [ecomData.ecomBrands])

    if (!userUtils.hasPackageTier('marketing', 'pro')) return null

    return (<>

        <GButton
            size='large'
            tooltip='Select static products to insert into this template using the "Static Product" tool'
            onClick={() => setOpen(true)}
        >
            <FA
                name="circle-plus"
                style={{ marginRight: 5, opacity: .8 }}
            />
            Static products
        </GButton>

        <Modal
            open={open}
            centered
            onCancel={() => setOpen(false)}
            width={'max-content'}
            bodyStyle={{ padding: '16px 32px' }}
            okText='Select products'
            onOk={() => {
                const selectedProducts = Object.values(staticProducts || {}).reduce((acc, product) => {
                    if (product.selected) acc[product.slug || ''] = product
                    return acc
                }, {} as EntryMap<StaticProduct>)
                props.onConfirm(selectedProducts)
                setOpen(false)
            }}
        >

            <div>
                <span style={{ fontSize: '1.25rem', fontWeight: 700, lineHeight: '130%' }}>Insert a product</span>
                <Break s='15' />
            </div>

            <>
                <div style={{ display: 'flex', gap: 24, alignItems: 'center' }}>
                    <div>
                        <AutoComplete
                            style={{ width: '100%', minWidth: 300 }}
                            options={productList}
                            filterOption={utils.filterOption}
                            placeholder={<Space>
                                <SearchOutlined style={{ color: '#939399' }} /> Search media by name, tag, or type...
                            </Space>}
                            value={inputValue}
                            onChange={(value) => setSearch(value)}

                        />

                    </div>

                    <div>
                        <Select
                            style={{ width: 200 }}
                            placeholder='Filter by category'
                            allowClear
                            showArrow
                            mode='multiple'
                            options={categoryOptions}
                            value={filters.categories}
                            onChange={(value) => setFilters({ ...filters, categories: value })}
                        />
                    </div>

                    <div>
                        <Select
                            style={{ width: 200 }}
                            placeholder='Filter by brand'
                            allowClear
                            showArrow
                            mode='multiple'
                            options={brandOptions}
                            value={filters.brands}
                            onChange={(value) => setFilters({ ...filters, brands: value })}
                        />
                    </div>

                    <div>
                        <GButton
                            size='large'
                            onClick={() => handleSearch()}
                            type="primary"
                            style={{ paddingLeft: 24, paddingRight: 24 }}
                        >
                            Search
                        </GButton>
                    </div>


                </div>
            </>

            <div style={{ marginTop: 24, }}>

                <GTable
                    alternateHeader
                    noRadius
                    pagination={{ defaultPageSize: 10 }}
                    dataSource={productList}
                    loading={loader.loading}
                    columns={getColumns<StaticProduct & { selected?: boolean }>([
                        getColumn('', (product) => <>
                            <GButton
                                type={product.selected ? 'primary' : 'default'}
                                style={{ color: product.selected ? undefined : colors.primary.blue }}
                                onClick={() => selectProduct(product)}
                            >
                                {product.selected ? 'Selected' : 'Select'}
                            </GButton>
                        </>, { width: '100px' }),

                        getColumn('PRODUCT', (product) => <>
                            <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                                <Avatar shape="square" size={50} src={product.imageUrl} />
                                <HighlightText
                                    text={product.productName}
                                    search={search}
                                />
                            </div>
                        </>),

                        getColumn('PRICE', (product) => <>
                            ${utils.formatNumber(product.salesPrice)}
                        </>),


                    ])}

                    locale={{
                        emptyText() {
                            return <>
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description={
                                        hasSearched
                                            ? 'No products found'
                                            : 'Search for products'
                                    }
                                />
                            </>
                        }
                    }}

                />

            </div >

        </Modal >
    </>)
}