import React from "react"
import Break from "../../../components/Break"
import { CampaignChannelType } from "../../../types";

export type Macro = {
    name: string;
    description: string;
    macro: string;
    isLink?: boolean;
    category: string;
    requiredPermissions?: {
        marketing?: 'pro' | 'enterprise'
        loyalty?: 'starter' | 'pro' | 'enterprise'
    };
    tooltip?: React.ReactNode;
    regex?: RegExp;
    dontShowInMacroList?: boolean;
}

export const getLinkMacros = () => {
    return Object.values(CampaignBuilderMacros).filter(macro => macro.isLink).map(macro => macro.macro)
}

export const CampaignBuilderMacros: Record<string, Macro> = {
    brand: {
        name: 'Brand',
        description: 'The brand name',
        macro: '{{brand}}',
        category: 'General',
        requiredPermissions: {
            marketing: 'pro'
        }
    },
    date: {
        name: 'Date',
        description: 'The date',
        macro: '{{date}}',
        category: 'General',
        requiredPermissions: {
            marketing: 'pro'
        }
    },
    reviewWidgetURL: {
        isLink: true,
        name: 'Review Widget URL',
        description: 'The review widget URL',
        macro: '{{reviewWidgetURL}}',
        category: 'General',
        requiredPermissions: {
            marketing: 'pro'
        }
    },
    walletURL: {
        isLink: true,
        name: 'Wallet URL',
        description: 'The wallet URL',
        macro: '{{walletURL}}',
        category: 'General',
    },
    loyaltySignupURL: {
        isLink: true,
        name: 'Loyalty Signup URL',
        description: 'The loyalty signup URL',
        macro: '{{loyaltySignupURL}}',
        requiredPermissions: {
            marketing: 'pro'
        },
        category: 'General',
    },
    referFriendURL: {
        isLink: true,
        name: 'Refer Friend URL',
        description: 'The refer friend URL',
        macro: '{{referFriendURL}}',
        requiredPermissions: {
            marketing: 'pro'
        },
        category: 'General',
    },
    tattleFeedbackURL: {
        isLink: true,
        name: 'Tattle Feedback URL',
        description: 'The tattle feedback URL',
        tooltip: "This macro requires the Tattle integration. If turned on, place this macro in your campaign message body to send users to the Tattle feedback landing page.",
        macro: '{{tattleFeedbackURL}}',
        requiredPermissions: {
            marketing: 'pro'
        },
        category: 'General',
    },
    emailOptOutURL: {
        isLink: true,
        name: 'Email Opt Out URL',
        description: 'The email opt out URL',
        macro: '{{emailOptOutURL}}',
        category: 'General',
    },
    predictedTopProduct: {
        name: 'Predicted Top Product',
        description: 'The predicted top product',
        macro: '{{predictedTopProduct}}',
        requiredPermissions: {
            marketing: 'pro'
        },
        category: 'Prediction',
    },
    predictedTopSku: {
        name: 'Predicted Top SKU',
        description: 'The predicted top SKU',
        macro: '{{predictedTopSku}}',
        requiredPermissions: {
            marketing: 'pro'
        },
        category: 'Prediction',
    },
    predictedTopBrand: {
        name: 'Predicted Top Brand',
        description: 'The predicted top brand',
        macro: '{{predictedTopBrand}}',
        requiredPermissions: {
            marketing: 'pro'
        },
        category: 'Prediction',
    },
    predictedTopCategory: {
        name: 'Predicted Top Category',
        description: 'The predicted top category',
        macro: '{{predictedTopCategory}}',
        requiredPermissions: {
            marketing: 'pro'
        },
        category: 'Prediction',
    },
    favoriteStore: {
        name: 'Favorite Store',
        description: 'The favorite store',
        macro: '{{favoriteStore}}',
        requiredPermissions: {
            marketing: 'pro'
        },
        category: 'Contact',
    },
    favoriteStoreShopURL: {
        isLink: true,
        name: 'Favorite Store Shop URL',
        description: 'The favorite store shop URL',
        macro: '{{favoriteStoreShopURL}}',
        requiredPermissions: {
            marketing: 'pro'
        },
        category: 'Contact',
    },
    favoriteRecStoreShopURL: {
        isLink: true,
        name: 'Favorite Rec Store Shop URL',
        description: 'The favorite rec store shop URL',
        macro: '{{favoriteRecStoreShopURL}}',
        requiredPermissions: {
            marketing: 'pro'
        },
        category: 'Contact',
    },
    favoriteMedStoreShopURL: {
        isLink: true,
        name: 'Favorite Med Store Shop URL',
        description: 'The favorite med store shop URL',
        macro: '{{favoriteMedStoreShopURL}}',
        requiredPermissions: {
            marketing: 'pro'
        },
        category: 'Contact',
    },
    favStoreDisclaimer: {
        name: 'Favorite Store Disclaimer',
        description: 'The favorite store disclaimer',
        macro: '{{favStoreDisclaimer}}',
        requiredPermissions: {
            marketing: 'pro'
        },
        category: 'Contact',
    },
    favStoreAvatar: {
        name: 'Favorite Store Avatar',
        description: 'The favorite store avatar',
        macro: '{{favStoreAvatar}}',
        requiredPermissions: {
            marketing: 'pro'
        },
        category: 'Contact',
    },
    firstName: {
        name: 'First Name',
        description: 'The first name',
        macro: '{{firstName}}',
        category: 'Contact',
    },
    lastName: {
        name: 'Last Name',
        description: 'The last name',
        macro: '{{lastName}}',
        category: 'Contact',
    },
    name: {
        name: 'Name',
        description: 'The name',
        macro: '{{name}}',
        category: 'Contact',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    email: {
        name: 'Email',
        description: 'The email',
        macro: '{{email}}',
        category: 'Contact',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    phone: {
        name: 'Phone',
        description: 'The phone',
        macro: '{{phone}}',
        category: 'Contact',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    state: {
        name: 'State',
        description: 'The state',
        macro: '{{state}}',
        category: 'Contact',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    city: {
        name: 'City',
        description: 'The city',
        macro: '{{city}}',
        category: 'Contact',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    loyaltyPoints: {
        name: 'Loyalty Points',
        description: 'The loyalty points',
        macro: '{{loyaltyPoints}}',
        category: 'Contact',
    },
    contactID: {
        name: 'Contact ID',
        description: 'The contact ID',
        macro: '{{contactID}}',
        category: 'Contact',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    totalPointsEarned: {
        name: 'Total Points Earned',
        description: 'The total points earned',
        macro: '{{totalPointsEarned}}',
        category: 'Contact',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    totalPointsRedeemed: {
        name: 'Total Points Redeemed',
        description: 'The total points redeemed',
        macro: '{{totalPointsRedeemed}}',
        category: 'Contact',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    lastOrderedProduct: {
        name: 'Last Ordered Product',
        description: 'The last ordered product',
        macro: '{{lastOrderedProduct}}',
        category: 'Order',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    lastOrderedBrand: {
        name: 'Last Ordered Brand',
        description: 'The last ordered brand',
        macro: '{{lastOrderedBrand}}',
        category: 'Order',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    lastOrderedCategory: {
        name: 'Last Ordered Category',
        description: 'The last ordered category',
        macro: '{{lastOrderedCategory}}',
        category: 'Order',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    favoritestoreapplestore: {
        isLink: true,
        name: 'Favorite Store Apple Store',
        description: 'The favorite store apple store',
        macro: '{{favoritestoreapplestore}}',
        category: 'Social',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    favoritestoregoogleplay: {
        isLink: true,
        name: 'Favorite Store Google Play',
        description: 'The favorite store google play',
        macro: '{{favoritestoregoogleplay}}',
        category: 'Social',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    favoritestoreinstagram: {
        name: 'Favorite Store Instagram',
        description: 'The favorite store instagram',
        macro: '{{favoritestoreinstagram}}',
        category: 'Social',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    favoritestoretwitter: {
        isLink: true,
        name: 'Favorite Store Twitter',
        description: 'The favorite store twitter',
        macro: '{{favoritestoretwitter}}',
        category: 'Social',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    favoritestoregoogle: {
        isLink: true,
        name: 'Favorite Store Google',
        description: 'The favorite store google',
        macro: '{{favoritestoregoogle}}',
        category: 'Social',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    favoritestorefacebook: {
        isLink: true,
        name: 'Favorite Store Facebook',
        description: 'The favorite store facebook',
        macro: '{{favoritestorefacebook}}',
        category: 'Social',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    favoritestoreyelp: {
        isLink: true,
        name: 'Favorite Store Yelp',
        description: 'The favorite store yelp',
        macro: '{{favoritestoreyelp}}',
        category: 'Social',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    favoritestoreweedmaps: {
        isLink: true,
        name: 'Favorite Store Weedmaps',
        description: 'The favorite store weedmaps',
        macro: '{{favoritestoreweedmaps}}',
        category: 'Social',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    favoritestoreleafly: {
        isLink: true,
        name: 'Favorite Store Leafly',
        description: 'The favorite store leafly',
        macro: '{{favoritestoreleafly}}',
        category: 'Social',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    favoritestoretattle: {
        isLink: true,
        name: 'Favorite Store Tattle',
        description: 'The favorite store tattle',
        macro: '{{favoritestoretattle}}',
        category: 'Social',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    landingPage: {
        isLink: true,
        name: 'Landing Page',
        description: 'The landing page',
        macro: '{{landingPage}}',
        category: 'Social',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    forstaURL: {
        isLink: true,
        name: 'Forsta URL',
        description: 'The forsta URL',
        macro: '{{forstaURL}}',
        category: 'Social',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    emailOptInURL: {
        isLink: true,
        name: 'Email Opt In URL',
        description: 'The email opt in URL',
        macro: '{{emailOptInURL}}',
        category: 'Social',
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    favoriteStoreSales: {
        name: 'Favorite Store Sales',
        description: 'The favorite store sales',
        macro: '{{favoriteStoreSales-7-🔥}}',
        regex: /(?:{{favoriteStoreSales-)([0-9]+)-(.{0,})}}/gi,
        category: 'General',
        tooltip: <>
            <b>Requires GlobalTill POS:</b> This macro replaces itself with discounted products as long as they have at least <b>7</b> items in inventory available (You can change the <b>7</b> in the macro to any number of your choice).
            It customizes itself by the user's favorite store with top 10 items discounted with the largest discount item first.

            <Break air />
            <b>7</b> the argument is the minimum quantity that should be in stock for the product to be sent in the macro. <small>Can be any number.</small>
            <Break air />
            <b>🔥</b> optionally use a custom bullet point indicator/icon
        </>,
        requiredPermissions: {
            marketing: 'pro'
        },
    },
    discounts: {
        isLink: true,
        name: 'Discounts',
        dontShowInMacroList: true,
        description: 'The discounts',
        macro: '{{discounts-id}}',
        regex: /(?:{{discount-)([0-9]+)}}/gi,
        category: 'General',
    },
    surveys: {
        isLink: true,
        name: 'Surveys',
        dontShowInMacroList: true,
        description: 'The surveys',
        macro: '{{surveys-id}}',
        regex: /\{\{survey-[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\}\}/g,
        category: 'General',
    },
    dynamicContent: {
        name: 'Dynamic Content',
        dontShowInMacroList: true,
        description: 'The dynamic content',
        macro: '{{dynamicContent-x-x-x}}',
        regex: /\{\{dynamic-[A-Za-z0-9]+-[A-Za-z0-9]+-[A-Za-z0-9]+\}\}/gi,
        category: 'General',
    },
    productSlug: {
        name: 'Product Slug',
        dontShowInMacroList: true,
        description: 'The product slug',
        macro: '{{product-slug}}',
        category: 'General',
    },
} as const

export type CampaignBuilderMacro = keyof typeof CampaignBuilderMacros

export const warningMacros: string[] = [
    '{{favoritestore}}', '{{favoritestoreshopurl}}', '{{favstoreavatar}}', '{{favoritestoreapplestore}}',
    '{{favoritestoregoogleplay}}', '{{favoritestoreinstagram}}', '{{favoritestoretwitter}}', '{{favoritestoregoogle}}',
    '{{favoritestorefacebook}}', '{{favoritestoreyelp}}', '{{favoritestoreweedmaps}}', '{{favoritestoreleafly}}',
    '{{lastorderedproduct}}', '{{lastorderedbrand}}', '{{lastorderedcategory}}'
]

export const bannedMacrosByTemplate: { [key in CampaignChannelType]?: string[] } = {
    templates: ['{{emailoptouturl}}']
} 