import React from 'react';
import ReactGA4 from 'react-ga4';
// import ReactGA from 'react-ga';

import '@alpine-iq/ui-library/styles/main.css';
import { AppProvider, } from '@alpine-iq/ui-library';

import Routes from './routes';
import Loader from './components/Loader';

export default () => {
	React.useEffect(() => {
		if (!window['GA_INITIALIZED']) {
			ReactGA4.initialize('G-PPKJCEKLT1');
			window['GA_INITIALIZED'] = true;
		}

		// ReactGA4.set({ page: location.pathname });
		// ReactGA4.pageview(location.pathname + location.search);
		ReactGA4.send({ hitType: 'pageview', page: window.location.pathname });
	}, []);

	return (
		<>
			<React.Suspense fallback={<Loader fullPage />}>
				<AppProvider>
					<Routes />
				</AppProvider>
			</React.Suspense>
		</>
	);
};
