export * from './telynxSettings'


export const validateURL = (url?: string): boolean => {
    if (!url) {
        return false;
    }
    const urlRegex = /^(http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
};

export const validateEmail = (email?: string): boolean => {
    if (!email) {
        return false
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const validatePhoneNumber = (phone?: string): boolean => {
    if (!phone) {
        return false
    }
    const phoneRegex = /^\+?[1-9]\d{1,14}$/;
    return phoneRegex.test(phone);
};
