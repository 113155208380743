import { setUser } from '@sentry/react';
import { Layout, notification, Space } from 'antd';
import React, { lazy } from 'react';
import { useParams } from 'react-router-dom';

import { asyncWrap } from '../api';
import Break from '../components/Break';
import Loader from '../components/Loader';
import timeUtils from '../helpers/timeUtils';
import userUtils, { adminUtils } from '../helpers/userUtils';
import utils from '../helpers/utils';
import CH from '../pages/admin/CH';
import { showSidebarInCampaigns } from '../pages/campaigns/builder/cmp.builder.helpers';
import { showSidebarInDiscounts } from '../pages/discounts/builder/disc.builder.helpers';
import { AuthContext, AuthContextState, User } from '../types';
import { Role } from '../types/acl';
import ContextProvider from './useContext';
import updateHS from './useHS';
import useMountedEffect from './useMountedEffect';
import usePageViews from './UsePageViews';

const WarningBanner = lazy(() => import('../components/error/WarningBanner'));
const SandboxTermsDrawer = lazy(() => import('../components/SandboxTermsDrawer'));

const AdminBanner = lazy(() => import('../pages/admin/AdminBanner'));
const LeftNav = lazy(() => import('../components/navbar/LeftNavbar'));
const AlertAboutRefreshModal = lazy(() => import('../pages/settings/textingSettings/components/AlertAboutRefreshModal'));
const NotificationsDrawer = lazy(() => import('../pages/alerts/components/NotificationsDrawer'));
const FeatureHighlightDrawer = lazy(() => import('../pages/alerts/components/FeatureHighlightDrawer'));
const ProbationAndInvoiceFreezeModal = lazy(() => import('../components/error/InvoiceFreezeModal'));

export const AuthStateContextProvider = React.createContext<AuthContext | {}>({});

// This is a wrapper for components that require authentication. if not logged in, it will redirect to the login page
// noLayout is used for the login page, which doesn't need the navbar
export default function useAuthentication(AuthComponent: any, noLayout = false) {
	const { auth } = utils;
	// This is the actual wrapper
	return function AuthWrapper(props: any) {
		usePageViews()
		const params = useParams<{ uid: string }>()

		const isStaffAppPage = utils.strify(location.pathname).includes('walletsearch')
		const isCampaignBulder = utils.strify(location.pathname).includes('campaign/builder')
		const isDiscountBuilder = utils.strify(location.pathname).includes('discount/builder')

		noLayout = (isCampaignBulder && showSidebarInCampaigns()) ? false : noLayout
		noLayout = (isDiscountBuilder && showSidebarInDiscounts()) ? false : noLayout

		const [collapsed, setCollapsed] = React.useState(noLayout ? true : false)

		// We use a state to store the user data
		const [authState, _setAuthState] = React.useState<AuthContextState>({
			loaded: false,
			noLayout,
			uid: params.uid || utils.auth.getUID() || '1',
			user: null,
			email: '',
			role: '',
		});

		const setAuthState = ((newInput: AuthContextState | ((prevState: AuthContextState) => AuthContextState)) => {
			if (typeof newInput === 'function') {
				return _setAuthState((prevState) => {
					const newState = newInput(prevState);
					if (!authState.noLayout && newState.noLayout) setCollapsed(true)
					return { ...prevState, ...newState };
				});
			}
			if (!authState.noLayout && newInput.noLayout) setCollapsed(true)
			return _setAuthState({ ...authState, ...newInput });
		}) as React.Dispatch<React.SetStateAction<AuthContextState>>


		const isMounted = useMountedEffect((ref) => {
			let user: User | null = null;
			// If the user is logged in, we update the HS script
			if (authState.loaded && user) updateHS(user)

			// If the user is not logged in
			if (!authState.loaded) asyncWrap(async () => {
				// If the user is not logged in, we redirect to the login page
				if (!auth.loggedIn()) return await history.replace(utils.home())

				const { uid } = authState
				const email = await utils.auth.getEmail(), role = await utils.auth.getRole();

				// Get user from API
				user = await utils.auth.getUser(uid) || null;

				if (!user) {
					throw new Error('User not found')
					return
				}

				// If the user is an admin who is not whitelisted, we remove the HS script
				if (utils.isAdmin() && !adminUtils.hubspotWhitelist()) {
					utils.removeScript('hs-script-loader', true);
				} else {
					updateHS(user)
				}

				// IF the user is a staff member, we check if they have a customer queue
				if (isStaffAppPage) {
					const hasCustomerQueue = await utils.auth.get(`/hasQueue/${user.id}`)
					utils.hasCustomerQueue = hasCustomerQueue || utils.isLocal
				}

				// Perform action based on the user's role
				switch (role) {
					case Role.BUDTENDER:
						utils.removeScript('ze-snippet');
						if (!isStaffAppPage) history.replace(utils.home())
						break;
					case Role.FINANCIAL:
						// If the user is a financial admin, we redirect them to the financial reporting page
						if (!location.pathname.startsWith(`/settings/${user?.id || uid}/loyalty/reporting`)) {
							history.replace(utils.home());
						}
						break
					case '':
						auth.logout();
						history.push('/login');
						break;
					default:
						break;
				}

				// update the context
				setAuthState(s => ({ ...s, loaded: true, user, email, role, }));
				setUser({ email, uid, role })


			}, {
				errorCallback(error: Error) {
					if (userUtils.isAdmin() && error.message.toLowerCase() === 'user not found') {
						history.push('/admin');
						return utils.showErr(error)
					}
					const path = location.pathname + location.search;
					auth.logout();
					history.push(`/login?${path ? `p=${path}` : ''}`);
				},
			});
		}, []);

		const { uid, user, } = authState
		const { history, match } = props
		const rid = auth.getUID();

		// If the user is not logged in, we redirect to the login page so return null
		if (!user) return null;
		// If this is not an agency log last UID for off change of pageNotFound
		if (!!user.agencyID || user.type !== 'agency') utils.local.setWithExpiry('lastUID', uid, timeUtils.units.MINUTE_MS * 5)

		utils.user = user;
		utils.uid = uid;
		utils.realID = rid;
		utils.history = history;
		utils.params = !!match && !!match.params ? match.params : {};

		// If account is on probation && the user is not an admin. They should not be able to access the site
		if ((user.probation || user.invoiceFreeze) && !utils.isAdmin()) {
			return <>
				<Layout className='bg' style={{ minHeight: '100vh' }}>
					<ProbationAndInvoiceFreezeModal user={user} />
				</Layout >
			</>;
		}

		// if the account is on probation or is archived we send the admin an alert
		if (user.probation || user.archived || user.invoiceFreeze) {
			const color = '#1890FF'
			const isOn: string[] = []
			if (user.probation) isOn.push('on probation')
			if (user.invoiceFreeze) isOn.push('on invoice freeze')
			if (user.archived) isOn.push('archived')
			notification['info']({
				duration: 8,
				maxCount: 1,
				style: { borderLeft: color ? `3px solid ${color}` : "", },
				key: 'admin-auth-account-info',
				placement: 'top',
				message: <span style={{ fontSize: "1rem", fontWeight: 600 }}>Account Status</span>,
				description: <>
					<span>{`This account is currently ${isOn.join(', ')}. Clients will not be able to access this account until is reactivated.`}</span>
					<Break air />
					{!!user.probation && <>
						<Space>
							<span style={{ fontWeight: 600 }}>Probation:</span>
							<span>True</span>
						</Space>
						<Break air />
					</>}
					{!!user.invoiceFreeze && <>
						<Space>
							<span style={{ fontWeight: 600 }}>Invoice Freeze:</span>
							<span>True</span>
						</Space>
						<Break air />
					</>}
					{!!user.archived && <>
						<Space>
							<span style={{ fontWeight: 600 }}>Archived:</span>
							<span>True</span>
						</Space>
					</>}
				</>
			})
		}

		const showSandboxTerms = (user.subType === 'sandbox' && !user.sandboxTerms?.timestamp)

		// Render with layout, left nav, clock, etc.
		return (
			<React.Suspense fallback={<Loader />}>
				<Layout style={{ minHeight: '100vh', maxWidth: '100vw' }}>
					<AuthStateContextProvider.Provider value={[authState, setAuthState]}>
						<ContextProvider {...{ uid, noLayout: authState.noLayout }}>
							{!authState.noLayout && <>
								<LeftNav
									user={user}
									collapsed={collapsed}
									setCollapsed={setCollapsed}
								/>
							</>}
							<div
								style={{
									position: 'relative',
									// height: '100vh',
									// width: '100vw',
									// maxWidth: 'calc(100vw - 2px)',
									width: collapsed ? 'calc(100vw)' : 'calc(100vw - 230px)',
									maxWidth: collapsed ? 'calc(100vw)' : 'calc(100vw - 230px)',
									// height: '100%',
									overflowX: 'hidden',
									overflowY: 'auto',
									scrollbarWidth: 'none',
									border: ((user.probation || user.archived) ? '2px solid red' : ''),

									...(utils.darkMode ? { backgroundColor: '#121212' } : {}),
								}}
							>
								{showSandboxTerms && <SandboxTermsDrawer />}
								<WarningBanner />

								{utils.isAdmin() && <>
									<CH />
									<AdminBanner />
								</>}
								<NotificationsDrawer />
								<FeatureHighlightDrawer />
								<AlertAboutRefreshModal />

								<AuthComponent asHost={utils.asHost} user={user} {...props} />
							</div>
						</ContextProvider>
					</AuthStateContextProvider.Provider>
				</Layout>
			</React.Suspense >
		);
	};
}


